import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { log, logError, logRender } from '../lib/Debug';
import BlackoutAPI from '../lib/BlackoutAPI';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

import Panel from './Panel';
import LoadingBlinker from './LoadingBlinker';

function Verify() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	const { userId } = useParams();
	console.log('userId',userId);
	
	let [result,setResult] = useState(null);
	
	useEffect(()=>{

		if(userId){
			
			BlackoutAPI.patch({
				endpoint: 'managers',
				id: userId,
				headers: {
					'Content-Type': 'application/vnd.api+json'
				},
				data: {
					id: userId,
					type: 'managers',
					attributes:{
						verifyEmail: true
					}
				}
			}).then(res=>{
				log(` **** verify success ***** `);
				log(`res`,res);	
				setResult({
					success: i18n('misc.verified','Account verified'),
				});
			}).catch(err=>{
				logError(` **** verify error ***** `);
				logError(`err.message`,err.message);
				setResult({
					error: i18n('misc.failed-to-verify','Account verification failed'),
				});
			});
			
		}
		
		// For testing:
		// setTimeout(()=>{
		// 	setResult({
		// 		success: i18n('misc.verified','Account verified'),
		// 	});
		// },1000);
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	let homePageLink = (
		<Link to={`/`} className="upper-link">{i18n('misc.return-home','Return Home')}</Link>
	);
	
	let content;
	
	if(result){
		if(result.error){
			content = (
				<div className="validation-text center-text"><span className="icon-attention" />{result.error}</div>
			);
		} else {
			content = (
				<>
					<div className="success-text center-text gap-after sm">{result.success}<span className="icon-ok-squared verified" /></div>
					<div className="gold-glow-text">{i18n('misc.tokens-applied','Your tokens have been applied!')} <img src={`${globals.config.baseImageUrl}/email/2020-06-template/token.png`} alt={i18n('misc.token','Token')} className="inline-token" /></div>
				</>
			);
		}
	} else {
		content = (
			<LoadingBlinker text={i18n('misc.verifying','Verifying')} />
		);
	}
	
	logRender('Verify -- render');
	
	return (
		<Panel className="flex-column-center">
			<div className="sub-panel center-text">
				<div className="gap-after lg">
					{content}
				</div>
				{homePageLink}
			</div>
		</Panel>
	);
	
}

export default Verify;

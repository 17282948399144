import React from 'react';
import Globals from '../../contexts/Globals';
import { useMediaQuery } from 'react-responsive';

function RugbyFeatures(){

	let globals = Globals.useContainer();

		// Determine video frame size
		const videoBaseWidth = 480;
		const videoBaseHeight = 270;
		const videoHeightFactor = videoBaseHeight/videoBaseWidth;
		const isWide = useMediaQuery({ query: globals.wideMediaQuery });
		const isTablet = useMediaQuery({ query: globals.tabletMediaQuery });
		const isPhone = useMediaQuery({ query: globals.phoneMediaQuery });
		let windowWidth = window.innerWidth;
		const baseWidth = isWide ? windowWidth : 1920;
		const factor = baseWidth/1920;
		let videoWidth = isPhone ? windowWidth*0.9 : (isTablet ? 400 : 480*factor);
		let videoHeight = videoWidth * videoHeightFactor;

	return(
		<>
			<div id="features-wrapper">
				<div id="features-shadow">
					<div id="features-video">
						<iframe title="Blackout Rugby Features Trailer" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" width={videoWidth} height={videoHeight} type="text/html" src="https://www.youtube.com/embed/xCRNvoCeXyk?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&controls=1" allowFullScreen></iframe>
					</div>
				</div>
				<div className="flex-column-center">
					<img id="features-title" src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/home-features-title-centered.png`} alt="Features Trailer" />
				</div>
			</div>
			
			<h1 style={{textAlign:'center', marginBottom:'30px'}}>Our Games</h1>
			<div id="features-wrapper">
				<a href={`https://${globals.isDevMode?`local.`:``}blackout.rugby`}>
					<div id='features-app'>
						<img className='app-icon' src='https://dr8u6iu2x4v0s.cloudfront.net/website/blackout.rugby/2024/app_icon_rugby.png' alt='Blackout Rugby Manager'></img>
					</div>
				</a>
				<a href={`https://${globals.isDevMode?`local.`:``}blackout.football`}>
					<div id='features-app' className='football'>
						<img className='app-icon' src='https://dr8u6iu2x4v0s.cloudfront.net/website/blackout.rugby/2024/app_icon_football.png' alt='Blackout Football Manager'></img>
					</div>
				</a>
			</div>
		</>
	);

}
export default RugbyFeatures;
import cookies from 'js-cookie';
import moment from 'moment';
import FacebookAPI from './FacebookAPI';
import BlackoutAPI from './BlackoutAPI';
import { log, logError } from '../lib/Debug';

export default {
	
	// Only call for newly acquired tokens
	storeToken(token,manager){
		token.userId = manager.id;
		
		// For testing short token expiry
		//token.expiresIn = 60;
		
		token.expireDate = new moment().add(token.expiresIn,'seconds').toISOString();
		cookies.set('token',token);
	},
	
	logout(globals){
		cookies.remove('token');
		localStorage.removeItem('processedUser');
		FacebookAPI.reset();
		globals.resetUserState();
	},
	
	async getUserFromToken(token){
		
		log('getUserFromToken',token);
		let includes = ['clubs','social'];
		
		let secondsUntilExpires = moment(token.expireDate).diff(new Date(),'seconds');
		let currentUser = localStorage.getItem('processedUser');
		if(currentUser) currentUser = JSON.parse(currentUser);
		
		// Check if we are within 30 seconds of expiry, or if we have expired
		if(secondsUntilExpires <= 30){
			log('Refreshing token');
			// We need to refresh the token
			let promise = BlackoutAPI.post({
				endpoint: 'token',
				include: includes.join(','),
				data: {
					grant_type: 'refresh_token',
					refresh_token: token.refreshToken,
					access_token: token.accessToken,
				}
			}).then(res=>{
				let newToken = {
					accessToken: res.access_token,
					expiresIn: res.expires_in,
					refreshToken: res.refresh_token,
					type: res.token_type,
					userId: res.manager.data.id,
				};
				
				this.storeToken(newToken,res.manager.data);
				return this.processUser(res.manager,newToken);
			}).catch(err=>{
				logError(` ** REFRESH TOKEN ERROR *** deleting cookie token `);
				logError(`err.message`,err.message);
				cookies.remove('token');
				throw err;
			});
			
			return {promise,currentUser};
			
		} else {
			
			// We have a valid token, so just load the user
			let headers = {
				token: token.accessToken,
			};

			let promise = BlackoutAPI.get({
				endpoint: 'managers',
				id: token.userId,
				include: includes.join(','),
				headers
			}).then(res=>{
				return this.processUser(res,token);
			});
			
			return {promise,currentUser};
			
		}
	},
	
	processUser(userRes,token){
		let clubs = [];
		let social;
		if(userRes.included){
			for(let i in userRes.included){
				if(userRes.included[i].type === 'social'){
					social = userRes.included[i];
				} else if(userRes.included[i].type === 'clubs'){
					clubs.push(userRes.included[i]);
				}
			}
		}
		let processedUser = {
			loggedIn: true,
			token,
			manager:userRes.data,
			clubs,
			social,
		};
		localStorage.setItem('processedUser',JSON.stringify(processedUser));
		return processedUser;
	},

};
import cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import BlackoutAPI from "./../lib/BlackoutAPI";
import { logError } from "./../lib/Debug";
import util from "./../lib/util";
import ChatAlert from "./chat-alerts/ChatAlert";
import LoaderButton from "./LoaderButton";

// let lizzie = {
// 	managerId: '696833ae-bc16-4915-8e4b-018ca4a6cc62',
// 	token: '7UhFqQRHUARYT0swjUduFphQ5Yj5jQ1c4vJHh3bE',
// };

function ChatAlerts(){
	const[managerId, setManagerId] = useState();
	const[managerName, setManagerName] = useState();
	const[alertsToDisplay, setAlertsToDisplay] = useState([]);
	const[newChatAlertTitle, setNewChatAlertTitle] = useState();
	const[isAddingNewChatAlert, setIsAddingNewChatAlert] = useState();
	const[newChatAlertDescription, setNewChatAlertDescription] = useState();
	const[isDisplayingChatAlerts, setIsDisplayingChatAlerts] = useState();
	const[filteredManagerUserName, setFilteredManagerUserName] = useState("");
	const[managersUserNameList, setManagersUserNameList] = useState([]);
	const[isSearching, setIsSearching] = useState(false);
	const[noResultsFound, setNoResultsFound] = useState(false);
	const[isLoggedIn, setIsLoggedIn] = useState(false);
	
	let token = cookies.getJSON('token');
	// console.log(token);

	//--- autocomplete stuff
	const [active, setActive] = useState(0);
	const [isShow, setIsShow] = useState(false);
	const [filtered, setFiltered] = useState([]);

	useEffect(() => {
		if(token){
			setIsLoggedIn(true);
		} 

	}, [token]);

	useEffect(() => {
		BlackoutAPI.get({
			endpoint: 'search',
			ac: { target: "managers", query: filteredManagerUserName },
			headers: {
				Accept: "application/vnd.api+json",
			},
		})
			.then((res) => {
				setManagersUserNameList(res.results.managers);
			})
			.catch((err) => {
				logError(`err.message`, err.message);
			});
	}, [filteredManagerUserName]);


	const newChatAlertTitleHandler = (e) => {
		setIsShow(false);
		setNewChatAlertTitle(util.toTitleCase(e.target.value));
	};

	const newChatAlertDescriptionHandler = (e) => {
		setIsShow(false);
		setNewChatAlertDescription(util.toTitleCase(e.target.value));
	};

	const addingNewChatAlertHandler = () => {
		setIsAddingNewChatAlert(true);
		setIsDisplayingChatAlerts(false);
	};

	const resetButton = () => {
		setIsAddingNewChatAlert(false);
		setIsDisplayingChatAlerts(false);
		setIsSearching(false);
		setManagerId("");
		setManagerName("");
		setFilteredManagerUserName("");
		setNoResultsFound(false);
	};

	const cancelButton = () => {
		setIsAddingNewChatAlert(false);
	};

	const searchManagerChatAlert = () => {
		let id = managerId;
		setIsSearching(true);
		searchChatAlert(id);

	};

	const searchChatAlert = (id) => {
		let promise = BlackoutAPI.get({
			endpoint: 'chat-alerts', //game alerts to see the actual message
			headers: {
				Accept: "application/vnd.api+json",
				Token: token.accessToken, //this will need to get the token from the cookie
			},
			filter: { key: "manager", value: id }, 
		}).then((res) => {
			if (util.isEmpty(res)) {
				setIsDisplayingChatAlerts(false);
				setIsSearching(false);
				setNoResultsFound(true);
			} else {
				setIsDisplayingChatAlerts(true);
				setAlertsToDisplay(res);
				setIsSearching(false);
				setNoResultsFound(false);
			}
		}).catch((err) => {
			logError(`err.message`, err.message);
		});
		return { promise };
	};

	const submitNewChatAlertHandler = () => {
		//submit new chat alert to the endpoint
		let result = window.confirm("Send chat alert to " + managerName + "?");
		if (result) {
			addNewChatAlert();
		}
	};

	const searchingUserNameHandler = (e) => {
		setActive(0);
		setIsShow(true);
		setFiltered(managersUserNameList);
		setFilteredManagerUserName(e.currentTarget.value);
	};

	const onClick = e => {
		setActive(0);
		setFiltered([]);
		setIsShow(false);
		setFilteredManagerUserName(e.currentTarget.innerText);
		setManagerName(filtered[active].match);

		if(filtered[active].match === e.currentTarget.innerText){
			setManagerId(filtered[active].payload.Id);
		}
	};

	const onKeyDown = e => {
		if (e.keyCode === 13) { // enter key
			setActive(0);
			setIsShow(false);
			setFilteredManagerUserName(filtered[active].match);
			setManagerId(filtered[active].payload.Id);
			setManagerName(filtered[active].match);
		}
		else if (e.keyCode === 27){ //esc key
			setIsShow(false);
		}
		else if (e.keyCode === 38) { // up arrow
			return (active === 0) ? null : setActive(active - 1);
		}
		else if (e.keyCode === 40) { // down arrow
			return (active - 1 === filtered.length) ? null : setActive(active + 1);
		}
	};

	const renderAutocomplete = () => {
		if (isShow && filteredManagerUserName) {
			if (filtered.length) {
				return (
					<ul className="chat-alert-autocomplete">
						{filtered.map((m, index) => {
							let className;
							if (index === active) {
								className = "active";}
							return (
								<li key={m} value={m.payload.Id} className={className} onClick={onClick}> {m.match} </li>);
						})}
					</ul>
				);
			} else {
				return (
					<div className="chat-alert-no-autocomplete">
						<p>No matching manager names</p>
					</div>
				);
			}
		}
	};

	const addNewChatAlert = () => {
		if (!util.isEmpty( managerId || newChatAlertTitle || newChatAlertDescription)) {
			let promise = BlackoutAPI.post({
				endpoint: 'chat-alerts',
				headers: {
					"content-type": "application/vnd.api+json",
					Token: token.accessToken, //this would come from the token
				},
				data: {
					type: 'chat-alerts',
					attributes: {
						creator: token.userId, //this would come from the token
						title: newChatAlertTitle,
						description: newChatAlertDescription,
						manager: managerId,
						isDeveloper: false,
					},
				},
			})
				.then((res) => {
					console.log(res);
					setIsAddingNewChatAlert(false);
				})
				.catch((err) => {
					logError(`err.message`, err.message);
				});
			return { promise };
		}

	};

	return (
		<div>
			<>
				<div className="flex-row-center">
					<h1>Chat Alerts</h1>
				</div>
				{ isLoggedIn ?
				<div>
					<h4 className="no-gap-after">Manager Name</h4>
					<div className="flex-row">
						<input
							className="flex-row no-gap-after chat-alert-autocomplete-input"
							placeholder="Enter manager name"
							value={filteredManagerUserName}
							onChange={searchingUserNameHandler}
							onKeyDown={onKeyDown}>
						</input>
						{renderAutocomplete()}
						<LoaderButton
							className="chat-alert-button gap-after"
							value={filteredManagerUserName}
							onClick={searchManagerChatAlert}
							isLoading={isSearching}>Search
						</LoaderButton>
						<button
							className="chat-alert-cancel-button"
							onClick={resetButton}>Clear
						</button>
					</div>	
					{!isAddingNewChatAlert && (<button
						className="chat-alert-create-button gap-after"
						onClick={addingNewChatAlertHandler}>Create a new chat alert
					</button>)}
					<div className="flex-row flex-align-stretch">
						<div className="flex-row-flex-align-stretch">
							<div className="flex-column chat-alert-main-panel">
								{isDisplayingChatAlerts && !isSearching && !noResultsFound &&
									<div className="chat-alert-scroll-bar">
										<ChatAlert alertsToDisplay={alertsToDisplay} managerName={managerName} /> 
										</div>}
								{noResultsFound && <p className="chat-alert-p">No chat alerts found</p>}
								<div className="flex-row">
									{isAddingNewChatAlert && !isDisplayingChatAlerts && (
										<div>
											<h4 className="no-gap-after">Title</h4>
											<input className="gap-after chat-alert-input"
												placeholder="Enter title or Template Id"
												onChange={(e) => newChatAlertTitleHandler(e)}>
											</input>
											<h4 className="no-gap-after">Message</h4>
											<input
												className="gap-after chat-alert-input"
												placeholder="Enter message or Template Id"
												onChange={newChatAlertDescriptionHandler}>
											</input>
											<br></br>
											<div className="flex-row">
												<button className="chat-alert-create-button"
													onClick={submitNewChatAlertHandler}>Submit
												</button>
												<button
													className="chat-alert-cancel-button"
													onClick={cancelButton}>Cancel
												</button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div> 
				: <p className="chat-alert-logged-in-p"> You must be logged in to view this page </p>}
			</>
		</div>
	);

}

export default ChatAlerts;
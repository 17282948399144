import React,{ useState, useEffect } from 'react';
//import Globals from '../contexts/Globals';
import FormationPlayer from './formation/FormationPlayer';
import util from '../lib/util';

const playerSizeMetres = 5;
const fieldBounds = {w:428.5,h:665.7};
const fieldMetres = {w:68, h:106};
const playerWidth = playerSizeMetres/fieldMetres.w*fieldBounds.w;
const playerHeight = playerSizeMetres/fieldMetres.h*fieldBounds.h;
const pixelsPerGrid = {x:fieldBounds.w/fieldMetres.w*0.5,z:fieldBounds.h/fieldMetres.h*0.5};

const positions = {
	GK: 'goalKeeper',
	FB: 'fullBack', // L/R
	CB: 'centreBack', // L/R
	WM: 'wideMidfielder', // L/R
	CM: 'centreMidfielder',
	W:  'wing', // L/R
	S: 'striker',
};
const variants = ['balanced','cautious','attacking'];
const formationTypes = ['attack','defence'];
const validX = {min:-2.0,max:2.0}; // Only used for data validation, doesn't affect actual available value
const validZ = {min:-0.5,max:9.0}; // Only used for data validation, doesn't affect actual available value
const validFieldX = {min:2.5,max:65.5}; // Only used for data validation, doesn't affect actual available value
const validFieldZ = {min:2.5,max:103.5}; // Only used for data validation, doesn't affect actual available value
let lastFormationType;

const formationLib = {

	toFormationCoords(x,z,players){
		
		// Determine block height and width
		let top=9999,bottom=0,left=9999,right=0; 

		for( let player of players ){
			if(player.positionKey==='GK') continue; //ignore GK
			if(player.x > right) right = player.x;
			if(player.x < left) left = player.x;
			if(player.z > bottom) bottom = player.z;
			if(player.z < top) top = player.z;

		}
		const blockHeight = Math.abs(bottom-top);
		const blockWidth = Math.abs(right-left)*0.5;
		// Determine this player's position within formation
		const xDiffToGK = x - right; 
		const zDiffToGK = bottom - z;
		return {
			x: util.round(xDiffToGK/blockWidth * 2 + 2,2),
			z: util.round(zDiffToGK/blockHeight * 9,2),
		};
	},
	
	fromFormationCoords(x,z){
		const netFieldWidth = fieldMetres.w;
		const xMetre = (netFieldWidth*0.5 + (x/2.5*(netFieldWidth*0.5)));
		const xHalfMetre = Math.round(xMetre*2)*0.5 - playerSizeMetres*0.5;
		const netFieldHeight = fieldMetres.h;
		const zMetre = (fieldMetres.h-8 - (z/8*netFieldHeight));
		const zHalfMetre = Math.round(zMetre*2)*0.5 - playerSizeMetres*0.5;
		return {
			x: xHalfMetre*pixelsPerGrid.x*2,
			z: zHalfMetre*pixelsPerGrid.z*2,
		};
	},
	
	toMetres(x,y){
		return {
			x: util.round(x/fieldBounds.w*fieldMetres.w,1) + playerSizeMetres*0.5,
			z: util.round(y/fieldBounds.h*fieldMetres.h,1) + playerSizeMetres*0.5,
		};
	},

	fromMetres(x,z){
		return {
			x: x*pixelsPerGrid.x*2 - playerWidth*0.5,
			z: z*pixelsPerGrid.z*2 - playerHeight*0.5,
		};
	},
	
	calculateBlockSize(players){
		let left=9999,right=0,top=9999,bottom=0;
		for( let player of players ){
			if(player.positionKey==='GK') continue;
			const metrePosition = this.toMetres(player.x,player.z);
			if(metrePosition.x > right) right = metrePosition.x;
			if(metrePosition.x < left) left = metrePosition.x;
			if(metrePosition.z > bottom) bottom = metrePosition.z;
			if(metrePosition.z < top) top = metrePosition.z;
		}
		return {
			w: Math.abs(right - left),
			h: Math.abs(bottom - top),
		};
	},
	
	replaceFormationInData(formation,data){
		const idx = data.findIndex(f=>f.formationType===formation.formationType);
		data[idx] = formation;
	},
	
	processJSON(json,fixOutOfBounds=false,formationType){
		let data,formation,updateJSONField=false;
		try {
			data = JSON.parse(json);
		} catch(err){
			return {isValid:false,reason:'Invalid JSON'};
		} 
		if(!data) return {isValid:false,reason:'Invalid JSON'};
		
		if(!util.isArray(data)) return {isValid:false,reason:'JSON should be an array containing two formations, one attack and one defence'};
		
		let attackFormation = data.find(f=>f.formationType==='attack');
		if(!attackFormation||!util.isObject(attackFormation)){
			return {isValid:false,reason:'Attack formation not found. JSON should be an array containing two formations, one attack and one defence'};
		}
		let defenceFormation = data.find(f=>f.formationType==='defence');
		if(!defenceFormation||!util.isObject(defenceFormation)){
			return {isValid:false,reason:'Attack formation not found. JSON should be an array containing two formations, one attack and one defence'};
		}
		
		if(formationType === 'attack'){
			formation = attackFormation;
		} else if(formationType === 'defence'){
			formation = defenceFormation;
		} else {
			throw new Error(`Invalid formationType (${formationType}) passed to processJSON`);
		}
		
		// Check for both formation types
		if(!formationTypes.includes(formation.formationType)){
			return {isValid:false,reason:'Invalid formation type ('+formation.formationType+')'};
		}
		
		// Check for title
		if(typeof formation.formationName !== 'string' || formation.formationName.length===0){
			return {isValid:false,reason:'formationName not found'};
		}
		
		// Check for variant
		if(!variants.includes(formation.formationVariant)){
			return {isValid:false,reason:'Invalid formation variant ('+formation.formationVariant+')'};
		}
		
		let players = [];
		
		// Check for 11 positions
		for(let p=1; p<=11; p++){
			const pKey = `P${p}`;
			
			if(!util.isObject(formation[pKey])){
				return {isValid:false,reason:pKey+' not found'};
			}
			let player = formation[pKey];
			// Check for valid positionKey
			if(!positions[player.positionKey]){
				return {isValid:false,reason:'Invalid positionKey for '+pKey+' ('+player.positionKey+')'};
			}
			// Check for valid role
			if(!Object.values(positions).includes(player.role)){
				return {isValid:false,reason:'Invalid role for '+pKey+' ('+player.role+')'};
			}
			// Check for location
			if(!util.isObject(player.location)){
				return {isValid:false,reason:pKey+' invalid location ('+util.inspect(player.location)+')'};
			}
			if(isNaN(player.location.x)){
				return {isValid:false,reason:pKey+' location missing x ('+util.inspect(player.location)+')'};
			}
			if(isNaN(player.location.z)){
				return {isValid:false,reason:pKey+' location missing z ('+util.inspect(player.location)+')'};
			}
			if(player.location.x<validX.min||player.location.x>validX.max){
				if(pKey==='P1') {
					// Hard coded GK
					player.location.x = 0;
				} else if(fixOutOfBounds){
					// If this is pasted JSON data coming in, it may be out of date so we just fix it
					player.location.x = player.location.x < validX.min ? validX.min : validX.max;
					updateJSONField=true;
				} else {
					return {isValid:false,reason:'Out of bounds x location for '+pKey+' ('+player.location.x+')'};
				}
			}
			if(player.location.z<validZ.min||player.location.z>validZ.max){
				if(pKey==='P1') {
					// Hard coded GK
					player.location.z = 0;
				} else if(fixOutOfBounds){
					// If this is pasted JSON data coming in, it may be out of date so we just fix it
					player.location.z = player.location.z < validZ.min ? validZ.min : validZ.max;
					updateJSONField=true;
				} else {
					return {isValid:false,reason:'Out of bounds y location for '+pKey+' ('+player.location.z+')'};
				}
			}
			if(player.fieldLocation){
				if(player.fieldLocation.x<validFieldX.min||player.fieldLocation.x>validFieldX.max){
					if(fixOutOfBounds){
						// If this is pasted JSON data coming in, it may be out of date so we just fix it
						player.fieldLocation.x = player.fieldLocation.x < validFieldX.min ? validFieldX.min : validFieldX.max;
						updateJSONField=true;
					} else {
						return {isValid:false,reason:'Out of bounds x field location for '+pKey+' ('+player.fieldLocation.x+')'};
					}
				}
				if(player.fieldLocation.z<validFieldZ.min||player.fieldLocation.z>validFieldZ.max){
					if(fixOutOfBounds){
						// If this is pasted JSON data coming in, it may be out of date so we just fix it
						player.fieldLocation.z = player.fieldLocation.z < validFieldZ.min ? validFieldZ.min : validFieldZ.max;
						updateJSONField=true;
					} else {
						return {isValid:false,reason:'Out of bounds y field location for '+pKey+' ('+player.fieldLocation.z+')'};
					}
				}
			}

			players.push({
				id: pKey,
				fx:player.location.x,
				fz:player.location.z,
				positionKey:player.positionKey,
				role:player.role,
				resetPosition:true,
				fieldLocation: player.fieldLocation,
			});
		}
		
		return {isValid:true,formation,data,players,updateJSONField};
	},
};

function FormationEditor() {
	let boundsRef = React.useRef();
	
	//let globals = Globals.useContainer();
	let [json,setJSON] = useState(localStorage.getItem('formationJSON'));
	const [formationType, setFormationType] = useState('defence');
	let {isValid,players:initPlayers,reason,formation,data,updateJSONField} = formationLib.processJSON(json,true,formationType);
	const [players,setPlayers] = useState(isValid?initPlayers:[]);
	const [player,setPlayer] = useState(null);
	const [jsonError,setJSONError] = useState(isValid?null:reason);
	const [formationName,setFormationName] = useState(formation?.formationName);
	const [formationVariant,setFormationVariant] = useState(formation?.formationVariant);
	const [blockBoundsStyle,setBlockBoundsStyle] = useState({});
	
	if(formationType!==lastFormationType){
		// lastFormationType = formationType;
		// players.forEach(p=>p.resetPosition=true);
	}

	const patchPlayer = (player,patch) => {
		if(patch){
			let teamPlayer = players.find(p=>p.id===player.id);
			Object.assign(teamPlayer,patch);
			if(!isNaN(patch.x)){
				// Update all players, because relative formation coords can shift if the block shifts
				for( let player of players ){
					if(player.positionKey==='GK') continue; 
					const coords = formationLib.toFormationCoords(player.x,player.z,players);
					player.fx = coords.x;
					player.fz = coords.z;
				}
			}
			setPlayers([...players]);
			saveFormation();
		}
		setPlayer({...player});
	};
	
	const setValidatedFormation = (players,formation) => {
		setFormationName(formation.formationName);
		setPlayers(players);
	};
	
	const saveFormation = (newValues={},returnJSONOnly=false) => {
		let formation = { formationName, formationVariant, formationType, ...newValues };
		players.forEach(player=>{
			const metresPosition = formationLib.toMetres(player.x, player.z);
			formation[player.id] = {
				positionKey: player.positionKey,
				role: player.role,
				location: { x: player.fx, z: player.fz },
				fieldLocation: { x: metresPosition.x, z: metresPosition.z },
			};
			if(player.fx<0) formation[player.id].dominantSide = 'Left';
			if(player.fx>0) formation[player.id].dominantSide = 'Right';
			
		});
		
		formationLib.replaceFormationInData(formation,data);
		
		data.forEach(f=>{
			if(newValues.formationName) f.formationName = newValues.formationName;
			if(newValues.formationVariant) f.formationVariant = newValues.formationVariant;
		});
		let json = JSON.stringify(data, null, 2);
		
		if(returnJSONOnly) return json;
		
		setJSON(json);
		let {isValid,reason} = formationLib.processJSON(json,false,formationType);
		if(isValid){
			localStorage.setItem('formationJSON',json);
		} else {
			setJSONError(reason);
		}
	};
	
	// A validation error was automatically fixed, so we should update the JSON to represent player data again
	if(updateJSONField){
		json = saveFormation({},true);
		localStorage.setItem('formationJSON',json);
	}
	
	const resetFields = () => {
		setJSONError(null);
		setFormationName(null);
		setPlayers([]);
	};
	
	const handleNewData = e => {
		setJSON(e.target.value);
		localStorage.setItem('formationJSON',e.target.value);
		resetFields();
		if(e.target.value){
			let {isValid,reason,players,formation} = formationLib.processJSON(e.target.value,true,formationType);
			if(isValid){
				setValidatedFormation(players,formation);
			} else {
				setJSONError(reason);
			}
		}
	};

	const handleFormationName = e => {
		setFormationName(e.target.value);
		saveFormation({formationName:e.target.value});
	};

	const handleFormationVariant = e => {
		setFormationVariant(e.target.value);
		saveFormation({formationVariant:e.target.value});
	};

	const handleFormationType = e => {
		setFormationType(e.target.value);
		let {players} = formationLib.processJSON(json,true,e.target.value);
		setPlayers(players);
		setPlayer(null);
	};
	
	const handlePositionKey = e => {
		let teamPlayer = players.find(p=>p.id===player.id);
		player.positionKey = e.target.value;
		player.role = positions[player.positionKey];
		teamPlayer.positionKey = player.positionKey;
		teamPlayer.role = player.role;
		setPlayers([...players]);
		setPlayer(player);
		saveFormation();
	};
	
	const deselectPlayer = () => {
		setPlayer(null);
	};
	
	useEffect(()=>{
		let $bounds = boundsRef.current;
		
		// Figure out block size
		if(formationType === 'defence'){
			let left=9999,right=0,top=9999,bottom=0;
			for( let player of players ){
				if(player.positionKey==='GK') continue;
				if(player.x > right) right = player.x;
				if(player.x < left) left = player.x;
				if(player.z > bottom) bottom = player.z;
				if(player.z < top) top = player.z;
			}
			setBlockBoundsStyle({ left:`${left}px`, right:`${$bounds.offsetWidth-right-playerWidth}px`, top:`${top}px`, bottom:`${$bounds.offsetHeight-bottom-playerHeight-1.5}px` });
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[formationType,players]);
	
	const metresPosition = player ? formationLib.toMetres(player.x,player.z) : {};
	const blockSize = formationLib.calculateBlockSize(players);
	
	return (
		<>
			<div className="flex-row-center" style={{width:'100%'}}>
				<h1>Formation Editor</h1>
			</div>
			<div className="flex-row flex-align-stretch">
				<div className="flex-column formation-panel">
					<div>
						<div className="flex-row">
							<div className="flex-column" style={{width:'47%',paddingRight:'15px'}}>
								<input type="text" value={formationName||'🤷‍♂️'} onChange={handleFormationName} style={{color:'white'}} className="gap-after" />
							</div>
							<div className="flex-column">
								<select value={formationVariant} onChange={handleFormationVariant} className="gap-after">
									{variants.map(variant=>
										<option key={variant} value={variant}>{util.toTitleCase(variant)}</option>
									)}
								</select>
							</div>
						</div>
						<select value={formationType} onChange={handleFormationType} className="gap-after">
							{formationTypes.map(type=>
								<option key={type} value={type}>{util.toTitleCase(type)}</option>
							)}
						</select>
						{player?
							<>
								<h3 className="no-gap-after">Position <span className="selected-formation-text">{player.id}</span></h3>
								<h1 className="no-gap-after">{player.fx},{player.fz}</h1>
								<div className="gap-after"><b>{metresPosition.x}m {metresPosition.z}m</b></div>
								<h3 className="no-gap-after">POSITION</h3>
								<select value={player.positionKey} onChange={handlePositionKey} className="gap-after">
									{Object.keys(positions).map(key=>
										<option key={key} value={key}>{key}</option>
									)}
								</select>
								<h3 className="no-gap-after">ROLE</h3>
								<h4 className="">{player.role}</h4>
							</>
						:<h3>No player selected</h3>}
						{formationType==='defence'?
							<>
								<h3 className="no-gap-after">BLOCK SIZE</h3>
								<div className="gap-after color"><b>{blockSize.w}m {blockSize.h}m</b></div>
							</>
						:null}
					</div>
					<div className="formation-json-panel">
						<h3 className="no-gap-after">JSON Data</h3>
						<textarea cols="30" rows="10" onChange={handleNewData} value={json}></textarea>
						{jsonError
							? <span className="validation-text small-text"><span className="icon-attention" />{jsonError}</span>
							: ''
						}
					</div>
				</div>
				<div className="flex-column formation-field" onMouseDown={deselectPlayer}>
					<div className="formation-bounds" ref={boundsRef}>
						<div className={'formation-block'+(formationType==='defence'?' defence':'')} style={blockBoundsStyle}></div>
						{players.map(p=>
							<FormationPlayer key={p.id} player={p} patchPlayer={patchPlayer} pixelsPerGrid={pixelsPerGrid} formationLib={formationLib} selectedPlayer={player}/>
						)}
					</div>
				</div>
			</div>
		</>
	);
	
}

export default FormationEditor;

import React from 'react';
import Globals from '../contexts/Globals';
import { logRender } from '../lib/Debug';

function LowerBar(props) {
	
	//--------------------- State
	
	let globals = Globals.useContainer();
	
	//--------------------- Styles
	
	let barClassName = ['clearfix'];
	if(!globals.isHome) barClassName.push('showing');
	if(!props.isTransitioning) barClassName.push('notransition');
  
	logRender(`LowerBar -- render`);
	
	return (
		<div id="lower-bar" className={barClassName.join(' ')} style={{bottom:`${props.homeHeight}px`}}>
		</div>
	);
	
}

export default LowerBar;


import React, { useState, useEffect } from 'react';
import Globals from '../contexts/Globals';
import BlackoutAPI from '../lib/BlackoutAPI';

function I18n() {
	
	let globals = Globals.useContainer();
	let [isLoading,setIsLoading] = useState(false);
	
	useEffect(()=>{
		
		// Set globals if data is already found in localStorage
		if((!globals.i18n || !Object.keys(globals.i18n).length)){
			let i18n = JSON.parse(localStorage.getItem('i18n'));
			if(i18n) globals.setI18n(i18n);
		}
		
		// Regardless of whether data was found in local store, we still load fresh data in case anything has changed
		if((!globals.i18n || !globals.i18n.hasLoaded) && !isLoading){
			setIsLoading(true);
			BlackoutAPI.get({
				endpoint: 'i18n',
				id: 'web',
			}).then(res=>{
				localStorage.setItem('i18n',JSON.stringify(res));
				res.hasLoaded = true;
				globals.setI18n(res);
			});
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	return (
		<></>
	);
	
}

export default I18n;
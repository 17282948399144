import axios from 'axios';
import util from './util';
import { logAPI } from '../lib/Debug';
import config from '../config.json';
import zlib from 'zlib';
import {Buffer} from 'buffer/';
import Globals from '../contexts/Globals';

const baseApi = config.baseAPI;
// const baseApiLocal = config.baseAPILocal;
const defaultHeaders = {
	'Blackout-Accept-Encoding': 'base64/gzip',
};


export default Object.assign({
	
	preprocessResponse(res){
		if(res.data){
			try {
				res.data = JSON.parse(zlib.gunzipSync(Buffer.from(res.data, 'base64')));
			} catch(err){
				console.log(err);
				console.log('Failed to process API response');
				console.log(res.data);
			}
		}
	},
	
	queryBuilder(params){
		let query = baseApi;
		// let query = baseApiLocal; //use sandbox as api
		if(params.endpoint){
			query = `${query}${params.endpoint}`;
		} else {
			throw new Error(`must provide endpoint in api params`);
		}
		if(params.id){
			query = `${query}/${params.id}`;
		}
		let joiner = '?';
		if(params.ids){
			query = `${query}${joiner}ids=${params.ids}`;
			joiner = '&';
		}
		if(params.where){
			query = `${query}${joiner}${params.where.key}=${params.where.value}`;
			joiner = '&';		
		}
		if(params.filter){
			query = `${query}${joiner}filter[${params.filter.key}]=${params.filter.value}`;
			joiner = '&';
		}
		if(params.sort){
			query = `${query}${joiner}sort=${params.sort}`;
			joiner = '&';
		}
		if(params.include){
			query = `${query}${joiner}include=${params.include}`;
			joiner = '&';
		}
		if(params.queryParams){
			query = `${query}${joiner}${params.queryParams}`;
			joiner = '&';
		}
		if(params.ac){
			query = `${query}${joiner}ac&target=${params.ac.target}&q=${params.ac.query}`;
			joiner = '&';
		}
		return query;
	},
	
	processHeaders(headers){
		if(!headers) headers = {};
		
		headers['blackout-sport'] = Globals.current.sport;
		
		return headers;
	},

	async get(params){
		if(!params.endpoint){
			throw new Error(`BlackoutAPI: post: missing endpoint`);
		}
		let query = this.queryBuilder(params);
		logAPI(`GET`,query);
		
		params.headers = this.processHeaders(params.headers);
		
		return axios.get(query, { headers: { ...defaultHeaders, ...params.headers } }).then(res=>{
			this.preprocessResponse(res);
			//logAPI(`GET RESPONSE`,query);
			return this.deserialize(res.data);
		}).catch(error=>{
			this.preprocessResponse(error.response);
			if(error.response && error.response.data && error.response.data.data){
				error.response.data = this.deserialize(error.response.data.data);
			}
			throw error;
		});
	},


	/**
	 * @param {string} params.endpoint 	required: the api endpoint
	 * @param {string} params.data 		required: the body for the request
	 */

	async post(params){
		if(!params.endpoint){
			throw new Error(`BlackoutAPI: post: missing endpoint`);
		}
		let data;
		if(params.data){
			data = JSON.stringify(params.data);
		} else {
			throw new Error(`BlackoutAPI: post: requires data`);
		}
		
		let url = this.queryBuilder(params);
		params.headers = this.processHeaders(params.headers);
		
		logAPI(`POST`,url,data);
		return axios.post(url, data, { headers: { ...defaultHeaders, ...params.headers } }).then(res=>{
			this.preprocessResponse(res);
			logAPI(`POST RESPONSE`,res);
			return this.deserialize(res.data);
		}).catch(error=>{
			this.preprocessResponse(error.response);
			if(error.response && error.response.data && error.response.data.data){
				error.response.data = this.deserialize(error.response.data.data);
			}
			throw error;
		});
	},

	async patch(params){
		if(!params.endpoint){
			throw new Error(`BlackoutAPI: post: missing endpoint`);
		}
		let data;
		if(params.data){
			data = JSON.stringify(params.data);
		} else {
			throw new Error(`BlackoutAPI: post: requires data`);
		}
		
		let url = this.queryBuilder(params);
		params.headers = this.processHeaders(params.headers);
		
		logAPI(`PATCH`,url,data);
		return axios.patch(url, data, { headers: { ...defaultHeaders, ...params.headers } }).then(res=>{
			this.preprocessResponse(res);
			logAPI(`PATCH RESPONSE`,res);
			return this.deserialize(res.data);
		}).catch(error=>{
			this.preprocessResponse(error.response);
			if(error.response && error.response.data && error.response.data.data){
				error.response.data = this.deserialize(error.response.data.data);
			}
			throw error;
		});
	},

	async delete(params){
		if(!params.endpoint){
			throw new Error(`BlackoutAPI: delete: missing endpoint`);
		}
		
		let url = this.queryBuilder(params);
		params.headers = this.processHeaders(params.headers);
		
		logAPI(`DELETE`,url);
		return axios.delete(url, { headers: { ...defaultHeaders, ...params.headers } }).then(res=>{
			this.preprocessResponse(res);
			logAPI(`DELETE RESPONSE`,res);
		}).catch(error=>{
			this.preprocessResponse(error.response);
			if(error.response && error.response.data && error.response.data.data){
				error.response.data = this.deserialize(error.response.data.data);
			}
			throw error;
		});
	},
	
	deserialize(item){
		if(util.isObject(item)){
			
			if(item.id && item.type && item.attributes){
						
				// Split out fields containers
				let attributes = item.attributes;
				let relationships = item.relationships;
				delete item.attributes;
				delete item.relationships;
				
				// Merge in attributes
				Object.assign(item,attributes);
				
				// Process relationships
				if(relationships){
					for( let key in relationships ){
						
						let relationship = relationships[key];
						if(relationship && relationship.data){
							
							if(util.isArray(relationship.data)){
								item[key] = relationship.data.map(i=>i.id);
							} else {
								item[key] = relationship.data.id;
							}
							
						}
					}
				}
				
			} else {
				
				// Recursively process potential nested data
				for( let key in item ){
					item[key] = this.deserialize(item[key]);
				}
				
			}
			
		} else if(util.isArray(item)){
			
			// Recursively process potential nested data
			item.forEach(item=>{
				this.deserialize(item);
			});
			
		}
		return item;
	},
	
});
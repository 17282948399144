import React from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

function Club(props) {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let club = props.club;
	
	return (
		<div className={`club-cell sub-panel${props.center?' flex-row-center':''}`}>
			<div className="club-shield-container">
				<div className={(club.logoImageURL ? ''
					: 'show-placeholder') + " club-logo center-div"} style={{backgroundImage:club.logoImageURL ? `url(https://dhndi3mnxrz0u.cloudfront.net/v1/images?imageUrl=${encodeURIComponent(club.logoImageURL)}&size=128&mode=cover)` : 'none'}}>
				</div>
			</div>
			<div className="club-name-container">
				<h4 className="club-name">
					{club.name}
				</h4>
				<div className="club-level-container">
					<div className="club-level-text">{i18n('account.club-level','Level')}</div>
					<div className="club-level">{club.level}</div>
				</div>
			</div>
		</div>
	);
	
}

export default Club;
import React from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

function RugbyHomeHeader(){

	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	const isPhone = useMediaQuery({ query: globals.phoneMediaQuery });

	// Determine subtitle image
	let subtitleImage = `${globals.config.baseImageUrl}/website/blackout.rugby/2020/home-title-${isPhone ? 'tall' : 'wide'}.png`;
	
	return (
		<div>
			<div>
				<img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2023/logo-wc-min.png`} alt="Blackout Rugby" id="home-rugby-logo" />
			</div>
			<div>
				<img src={subtitleImage} alt={i18n('home.title', '#1 MMO Rugby Manager Game')} className="home-subtitle" />
			</div>
			<div>
				<a href="https://store.steampowered.com/app/1157960/Blackout_Rugby_Manager/" target="_blank" rel="noopener noreferrer" className="home-header-button download">{i18n('buttons.download', 'Download Now')}</a>
				<Link to="/signup" className="home-header-button signup">{i18n('buttons.signup', 'Signup')}</Link>
			</div>
			<div className="home-text">
				{i18n('home.availability', 'Available now on Steam, iOS and Android!')}
			</div>
			<div className="home-store-buttons">
				<span className="store-button"><a href="https://store.steampowered.com/app/1157960" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-steam.png`} className="platform-image" alt="Download on Steam" /></a></span>
				<span className="store-button">
					<a href="https://apps.apple.com/us/app/blackout-rugby-manager/id1539351070" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-apple.png`} className="platform-image" alt="Download on iOS" /></a>
				</span>
				<span className="store-button last">
					<a href="https://play.google.com/store/apps/details?id=rugby.blackout.android" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/download-google.png`} className="platform-image" alt="Download on Android" /></a>
				</span>
			</div>
		</div>
	);

}

export default RugbyHomeHeader;
import React from 'react';
import { logRender } from './lib/Debug';
import Globals from './contexts/Globals';
import I18nLib from './lib/I18nLib';

import HomeHeader from './components/HomeHeader';
import Testimonies from './components/Testimonies';
import Footer from './components/Footer';
import FootballFeatures from './components/football/FootballFeatures';
import RugbyFeatures from './components/rugby/RugbyFeatures';

function Home() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	logRender(`Home -- render`);
	
	return (
		<div id="home">
			<HomeHeader />
			<div id="home-testimonies" className="clearfix"> <Testimonies /> </div>
			{/* Rugby */}
			<div className={globals.transition ? 'fade-out' : 'fade-in'}>
				{
					globals.sport === 'rugby' && !globals.transition &&
					<div>
						<div id="home-features" className="clearfix flex-column-center">
							<RugbyFeatures />
						</div>
					</div>
				}
			</div>

			<div className={globals.transition ? 'fade-in' : 'fade-out'}>
				{
					globals.sport === 'football' && globals.transition &&
					<div>
						<div id="home-features" className="clearfix flex-column-center">
							<FootballFeatures />
						</div>
					</div>
				}
			</div> 

			<div id="home-community" className="clearfix flex-column-center">
				<div id="community-text" className={`center-text ${globals.sport}`}>
					{i18n('home.stay-up-to-date','For news and announcements')}
				</div>
				<div id="community-wrapper" className="flex-row-center">
					<img id="community-title" src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/home-community-title-centered.png`} alt="Join the Community" />
					<div className="flex-column-center">
						<a href={globals.sportConfig.discordUrl} target="_blank" rel="noopener noreferrer"><img id="community-discord" src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/discord-logo-white.png`} alt="Discord" /></a>
					</div>
				</div>
			</div>
			<div id="home-footer" className="clearfix">
				<Footer/>
			</div>
		</div>
	);
	
}

export default Home;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import { logRender } from '../lib/Debug';

import Panel from './Panel';

function PageNotFound() {
	
	let randomGifs = [
		'https://media.giphy.com/media/l1J9EdzfOSgfyueLm/giphy.gif', // No signal static
		'https://media.giphy.com/media/YRcXl6VfNhCorklI0R/giphy.gif', // Simple static
		'https://giphygifs.s3.amazonaws.com/media/BDqInV6xYl1Ju/giphy.gif', // Spaz static
		'https://media.giphy.com/media/J6DKSSrTUoSqiCuT8Z/giphy.gif', // No signal colour tv
		'https://giphygifs.s3.amazonaws.com/media/FkUyGd7FDh1gk/giphy.gif', // Simple color tv
		'https://media.giphy.com/media/YzLIczpU0mZB6/giphy.gif', // Kirk
		'https://media.giphy.com/media/26Ff8cTdHrcyQ1ZLO/giphy.gif', // Old school color tv
		'https://media.giphy.com/media/1xndSitiu4NSbrJNxI/giphy.gif', // ESPN clap
	];
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [randomGif,] = useState(randomGifs[Math.floor(Math.random()*randomGifs.length)]);
	
	logRender('PageNotFound -- render');
	
	return (
		<Panel>
			<div className="center-text">
				<h1 className="blurb">404 {i18n('misc.page-not-found','Page Not Found')}</h1>
				<h4><Link to="/">{i18n('misc.return-to-safety','Return to Safety')}</Link></h4>
				<img src={randomGif} className="gif404" alt="404: Page not found"/>
			</div>
		</Panel>
	);
	
}

export default PageNotFound;
import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ResizeObserver } from '@juggle/resize-observer';
import Globals from './contexts/Globals';
import { logRender } from './lib/Debug';

import AppSwitch from './components/AppSwitch';
import Unsubscribe from './components/Unsubscribe';
import Verify from './components/Verify';
import ResetPassword from './components/ResetPassword';
import AdViewer from './components/AdViewer';
import PageNotFound from './components/PageNotFound';
import CookieFooter from './components/CookieFooter';
import Matches from './components/Matches';
import FormationEditor from './components/FormationEditor';
import NameDatabase from './components/NameDatabase';
import ChatAlerts from './components/ChatAlerts';

function App(props) {
	
	let globals = Globals.useContainer();
	let [isWatchingSize,setIsWatchingSize] = useState(false);
	const showCookieFooter = false;
	
	useEffect(()=>{
		
		if(!isWatchingSize){
			setIsWatchingSize(true);
			
			const ro = new ResizeObserver((entries) => {
				//console.log('APP has resized!',entries[0].contentRect.height);
				globals.setAppHeight(entries[0].contentRect.height);
			});

			ro.observe(document.getElementById('app')); // Watch dimension changes on body
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	//----------------------------- Styles
	
	let containerClassName = ['main-container'];
	if(globals.userState.loggedIn){
		containerClassName.push('sub-bar-showing');
	}
	
	logRender('App -- render');
	
	return (
		<div id="app" style={{bottom: `${props.homeHeight}px`}}>
			<div className={containerClassName.join(' ')}>
				<Switch>
					<Route exact path='/' component={AppSwitch} />
					<Route exact path='/invite' component={AppSwitch} />
					<Route exact path='/invite/:refCode' component={AppSwitch} />
					<Route exact path='/login' component={AppSwitch} />
					<Route exact path='/signup' component={AppSwitch} />
					<Route exact path='/profile' component={AppSwitch} />
					<Route exact path='/settings' component={AppSwitch} />
					<Route exact path='/subscriptions' component={AppSwitch} />
					<Route exact path='/feedback' component={AppSwitch} />
					<Route exact path='/feedback/:base64' component={AppSwitch} />
					<Route exact path='/unsubscribe' component={Unsubscribe} />
					<Route exact path='/unsubscribe/:data' component={Unsubscribe} />
					<Route exact path='/verify' component={Verify} />
					<Route exact path='/verify/:userId' component={Verify} />
					<Route exact path='/forgotpassword' component={ResetPassword} />
					<Route exact path='/forgotpassword/:emailParam' component={ResetPassword} />
					<Route exact path='/ads' component={AdViewer} />
					<Route exact path='/ads/:clubId' component={AdViewer} />
					<Route exact path='/matches' component={Matches} />
					<Route exact path='/matches/:id' component={Matches} />
					<Route exact path='/formation-editor' component={FormationEditor} />
					<Route exact path='/name-database' component={NameDatabase} />
					<Route exact path='/chat-alerts' component={ChatAlerts} />
					<Route path="*" component={PageNotFound} />
				</Switch>
			</div>
			{showCookieFooter?<CookieFooter />:null}
		</div>
	);
	
}

export default App;


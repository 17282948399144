import React,{ useState, useEffect } from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import UsersLib from '../lib/UsersLib';
import BlackoutAPI from '../lib/BlackoutAPI';
import { useParams } from 'react-router-dom';
import './rugby2d/rugby2d.scss';
import cookies from 'js-cookie';
import moment from 'moment';
import { logError } from '../lib/Debug';
import testAnim from './rugby2d/anim.json';

import Rugby2d from './rugby2d/Rugby2d';
import LoadingBlinker from './LoadingBlinker';
import LoaderButton from './LoaderButton';

let fixtureRefreshTimeout;
let searchRequestIdx = 0;
let animCache = {};

function Matches() {
	const { id } = useParams();
	if(id) console.log('load match',id);
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	const [isShowingMatch,setIsShowingMatch] = useState(false);
	const [anim,setAnim] = useState(null);
	const [animClubNames,setAnimClubNames] = useState({});
	const [hasProcessedUser,setHasProcessedUser] = useState(false);
	const [isLoading,setIsLoading] = useState(!!id);
	const [isDeveloper,setIsDeveloper] = useState(false);
	const [query,setQuery] = useState('');
	const [mustFindAWinner,setMustFindAWinner] = useState(false);
	const [forceKickingComp,setForceKickingComp] = useState(false);
	const [isSearching,setIsSearching] = useState(false);
	const [isSelectingClub,setIsSelectingClub] = useState(false);
	const [searchClubs,setSearchClubs] = useState([]);
	const [homeClub,setHomeClub] = useState(null);
	const [opponentClub,setOpponentClub] = useState(null);
	const [isLoadingFixtures,setIsLoadingFixtures] = useState(false);
	const [clubFixtures,setClubFixtures] = useState([]);
	const [secondsUntilNextCheck,setSecondsUntilNextCheck] = useState(5);
	const [isCreatingMatch,setIsCreatingMatch] = useState(false);
	const [noMatchDataFound,setNoMatchDataFound] = useState(false);
	const [createError,setCreateError] = useState(null);
	
	useEffect(()=>{
		
		// Use /matches/local to run a local anim.json file (at components/rugby2d/anim.json)
		if(id){
			loadAndViewMatch(id);
			return;
		}
		
		let token = cookies.getJSON('token');
		
		if(!globals.userState.loggedIn){
			if(token && !isLoading && !hasProcessedUser){
				setIsLoading(true);
				
				// Checks if token needs refresh, and if so, refreshes it
				UsersLib.getUserFromToken(token).then(({promise,currentUser:userState})=>{
					
					let handleUser = userState => {
						
						globals.mergeUserState(userState);
						
						if(userState && userState.clubs && userState.clubs.length){
							setHomeClub(userState.clubs[0]);
							loadFixturesForClub(userState.clubs[0]);
						}
						
						// Token was refreshed and we already have user and clubs
						setHasProcessedUser(true);
						setIsLoading(false);
						
						if(userState.manager.isDeveloper){
							setIsDeveloper(true);
						}
						console.log('userState',userState);
						
					};
					
					if(userState){
						handleUser(userState);
						promise.then(userState=>{
							globals.mergeUserState(userState);
						});
					} else {
						promise.then(userState=>{
							handleUser(userState);
						});
					}
					
				}).catch(err=>{
					logError(err);
					setHasProcessedUser(true);
					setIsLoading(false);
					UsersLib.logout(globals);
				});
			} else if(!isLoading) {
				setHasProcessedUser(true);
			}
		} else if(globals.userState.manager.isDeveloper){
			setIsDeveloper(true);
		}
		
		return ()=>{
			if(fixtureRefreshTimeout) clearTimeout(fixtureRefreshTimeout);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const loadFixturesForClub = (club,isRefresh=false) => {
		if(!isRefresh) setIsLoadingFixtures(true);
		BlackoutAPI.get({
			endpoint: 'fixtures',
			filter: { key: 'club', value: club.id },
			queryParams: `past=1&minDate=${moment().subtract(7,'days').toISOString()}&include=homeClub,guestClub&fields[clubs]=name&fields[fixtures]=homeClub,guestClub,date,competition,endDate`,
		}).then(res=>{
			setIsLoadingFixtures(false);
			// Add club names to fixtures
			let fixturesInProgress = false;
			for( let f of res.data ){
				let homeClub = res.included.find(i=>i.type==='clubs' && i.id===f.homeClub);
				let guestClub = res.included.find(i=>i.type==='clubs' && i.id===f.guestClub);
				f.homeClubName = homeClub.name;
				f.guestClubName = guestClub.name;
				if(!f.endDate) fixturesInProgress = true;
			}
			setClubFixtures(res.data);
			
			if(fixturesInProgress){
				setSecondsUntilNextCheck(5);
				fixtureRefreshTimeout = setTimeout(()=>{
					refreshFixtures(4,club,res.data);
				},1000);
			}
		}).catch(e=>{
			logError(e);
			setIsLoadingFixtures(false);
			setClubFixtures([]);
		});
	};
	
	const refreshFixtures = (secondsRemaining,club,currentFixtures) => {
		if(secondsRemaining>0){
			fixtureRefreshTimeout = setTimeout(()=>{
				refreshFixtures(secondsRemaining-1,club,currentFixtures);
			},1000);
			setSecondsUntilNextCheck(secondsRemaining);
		} else {
			currentFixtures.forEach(f=>{
				if(!f.endDate) f.isRefreshing = true;
			});
			setClubFixtures([...currentFixtures]);
			loadFixturesForClub(club,true);
		}
	};
	
	const handleHomeClubChange = e => {
		let newHomeClub = globals.userState.clubs.find(c=>c.id===e.target.value);
		if(newHomeClub) setHomeClub(newHomeClub);
		if(!homeClub || homeClub.id !== newHomeClub.id){
			loadFixturesForClub(newHomeClub);
		}
	};
	
	const searchForClubs = e => {
		if(e.target.value && e.target.value !== query){
			setIsSearching(true);
			setIsSelectingClub(true);
			setOpponentClub(null);
			setCreateError(null);
			searchRequestIdx++;
			let thisSearchIdx = searchRequestIdx;
			BlackoutAPI.get({
				endpoint: 'search',
				queryParams: `q=${e.target.value}*&target=clubs&max=10&filter=${encodeURIComponent('isActive:{equals:1}')}`,//&filter=isActive:{equals:1}
			}).then(res=>{
				if(thisSearchIdx >= searchRequestIdx){
					setIsSearching(false);
					if(res.results && res.results.clubs){
						let filteredClubs = res.results.clubs.filter(c=>!homeClub || ((c.payload && c.payload.Id) || c.Id)!==homeClub.id);
						filteredClubs.forEach(c=>{
							if(!c.payload && c.Id){
								c.payload = { Id: c.Id };
								c.match = c.Name;
							}
						});
						setSearchClubs(filteredClubs);
					} else {
						setSearchClubs([]);
					}
				}
			}).catch(e=>{
				logError(e);
				setIsSearching(false);
				setSearchClubs([]);
			});
		}
		setQuery(e.target.value);
	};
	
	const createMatch = () => {
		setIsCreatingMatch(true);
		let headers = {
			'use-test-branch': 1,
			'Content-Type': 'application/vnd.api+json',
			Token: globals.userState.token.accessToken
		};
		if(mustFindAWinner) headers['must-find-a-winner'] = 1;
		if(forceKickingComp) headers['force-kicking-comp'] = 1;
		BlackoutAPI.post({
			endpoint: 'friendlies',
			queryParams: `instant`,
			headers,
			data:{
				type:'friendlies',
				attributes: {
					instant: true,
					initiatorClub: homeClub.id,
					opponentClub: opponentClub.id,
					homeGame: true,
				}
			},
		}).then(res=>{
			console.log('res',res);
			setIsCreatingMatch(false);
			if(res.data && res.data.id){
				setOpponentClub(null);
				loadFixturesForClub(homeClub);
			}
		}).catch(e=>{
			if(e.response){
				console.log('e.response.data',e.response.data);
				if(e.response.data.errors && e.response.data.errors.message)
					setCreateError(e.response.data.errors.message);
			}
			logError(e);
			setIsCreatingMatch(false);
		});
	};
	
	const selectOpponentClub = (club) => {
		setIsSelectingClub(false);
		setOpponentClub({ id: club.payload.Id, name: club.match });
		setQuery(club.match);
	};
	
	const cancelSearch = () => {
		setIsSelectingClub(false);
		setOpponentClub(null);
		setQuery('');
	};
	
	const toggleMustFindAWinner = () => {
		let newMustFindAWinner = !mustFindAWinner;
		setMustFindAWinner(newMustFindAWinner);
		if(!newMustFindAWinner) setForceKickingComp(false);
	};
	
	const toggleForceKickingComp = () => {
		let newForceKickingComp = !forceKickingComp;
		setForceKickingComp(newForceKickingComp);
		if(newForceKickingComp) setMustFindAWinner(true);
	};
	
	const loadAndViewMatch = id => {
		console.log('View match',id);
		
		if(id === 'local'){
			showMatch(id,{homeClubName:'home',guestClubName:'guest'});
		}
		
		let fixture = clubFixtures.find(f=>f.id===id);
		
		if(animCache[id]){
			showMatch(animCache[id],fixture);
		} else {
			
			clubFixtures.forEach(f=>{
				if(f.id===id) f.isLoading = true;
			});
			setClubFixtures([...clubFixtures]);
			
			// Determine number of blocks first
			BlackoutAPI.get({
				endpoint: 'matches',
				filter: { key: 'fixture', value: id },
				queryParams: `fields[matches]=block&sort=block`,
			}).then(res=>{
				
				let blocks = res.data;
				
				const checkIfReady = () => {
					if(blocks.find(b=>!b.events)) return;
					
					let animData = {};
					
					blocks.forEach(b=>{
						b.events.forEach(e=>{
							if(e.a){
								for(let eventType in e.a){
									if(!(eventType in animData)){
										animData[eventType] = e.a[eventType];
									} else {
										Object.assign(animData[eventType],e.a[eventType]);
									}
								}
							}
						});
					});
					
					//console.log('animData',animData);
					animCache[id] = animData;
					showMatch(animData,fixture);
					
					clubFixtures.forEach(f=>{
						if(f.id===id) delete f.isLoading;
					});
					setClubFixtures([...clubFixtures]);
					
				};
				
				if(blocks.length){
					for( let block of blocks ){
						
						BlackoutAPI.get({
							endpoint: 'matches',
							id: `${id}-${block.block}`,
						}).then(res=>{
							block.events = res.data.events;
							checkIfReady();
						}).catch(e=>{
							logError(e);
							clubFixtures.forEach(f=>{
								if(f.id===id) delete f.isLoading;
							});
							setClubFixtures([...clubFixtures]);
						});
						
					}
				} else {
					setIsLoading(false);
					setNoMatchDataFound(true);
				}
				
			}).catch(e=>{
				logError(e);
				clubFixtures.forEach(f=>{
					if(f.id===id) delete f.isLoading;
				});
				setClubFixtures([...clubFixtures]);
			});
			
		}
	};
	
	const showMatch = (newAnim,fixture) => {
		setIsShowingMatch(true);
		setIsLoading(false);
		setAnim(newAnim === 'local' ? testAnim : (newAnim||testAnim));
		setAnimClubNames({ home: fixture.homeClubName, guest: fixture.guestClubName });
		var els = document.getElementsByTagName( 'html','body' );
		for( let el of els ) el.classList.add('noscroll');
	};
	
	const hideMatch = () => {
		setIsShowingMatch(false);
		setAnim(null);
		var els = document.getElementsByTagName( 'html','body' );
		for( let el of els ) el.classList.remove('noscroll');
	};
	
	return (
		<>
			{isLoading?(
				<LoadingBlinker text={id?'Loading match':i18n('account.loading','Loading account')} />
			):id?
				<div className="center-text flex-column-center">
					{noMatchDataFound?
					<>
						<div className="warning-text">No match data found</div>
						<div className="small-text">(Animation data is removed after 7 days)</div>
					</>
					:<>
						<LoaderButton
							onClick={()=>loadAndViewMatch(id)}
							className="loader-button sm-inline"
						>View Match</LoaderButton>
					</>}
				</div>:(
				<>
					<div className="flex-column gap-after sm">
						<h1 className="gap-before sm">Match Engine Testing</h1>
						{globals.userState.loggedIn && isDeveloper?(
							<div className="flex-row">
								<div className="flex-cell match-viewer-column l">
									<h3>Create Match</h3>
									<div className="input-container select-container">
										<select onChange={handleHomeClubChange} value={homeClub&&homeClub.id}>
											{globals.userState.clubs.map(c=>(
												<option value={c.id} key={c.id}>{c.name} (Level {c.level})</option>
											))}
										</select>
										<div className="select-arrow icon-down-dir"></div>
									</div>
									<h3>VS</h3>
									<div className="gap-after">
										<input 
											type="text"
											value={query} 
											onChange={searchForClubs}
											placeholder="Search"
										/>
										{opponentClub
											?<div><span className="success-text default-text-case"><span className="icon-ok-circled" />{opponentClub.id}</span></div>
											: null}
									</div>
									<div style={{marginBottom:'5px'}}>
										<label>
											<input type="checkbox" id="events" checked={mustFindAWinner} onChange={toggleMustFindAWinner} />
											<span>Must find a winner (No draws)</span>
										</label>
									</div>
									<div className="gap-after">
										<label>
											<input type="checkbox" id="events" checked={forceKickingComp} onChange={toggleForceKickingComp} />
											<span>Force extra-time & kicking comp</span>
										</label>
									</div>
									<div>
										<LoaderButton
											onClick={createMatch}
											disabled={!opponentClub}
											className="loader-button"
											isLoading={isCreatingMatch}
										>
											Create
										</LoaderButton>
									</div>
									{createError?<div className="gap-before sm"><span className="error-text default-text-case"><span className="icon-attention" />{createError}</span></div>:null}
								</div>
								<div className="flex-cell match-viewer-column r">
									{isSelectingClub?(
										<>
											<div className="flex-row">
												<div className="flex-grow">
													<h3>Search Results</h3>
												</div>
												<div className="flex-grow right-text">
													<button onClick={cancelSearch} className="button-reset close-button"><span className="icon-cancel" /></button>
												</div>
											</div>
											{isSearching?(
												<LoadingBlinker align="left" />
											):searchClubs.length ? searchClubs.map(c=>(
												<button key={c.payload.Id} onClick={()=>selectOpponentClub(c)} className="button-reset search-result-button">{c.match}</button>
											)):(
												<>No clubs found</>
											)}
										</>
									):(
										<>
											<h3>Recent Fixtures</h3>
											{isLoadingFixtures?(
												<LoadingBlinker align="left" />
											):clubFixtures.length ? clubFixtures.map(f=>(
												<div key={f.id} className="fixture-panel">
													<div className="flex-row flex-align-stretch">
														<div className="flex-cell">
															<div className="fixture-clubs"><span className="fixture-club">{f.homeClubName}</span> v <span className="fixture-club">{f.guestClubName}</span></div>
															<div className="small-text">
																<a href={`/matches/${f.id}`} target="_blank" rel="noopener noreferrer"><span className="icon-export-alt" /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																{moment(f.date).format('ddd h.mma')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
																<span className="fixture-comp">{f.competition}</span>
															</div>
														</div>
														<div className="flex-fixed fixture-status">
															{f.endDate?
															<LoaderButton
																	onClick={()=>loadAndViewMatch(f.id)}
																	className="loader-button sm-inline"
																	isLoading={f.isLoading}
																>
																	View
															</LoaderButton>
															:<>
																<div className="warning-text">In progress</div>
																{f.isRefreshing?
																<div style={{paddingTop:'5px'}}>
																	<LoadingBlinker align="left" />
																</div>
																:<div className="small-text">Refreshing in {secondsUntilNextCheck}</div>}
															</>}
														</div>
													</div>
												</div>
											)):(
												<>No past fixtures found for {homeClub?.name}</>
											)}
										</>
									)}
								</div>
							</div>
						):(
							<>
								<div className="warning-text">Please log-in as a developer to use match viewer tools</div>
							</>
						)}
					</div>
				</>
			)}
			<div className="rugby-2d-bg" style={{display:isShowingMatch?'block':'none'}}>
				<div className="rugby-2d-overlay">
					<button onClick={hideMatch} className="button-reset close-button rugby2d"><span className="icon-cancel" /></button>
					<Rugby2d anim={anim} homeClubName={animClubNames.home} guestClubName={animClubNames.guest} />
				</div>
			</div>
		</>
	);
	
}

export default Matches;
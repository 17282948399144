import React, { useState } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logRender, logError } from '../../lib/Debug';

import BlackoutAPI from '../../lib/BlackoutAPI';
import util from '../../lib/util';
import LoaderButton from '../LoaderButton';

function UpdateAccountInfo() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [isUpdatingAccount,setIsUpdatingAccount] = useState(false);
	let [validationMessages,setValidationMessages] = useState({});
	let [username,setUsername] = useState(globals.userState.manager.username);
	let [email,setEmail] = useState(globals.userState.manager.email);

	// updates input states
	const handleChange = (e) => {
		if(e.target.id==='nickname'){
			if(validationMessages.username){
				delete validationMessages.username;
				setValidationMessages({...validationMessages});
			}
			setUsername(e.target.value);
		}
		else if(e.target.id==='username'){ // Email, but we use username for password managers
			if(validationMessages.email){
				delete validationMessages.email;
				setValidationMessages({...validationMessages});
			}
			setEmail(e.target.value);
		}
	};

	const handleFocus = (e) => {
		if(validationMessages && validationMessages[e.target.id]){
			delete validationMessages[e.target.id];
			setValidationMessages({...validationMessages});
		}
	};
	
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			updateAccount();
		}
	};

	const updateAccount = () => {
		let attributes = {};
		let validInputs = true;
		let changeDetected = false;

		// New username
		if(username){

			if(username.length < 1 || username.length > 22){
				validationMessages.username = `${i18n('account.username-length','Username must contain between 1 and 22 characters')} (a-z,A-Z,0-9)`;
				validInputs = false;
			} else {
				if(!util.regexCheck(username)){
					validationMessages.username = `${i18n('account.username-length','Username must contain between 1 and 22 characters')} (a-z,A-Z,0-9)`;
					validInputs = false;
				} else {
					if(username !== globals.userState.manager.username){
						changeDetected = true;
						attributes.Username = username;
					}
				}
			}
		}

		// New email
		if(email){
			if(!util.isEmail(email)){
				validationMessages.email = i18n('account.invalid-email',`Invalid email address`);
				validInputs = false;
			} else {
				if(email !== globals.userState.manager.email){
					changeDetected = true;
					attributes.Email = email;
				}
			}
		}
		if(!validInputs){
			setValidationMessages({...validationMessages});
			return;
		}
		if(!changeDetected){
			const element =  document.getElementById('updateButton');
			element.classList.remove('animated', 'shake');
			window.setTimeout(()=>{
				element.classList.add('animated', 'shake');
			},1);
			return;
		}
		
		setIsUpdatingAccount(true);
		
		// Send blackout api post request ......
		BlackoutAPI.patch({
			endpoint: 'managers',
			id: globals.userState.manager.id,
			headers: {
				Token: globals.userState.token.accessToken,
				'Content-Type': 'application/vnd.api+json',
			},
			data: {
				type: 'managers',
				id: globals.userState.manager.id,
				attributes
			}
		}).then(res=>{
			setIsUpdatingAccount(false);
			
			log(`updateAccount`,res);
			globals.mergeUserState({ manager: res.data });
			
		}).catch(err=>{
			let validationMessages = {};
			setIsUpdatingAccount(false);
			if(err.response){
				err = err.response;
				logError(`updateAccount`,err);
				if(err.data.errors.message.includes('Name contained profanity')){
					validationMessages.username = i18n('account.username-profanity','Profanity found in username');
				} else if(err.data.errors.message.includes('is not a valid email address')){
					validationMessages.email = i18n('account.invalid-email','Invalid email address');
				} else if(err.data.errors.message.toLowerCase().includes('conflicts')||err.data.errors.message.toLowerCase().includes('exists')){
					validationMessages.email = i18n('signup.email-already-exists','This email address is already registered');
				} else {
					validationMessages.email = err.data.errors.message;
				}
			} else {
				// 500 errors don't have .response
				logError(err);
				validationMessages.email = i18n('account.error-see-console','Error. See console for details.');
			}
			setValidationMessages({...validationMessages});
			
		});
		
	};

	const updateAccountForm = () => {
		return (
			<div>
				<div className="input-container">
					<span className="input-label">Username</span>
					<input 
						id='nickname' 
						type="text"
						placeholder={`*${i18n('account.username','Username')}`}
						onChange={handleChange}
						onFocus={handleFocus}
						onKeyDown={handleKeyDown}
						value={username}
						autoComplete='nickname'
					/>
					{validationMessages
						&& validationMessages.username
						? <span className="validation-text"><span className="icon-attention" />{validationMessages.username}</span>
						: ''
					}
				</div>

				<div className="input-container">
					<span className="input-label">Email</span>
					<input 
						id='username'  // Email, but we use username for password managers
						type="email" 
						placeholder={`*${i18n('login.email','Email')}`}
						onChange={handleChange}
						onFocus={handleFocus}
						onKeyDown={handleKeyDown}
						value={email}
						disabled={globals.userState.manager.facebookId && !globals.userState.manager.passwordSet}
						autoComplete='username'
					/>
					{validationMessages
						&& validationMessages.email
						? <span className="validation-text"><span className="icon-attention" />{validationMessages.email}</span>
						: (globals.userState.manager.facebookId && !globals.userState.manager.passwordSet ? <div className="form-minor-message warning"><span className="icon-info-circled" />{i18n('account.email-locked','Email is locked until you set a password')}</div> : '')
					}
				</div>
				
				<LoaderButton
					onClick={updateAccount}
					className="sm"
					isLoading={isUpdatingAccount}
					id="updateButton"
				>{i18n('buttons.update','Update')}</LoaderButton>
			</div>
		);
	};
	
	logRender(`UpdateAccountInfo -- render`);

	return (
		<div className="sub-panel">
			{updateAccountForm()}
		</div>
	);
	
}

export default UpdateAccountInfo;
import React, { useEffect, useState } from "react";
import BlackoutAPI from "../lib/BlackoutAPI";
import { logError } from "../lib/Debug";
import util from "../lib/util";
import sortBy from 'sort-by';
import cookies from "js-cookie";


function NameDatabase() {
  const [selectNameType, setSelectNameType] = useState("first-names");
  const [selectedNameType, setSelectedNameType] = useState("First Name"); //for database options
  const [selectedCountry, setSelectedCountry] = useState("");
  const [country, setCountry] = useState("AF"); //retrieve names by country from API
  const [nameData, setNameData] = useState([]); //store names
  const [filterName, setFilterName] = useState(""); //name filter keyword
  const [filteredList, setFilteredList] = useState(nameData); //for name filter
  const [isBeingFiltered, setIsBeingFiltered] = useState(false);
  const [newName, setNewName] = useState(); //adding new name
  const [changeId, setChangeId] = useState(""); //get the id of the name to be changed
  const [deletedName, setDeletedName] = useState(""); //get name to be deleted
  const [countriesList, setCountriesList] = useState([]); //countries list
  const [isAddingNewName, setIsAddingNewName] = useState();
  const [nameNotification, setNameNotification] = useState(false);
  const [fieldReset, setFieldReset] = useState(false); //reset fields
  const [isLoggedIn, setIsLoggedIn] = useState(false); //check if user is logged in

  let token = cookies.getJSON('token'); //checks for access token (if user has logged in)

  const countryList = countriesList;

  useEffect(() => {
	if(token){
		setIsLoggedIn(true);
	} 

}, [token]);

  //get ethnicity codes
  useEffect(() => {
    BlackoutAPI.get({
      endpoint: "settings/ethnicity",
      sort: "code",
    }).then((res) => {
      return BlackoutAPI.get({
        endpoint: "countries",
        ids: `${res.data.value.join(',')}`,
      }).then((res) => {
        res.data.sort(sortBy('name'));
        setCountriesList(res.data);
      });
    });
  }, []);

  //get names list
  useEffect(() => {
    BlackoutAPI.get({
      endpoint: `${selectNameType}`,
      filter: { key: "Country", value: country },
      sort: "name",
    }).then((res) => {
      setNameData(res.data);
    });
  }, [country, selectNameType, fieldReset]);


  /*   useEffect(() => {
  
      console.log(deletedName + " " + changeId);
  
    }, [changeId, deletedName]); */

  const nameFilter = (e) => {
    setIsBeingFiltered(true);
    const nameKeyword = e.target.value;
    const namesFilter = nameData.filter((name) =>
      name.name.toLowerCase().includes(nameKeyword.toLowerCase())
    );

    if (nameKeyword && selectNameType === "first-names") {
      setFilteredList(namesFilter);
    } else if (nameKeyword && selectNameType === "last-names") {
      setFilteredList(namesFilter);
    } else {
      //setFilteredList(nameData);
      setIsBeingFiltered(false);
    }
    setFilterName(nameKeyword);
  };

  const selectCountryDataHandler = (e) => {
    const selectCountry = e.target.value;
    setSelectedCountry(selectCountry);
    setCountry(selectCountry);
    console.log('country list', countryList);

  };

  const selectNameTypeHandler = (e) => {
    const selectedNameDatabase = e.target.value;

    if (selectedNameDatabase === "First Name") {
      setSelectedNameType(e.target.value);
      setSelectNameType("first-names");
    } else {
      setSelectedNameType(e.target.value);
      setSelectNameType("last-names");
    }
    console.log(selectedCountry);

  };

  const addingNameHandler = () => {
    setIsAddingNewName(true);
  };

  const stopAddingNameHandler = () => {
    setIsAddingNewName(false);

  };

  const newNameHandler = (e) => {
	setNewName(util.toTitleCase(e.target.value));

  };

  const nameEditHandler = (e, id) => {
	//eslint-disable-next-line
    let nameValue = [...nameData]; //for the array
    nameValue = nameValue.map((n) => {
		if(n.id === id) {
			n.name = e.target.value;}
		return n;
    });
	// console.log('e.target.value', e.target.value);
	// console.log('nameValue', nameValue);

	setNewName(util.toTitleCase(e.target.value));

  };

  const nameChangeHandler = (e, id) => {
    setChangeId(id);

	let result = window.confirm("Confirm name change to " + e.target.value + "?");

    if (util.isEmpty(newName)) {
      console.log("no new name entered");
    } else if(util.isEmpty(token)){
      console.log("token is empty");
    } else if (result) {
      changeName();
      //console.log("name has been entered");
    }
    console.log('is token empty: ', util.isEmpty(token));
  };

  const deleteNameHandler = (e, id) => {
    setDeletedName(e.target.value);
    setChangeId(id);
	// console.log('data', e);
	// console.log('id', id);

    let result = window.confirm("Delete name " + e.target.value + "?");
    
    if(util.isEmpty(token)){
      console.log("token is empty");
    } else if (result) {
      deleteName();
    }
  };

  const saveNewName = () => {
    if(util.isEmpty(token)){
      console.log("token is empty");
    }else if (util.isEmpty(newName)) {
      console.log("name is empty");
    } else {
      console.log("adding name: " + newName);
      let promise = BlackoutAPI.post({
        endpoint: `${selectNameType}`,
        headers: {
          "Content-Type": "application/vnd.api+json",
          Token: token.accessToken,
        },
        data: {
          type: `${selectNameType}`,
          attributes: {
            name: `${newName}`,
            country: `${selectedCountry}`,
          },
        },
      })
        .then((res) => {
          console.log(res);
          setNameNotification(true);
          resetFields();
          //needs confirmation message
        })
        .catch((err) => {
          logError(`err.message`, err.message);
        });
      return { promise };
    }
  };

  const changeName = () => {
    /*     console.log("change to new name: " + newName);
        console.log("country: " + country);
        console.log(changeId); */

    let promise = BlackoutAPI.patch({
      endpoint: `${selectNameType}/${changeId}`,
      headers: {
        "Content-Type": "application/vnd.api+json",
        Token: token.accessToken,
        // Token: "aEA4wkfwo2MyH6LX9kmTtxAVqYynJ9nIBIkmyH3z",
      },
      data: {
        type: `${selectNameType}`,
        id: `${changeId}`,
        attributes: {
          name: `${newName}`,
        },
      },
    })
      .then((res) => {
        console.log(res);
        resetFields();
        //needs confirmation message
      })
      .catch((err) => {
        logError(`err.message`, err.message);
      });
    return { promise };

  };

  const deleteName = () => {
    // console.log(changeId);
    // console.log("deleting name: " + deletedName);
    // console.log("deleting id: " + changeId);
    let promise = BlackoutAPI.delete({
      endpoint: `${selectNameType}/${changeId}`,
      headers: {
        "Content-Type": "application/vnd.api+json",
        Token: token.accessToken,
        // Token: "aEA4wkfwo2MyH6LX9kmTtxAVqYynJ9nIBIkmyH3z",
      },
      data: {
        type: `${selectNameType}`,
        id: `${changeId}`,
        attributes: {
          name: `${deletedName}`,
        },
      },
    })
      .then((res) => {
        console.log(res);
        resetFields();

      })
      .catch((err) => {
        logError(`err.message`, err.message);
      });
    return { promise };

  };

  const resetFields = () => {
    console.log("resetting fields");
    setNewName("");
    setIsBeingFiltered(false);
    setIsAddingNewName(false);
    setFilterName("");
    setFieldReset(true);
	timeoutMessage();
  };

	const timeoutMessage = () => {
		setNameNotification(true);

		setTimeout(() => { setNameNotification(false); }, 3000);
		
	};

  return (
    <div>
      <>
        <div className="flex-row-center" style={{ width: "100%" }}>
          <h1>Name Database</h1>
        </div>
		{isLoggedIn ? 
        <div className="flex-row flex-align-stretch">
          <div className="flex-column name-database-side-panel">
            <div style={{ minHeight: "470px" }}>
              <h2>
                Ethnicity
                <div className="name-database-name-dropdown">
                  <select
                    name="Country Name"
                    onChange={(e) => selectCountryDataHandler(e)}>
                    {countryList.map((country) => (
                      <option
                        key={country.id}
                        value={country.id}> {country.name}
                      </option>))}
                  </select>
                </div>
              </h2>
              <div className="name-database-overall-container">
                {isAddingNewName && (
                  <input
                    className="no-gap-after"
                    placeholder="Enter name"
                    onChange={newNameHandler}>
                  </input>)}
                {isAddingNewName && (
                  <button
                    className="name-database-edit-button"
                    onClick={saveNewName}
                    value={newName}>Add
                  </button>
                )}
                {isAddingNewName && (
                  <button
                    className="name-database-delete-button"
                    onClick={stopAddingNameHandler}>Cancel
                  </button>)}
                {!isAddingNewName && (
                  <button
                    className="name-database-edit-button"
                    onClick={addingNameHandler}>Add a new name
                  </button>
                )}
              </div>
              {nameNotification
                ? <span className="home-text small-text">{"Name database updated!"}</span>
                : ''
              }
            </div>
          </div>
          <div className="name-database-main-panel">
            <div style={{ minHeight: "470px" }}>
              <div className="name-database-overall-container">
                <div className="name-database-flex-container">
                  <h2>
                    Filter &nbsp;
                    <input
                      style={{ width: "50%" }}
                      type="text"
                      value={filterName}
                      onChange={nameFilter}
                      placeholder="Enter name" />
                  </h2>
                </div>
                <div className="name-database-name-dropdown">
                  <select
                    defaultValue={selectedNameType}
                    onChange={(e) => selectNameTypeHandler(e)}>
                    <option>First Name</option>
                    <option>Last Name</option>
                  </select>
                </div>
              </div>
              <div className="name-database-scroll-bar">
                <div className="name-database-overall-container">
                  <div className="name-database-flex-container">
                    {isBeingFiltered
                      ? filteredList.map((name) => (
                        <div key={name.id} className="name-database-item">
                          <textarea
                            name="edit name"
                            className="textarea"
                            rows="1"
                            value={name.name}
                            onChange={(e) => nameEditHandler(e, name.id)}
                          ></textarea>
                          <button
                            className="name-database-edit-button"
                            onClick={(e) => nameChangeHandler(e, name.id)}
                            value={name.name}>
                            Save
                          </button>
                          <button
                            className="name-database-delete-button"
                            onClick={(e) => deleteNameHandler(e, name.id)}
                            value={name.name}>
                            Delete
                          </button>
                        </div>))
                      : nameData.map((name) => (
                        <div key={name.id} className="name-database-item">
                          <textarea
                            name="edit name"
                            className="textarea"
                            rows="1"
                            value={name.name}
                            onChange={(e) => nameEditHandler(e, name.id)}
                          ></textarea>
                          <button
                            className="name-database-edit-button"
                            onClick={(e) => nameChangeHandler(e, name.id)}
                            value={name.name}>
                            Save
                          </button>
                          <button
                            className="name-database-delete-button"
                            onClick={(e) => deleteNameHandler(e, name.id)}
                            value={name.name}>
                            Delete
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
		: <p className="chat-alert-logged-in-p"> You must be logged in to view this page </p>}
      </>
    </div>
  );
}

export default NameDatabase;

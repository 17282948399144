import React,{} from 'react';
//import Globals from '../contexts/Globals';
import Draggable from 'react-draggable';

function FormationPlayer(props) {
	
	const playerRef = React.useRef();
	const player = props.player;
	const formationLib = props.formationLib;
	
	const handleDrag = (e,d) => {
		if(player.positionKey==='GK') return;
		props.patchPlayer(player,{x:d.x,z:d.y});
	};
	
	const handleMouseDown = e => {
		props.patchPlayer(player,{});
		e.stopPropagation();
	};
	
	if(isNaN(player.x)){
		let coords;
		if(player.fieldLocation){
			coords = formationLib.fromMetres(player.fieldLocation.x,player.fieldLocation.z);
		} else {
			coords = formationLib.fromFormationCoords(player.fx,player.fz);
		}
		player.x = coords.x;
		player.z = coords.z;
	}

	const jersey = Number(player.id.replace(/^P/,``));
	
	const defaultPosition = {x:player.x,y:player.z};
	const position = player.resetPosition ? defaultPosition : null;
	
	return (
		<>
			<Draggable
				axis={player.positionKey==='GK'?"none":"both"}
				bounds='parent'
				defaultPosition={defaultPosition}
				position={position}
				grid={[props.pixelsPerGrid.x, props.pixelsPerGrid.z]}
				scale={1}
				onDrag={handleDrag}
				nodeRef={playerRef}
				onMouseDown={handleMouseDown}>
				<div ref={playerRef} className={`formation-player${props.player.id===props.selectedPlayer?.id?` selected`:``}`}>
					<div className="formation-jersey-text">{jersey}</div>
					<div className="formation-jersey-label">{player.positionKey}</div>
				</div>
			</Draggable>
		</>
	);
	
}

export default FormationPlayer;
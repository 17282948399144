import React,{ useEffect } from 'react';

function LoaderButton(props) {
	
	let buttonRef = React.createRef();
	
	// This fluff fixes a bug if the user tabs to the buttons and presses the enter key, with lastpass installed
	// https://github.com/mui-org/material-ui/issues/14860
	useEffect(()=>{
		
		let handleHitEnter = e => {
			if (e.target === buttonRef.current && (e.key === 'Enter' || e.keyCode === 13)) {
				e.stopPropagation();
			}
		};
		
		document.addEventListener('keydown', handleHitEnter, true);
		return () => {
			document.removeEventListener('keydown', handleHitEnter, true);
		};
		
	},[buttonRef]);

	let buttonContent = props.isLoading
		? (<div className="la-ball-clip-rotate">
				<div></div>
			</div>)
		: props.children;
	
	let classNames = props.isLoading
		? props.className.split(' ').filter(c=>c.indexOf('icon')<0).join(' ')
		: props.className;
	
	return (
		<button
			ref={buttonRef}
			className={`loader-button ${classNames}`}
			onClick={props.onClick} 
			disabled={props.disabled ? props.disabled : props.isLoading} 
			type={props.isSubmitButton?"submit":"button"} 
			id={props.id}
		>
			{buttonContent}
		</button>
	);
	
}

export default LoaderButton;
import React,{useState,useEffect} from 'react';
import { useCookies } from 'react-cookie';

// Settings
let targetFps = 60;
let bgColor = `#313641`;
let fieldRatio = 1.712;
let ghostOpacity = 0.4;
let playerFadeMs = 300;

// Preset Globals
let currentMs = 0;
let startMs = null;
let lastMs = 0;
let finalFrameMs = 0;
let hasResized = false;
let eventsCache = {};
let players = [];
let highestFrame = 0;
let isPlaying = false;

// Holder Globals
let interval;
let ctx;
let paintWidth;
let fieldPos;
let fieldObj;
let fieldImageObj;
let ballImageObj;
let keyFrames;
let cachedClock;
let cachedActualClock;
let anim;

const resetGlobals = () => {
	currentMs = 0;
	startMs = null;
	lastMs = 0;
	finalFrameMs = 0;
	hasResized = false;
	eventsCache = {};
	players = [];
	highestFrame = 0;
	isPlaying = false;

	// Holder Globals
	interval = null;
	ctx = null;
	paintWidth = null;
	fieldPos = null;
	fieldObj = null;
	fieldImageObj = null;
	ballImageObj = null;
	keyFrames = null;
	cachedClock = null;
	cachedActualClock = null;
};

// Refs holders
let $canvas;
let $matchViewer;
let $slider;
let $handle;
let $fill;
let $currentEvent;
let $currentAm;
let $homePoints;
let $guestPoints;
let $specialTime;
let $clock;
let $actualTime;
let $sliderMap;

function Rugby2d(props) {
	
	let canvasRef = React.createRef();
	let matchViewerRef = React.createRef();
	let sliderRef = React.createRef();
	let sliderHandleRef = React.createRef();
	let sliderFillRef = React.createRef();
	let currentEventRef = React.createRef();
	let currentAmRef = React.createRef();
	let homePointsRef = React.createRef();
	let guestPointsRef = React.createRef();
	let specialTimeRef = React.createRef();
	let clockRef = React.createRef();
	let actualTimeRef = React.createRef();
	let sliderMapRef = React.createRef();
	
	const [canvasStyle,setCanvasStyle] = useState({});
	const [cookies, setCookie] = useCookies(['skipMissing']);
	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	
	// Runs after render, or whenever props.anim changes (the anim file passed to this component)
	useEffect(()=>{
		
		// Store all refs
		$canvas = canvasRef.current;
		$matchViewer = matchViewerRef.current;
		$slider = sliderRef.current;
		$handle = sliderHandleRef.current;
		$fill = sliderFillRef.current;
		$currentEvent = currentEventRef.current;
		$currentAm = currentAmRef.current;
		$homePoints = homePointsRef.current;
		$guestPoints = guestPointsRef.current;
		$specialTime = specialTimeRef.current;
		$clock = clockRef.current;
		$actualTime = actualTimeRef.current;
		$sliderMap = sliderMapRef.current;
		
		if(props.anim !== anim){
			
			if(anim){
				if(interval){
					clearInterval(interval);
					interval = null;
				}
				reset();
			}
			
			anim = props.anim;
			
			resetGlobals();
		}
		
		if(!anim) return;
		
		// Draw field only
		updateCanvas();
		draw();
		
		// Load anim file
		initAnim();
		play();
		
		// Update on resize
		window.onresize = null;
		window.onresize = ()=>{
			updateCanvas();
			draw();
			updateSlider();
			updateSliderMap();
		};
		
		return () => {
			window.onresize = null;
		};
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[props.anim]);
	
	const initAnim = () => {
		console.log('initAnim');
		
		// Detect file format
		let frames = keyFrames = (anim.frames || anim.keyFrames);
		let initialisedPlayers = false;
		
		for( let ms in frames ){
			if(!initialisedPlayers){
				// First frame
				initPlayers(frames[ms]);
				initialisedPlayers = true;
			}

			if (parseInt(ms) > highestFrame){
				highestFrame = parseInt(ms);
			}
			
			finalFrameMs = ms;
		}
		
		initControls();
	};
	
	const update = sliderIsControlling => {
		if(!sliderIsControlling) updateTime();
		updatePlayers();
		draw();
		updateUI(sliderIsControlling);
	};
	
	//-------------------------------------------------------------------------- Time
	
	const updateTime = () => {
		if(!startMs){
			startMs = new Date().getTime();
			currentMs = 0;
			lastMs = startMs;
		} else {
			let newMs = new Date().getTime();
			currentMs += (newMs - lastMs);
			lastMs = newMs;
		}
		if(currentMs > finalFrameMs){
			pause();
		} else if(cookies.skipMissing&&eventHidesPlayers()){
			let {nextAnimMs} = getEventAtTime(currentMs,true);
			let addMs = (nextAnimMs||finalFrameMs) - currentMs + 1;
			currentMs += addMs;
			startMs += addMs;
			lastMs = currentMs;
			pause();
			if(nextAnimMs) play();
		}
	};
	
	//-------------------------------------------------------------------------- Play Control
	
	const play = () => {
		
		// Auto-reset if we've finished
		if(currentMs>0 && currentMs >= finalFrameMs) reset(true);
		
		isPlaying = true;
		if(startMs) lastMs = new Date().getTime();
		forceUpdate();
		
		// Run first frame
		update();
		
		// Set frame runner
		if(interval){
			clearInterval(interval);
			interval = null;
		}
		interval = setInterval(update,1000/targetFps);
	};
	
	const pause = () => {
		if(interval){
			clearInterval(interval);
			interval = null;
		}
		isPlaying = false;
		forceUpdate();
	};
	
	const reset = (e,skipUpdate=false) => {
		pause();
		startMs = null;
		currentMs = 0;
		if(!skipUpdate) update();
	};
	
	const handleResetButton = () => {
		if(!anim) return;
		reset();
	};
	
	const togglePlayPause = () => {
		if(!anim) return;
		if(isPlaying){
			pause();
		} else {
			play();
		}
	};
	
	const toggleSkipMissing = e => {
		setCookie('skipMissing',e.target.checked?'checked':'',{ path: '/' });
	};
	
	//-------------------------------------------------------------------------- Controls UI
	
	const initControls = () => {
		
		updateSlider();
		initKeyboard();
		
	};
	
	const initKeyboard = () => {
		document.body.onkeydown = null;
		document.body.onkeydown = (e)=>{
			if(e.keyCode === 32){
				togglePlayPause();
			}
			if(e.keyCode === 8){
				reset();
			}
		};
		// Prevent keyboard from clicking buttons
		document.body.onkeyup = null;
		document.body.onkeyup = (e)=>{
			if(e.keyCode === 32 || e.keyCode === 8){
				e.preventDefault();
			}
		};
	};
	
	//-------------------------------------------------------------------------- Controls: Slider
	
	const updateSlider = () => {
		
		let isDragging = false;
		let mouseStartX,handleStartX;
		let $body = document.body;
		let handleWidth = $handle.offsetWidth;
		let minX = 0;
		let maxX = $slider.offsetWidth - handleWidth;
		let sliderLeftOffset = $slider.getBoundingClientRect().left;
		
		let updateHandlePosition = (handleX)=>{
			$handle.style.left = `${handleX}px`;
			$fill.style.width = `${handleX + handleWidth}px`;
			let progress = handleX / maxX;
			onSliderValueChange(progress);
		};
		
		$handle.onmousedown = null;
		$handle.onmousedown = e => {
			if(!isDragging){
				isDragging = true;
				mouseStartX = e.pageX;
				handleStartX = $handle.getBoundingClientRect().left;
				// Pause play if playing
				pause();
			}
		};
		$slider.onmousedown = null;
		$slider.onmousedown = e => {
			if(e.target === $slider || e.target === $fill){
				if(!isDragging){
					isDragging = true;
					mouseStartX = e.pageX;
					// Force move handle under mouse
					let handleX = Math.max(Math.min(mouseStartX - handleWidth*0.5 - sliderLeftOffset,maxX),minX);
					updateHandlePosition(handleX);
					
					handleStartX = mouseStartX - handleWidth*0.5;
					
					// Pause play if playing
					pause();
				}
			}
		};
		$body.onmousemove = null;
		$body.onmousemove = e =>{
			if(isDragging){
				let offsetX = e.pageX - mouseStartX;
				let handleX = Math.max(Math.min(handleStartX + offsetX - sliderLeftOffset,maxX),minX);
				updateHandlePosition(handleX);
			}
		};
		$body.onmouseup = null;
		$body.onmouseup = () => {
			if(isDragging){
				isDragging = false;
			}
		};
		
		updateSliderMap();
		updateSliderWhilePlaying();
		
	};
	
	const onSliderValueChange = (progress) => {
		
		// Pause if not paused
		if(isPlaying) pause();
		
		// Calculate ms based on slider progress (which is 0-1)
		currentMs = Math.round(progress * finalFrameMs);
		
		// Draw
		update(true);
		
	};
	
	const updateSliderMap = () => {
		let events = anim.events;
		let blocks = [];
		let startMs = null;
		let _isBreak = null;
		let lastBlockMs = 0;
		let maxMs = finalFrameMs;
		let handleWidth = $handle.offsetWidth;
		let prevWasBreak = false;
		let kickingComp = false;
		
		for( let ms in events ){
			let event = events[ms];
			
			// Detect situation where non-anim event immediately precedes break
			let forceSplit = startMs && isBreak(event) && !prevWasBreak;
			
			if(eventHidesPlayers(event) && !forceSplit){
				if(!startMs){
					startMs = ms;
					_isBreak = isBreak(event);
				}
			} else if(startMs||forceSplit){
				blocks.push({
					startMs,
					endMs:ms,
					isBreak: _isBreak,
				});
				startMs = forceSplit ? ms : null;
				_isBreak = forceSplit ? isBreak(event) : null;
				lastBlockMs = ms;
			}
			prevWasBreak = isBreak(event);
			
			if(event.name === 'kicking competition'){
				kickingComp = true;
			}
		}
		
		if(startMs){
			blocks.push({
				startMs,
				endMs:maxMs,
				isKickingComp: kickingComp,
			});
			startMs = null;
			_isBreak = null;
		} else if(kickingComp){
			blocks.push({
				startMs:lastBlockMs,
				endMs:maxMs,
				isKickingComp: kickingComp,
			});
		}
		
		// Prep
		let sliderWidth = $slider.offsetWidth - handleWidth;
		
		// Create blocks
		let lastX = 0;
		$sliderMap.innerHTML = '';
		
		for( let block of blocks ){
			
			// Add block
			let startX = Math.round(block.startMs/maxMs*sliderWidth);
			let width = Math.round(block.endMs/maxMs*sliderWidth) - startX;
			let gap = startX - lastX;
			
			$sliderMap.innerHTML += `<div style="flex-basis: ${width}px; margin-left: ${gap}px" class="slider-map-block${block.isBreak?` break`:``}${block.isKickingComp?` kicking-comp`:``}"></div>`;
			lastX = startX + width;
		}
	};
	
	const updateSliderWhilePlaying = () => {
		let progress = Math.max(0,Math.min(1,currentMs / finalFrameMs));
		let handleWidth = $handle.offsetWidth;
		let maxX = $slider.offsetWidth - handleWidth;
		let handleX = progress * maxX;
		$handle.style.left = `${handleX}px`;
		$fill.style.width = `${handleX + handleWidth}px`;
	};
	
	//-------------------------------------------------------------------------- General UI
	
	const updateUI = (sliderIsControlling=false) => {
		
		let {eventName,attackMove} = getEventAtTime(currentMs);
		
		$currentEvent.innerHTML = eventName;
		$currentAm.innerHTML = attackMove;
		$homePoints.innerHTML = getHomePointsAtTime(currentMs);
		$guestPoints.innerHTML = getGuestPointsAtTime(currentMs);
		
		// Update slider if playing (i.e. not using the slider)
		if(!sliderIsControlling) updateSliderWhilePlaying();
		updateClock();
	};
	
	const updateClock = () => {
		let clock = getClockAtTime(currentMs);
		let actualClock = convertToClock(currentMs/1000);
		let {h} = getEventAtTime(currentMs);
		
		if(h===5){
			$specialTime.innerHTML = 'Kicking Competition';
			$clock.style.display = 'none';
			$specialTime.style.display = 'inline-block';
		} else {
			$clock.style.display = 'inline-block';
			if(h===3||h===4){
				$specialTime.style.display = 'inline-block';
				$specialTime.innerHTML = 'Extra-time';
			} else {
				$specialTime.style.display = 'none';
			}
			
			if(clock !== cachedClock){
				$clock.innerHTML = clock;
				cachedClock = clock;
			}
		}
		
		if(actualClock !== cachedActualClock){
			$actualTime.innerHTML = actualClock;
			cachedActualClock = actualClock;
		}
	};
	
	//-------------------------------------------------------------------------- Time
	
	const getClockAtTime = (time) => {
		// time should be an int representing milliseconds
		// assuming the keys in events will be sorted in ascending order
		let events = anim.clockSeconds;
		let prevSeconds=0, nextSeconds, prevMs=0, nextMs;
		for (let ms in events){	
			nextMs = ms;
			nextSeconds = events[ms];
			if (time<=parseInt(ms)){
				break;
			}
			prevMs = ms;
			prevSeconds = events[ms];
		}
		
		let progress = prevMs === nextMs ? 1 : (time - prevMs) / (nextMs - prevMs);
		let seconds = prevSeconds + (nextSeconds - prevSeconds)*progress;
		//console.log('seconds',seconds,'ms event',nextMs,'progress',progress,'prevSeconds',prevSeconds,'nextSeconds',nextSeconds);
		
		// Convert to fakey fake time
		seconds *= 10;
		
		return convertToClock(seconds);
	};
	
	const convertToClock = (seconds) => {
		let mins = Math.floor(seconds/60);
		let secs = Math.floor(seconds%60);
		
		return `${mins<10?'0':''}${mins}:${secs<10?'0':''}${secs}`;
	};
	
	//-------------------------------------------------------------------------- Canvas
	
	const updateCanvas = () => {
		
		ctx = $canvas.getContext('2d');
		let pixelRatio = window.devicePixelRatio;
		
		let footerHeight = document.getElementById('rugby-2d-footer').offsetHeight;
		$canvas.width = $matchViewer.offsetWidth * pixelRatio;
		$canvas.height = ($matchViewer.offsetHeight - footerHeight)*pixelRatio;
		
		setCanvasStyle({
			width: `${$matchViewer.offsetWidth}px`,
			height: `${$matchViewer.offsetHeight - footerHeight}px`,
		});
		
		// Invalidate caches
		hasResized = true;
		
	};
	
	//-------------------------------------------------------------------------- Drawing

	const draw = () => {
		//console.log('draw',isPlaying ? `${currentMs}ms` : `field only`);
		
		ctx.fillStyle = bgColor;
		ctx.fillRect(0,0,$canvas.width,$canvas.height);
		
		// ctx.strokeStyle = 'red';
		// ctx.lineWidth = 7;
		// ctx.strokeRect(0,0,$canvas.width,$canvas.height);
		
		let drawOnTopOfField = ()=>{
			drawOrigin();
			drawNearby();
			drawMeta();
			drawPlayers();
			drawOverlay();
		};
		
		// Draw stuff
		drawField(drawOnTopOfField);
		drawOnTopOfField();
		
		hasResized = false;
		
	};
	
	//-------------------------------------------------------------------------- Drawing: Field
	
	const drawField = drawOnTopOfField => {
		let padding = 40;
		let paddingExtraTop = 100;
		let x=padding,y=padding+paddingExtraTop,w,h;
		
		// If not cached
		if(hasResized){
			
			let vh = $canvas.height - padding*2 - paddingExtraTop;
			let vw = $canvas.width - padding*2;
			let ratio = vw/vh;
			
			
			if(ratio < fieldRatio){
				
				// Limit on width
				w = vw;
				h = w/fieldRatio;
				y = padding + paddingExtraTop + (vh - h)*0.5;
				
			} else {
				
				// Limit on height
				h = vh;
				w = h*fieldRatio;
				x = padding + (vw - w)*0.5;
				
			}
			
			paintWidth = 0.3/110*h;
			fieldPos = [x,y,w,h];
			fieldObj = {
				x: fieldPos[0],
				y: fieldPos[1],
				w: fieldPos[2],
				h: fieldPos[3] - paintWidth*2,
			};
			
		}
		
		// ctx.strokeStyle = 'black';
		// ctx.lineWidth = 4;
		// ctx.strokeRect(x,y,fieldWidth,fieldHeight);
		
		let imgObj = fieldImageObj;
		
		// Has image already been loaded?
		if(imgObj){
			ctx.drawImage(imgObj, ...fieldPos);
		} else {
			imgObj = new Image();
			imgObj.onload = ()=>{
				ctx.drawImage(imgObj, ...fieldPos);
				drawOnTopOfField();
				fieldImageObj = imgObj;
			};
			imgObj.src = '/field.jpg';
		}
		
	};
	
	//-------------------------------------------------------------------------- Drawing: Players
	
	/**
	 * Perform any player maintenance
	 * (Runs before drawing)
	 */
	const updatePlayers = () => {
		if(!players || !anim) return;
		
		players.forEach((player, i, arr)=>{
			arr[i] = getPlayerStateAtTime(currentMs, player);
		});
		
	};
	
	const getPlayerStateAtTime = (time, player) => {

		// get the 2 keyframes that surround the given time value the player is invovled in.
		let frames = anim.frames || anim.keyFrames;

		let playerState = {
			'team': player.team,
			'jersey': player.jersey,
			'involvedFrames': player.involvedFrames,
			'x': -1,
			'y': -1,
			'r': -1,
			'height': -1,
			'hasBall': false
		};

		let prevFrameMs = player.involvedFrames[0]; let nextFrameMs = player.involvedFrames[1];

		for (let i=0; i < player.involvedFrames.length; i++){
			if (currentMs > player.involvedFrames[i]){
				prevFrameMs = player.involvedFrames[i];
				nextFrameMs = prevFrameMs;
			}
			else{
				nextFrameMs = player.involvedFrames[i];
				break;
			}
		}

		let playerAtPrevFrame = frames[String(prevFrameMs)]["ps"][player.team][player.jersey];
		let playerAtNextFrame = frames[String(nextFrameMs)]["ps"][player.team][player.jersey];
		let t = (currentMs - prevFrameMs) / (Math.max(1, nextFrameMs - prevFrameMs));
		playerState.x = lerp(playerAtPrevFrame[0], playerAtNextFrame[0], t);
		playerState.y = lerp(playerAtPrevFrame[1], playerAtNextFrame[1], t);
		if (player.team === 'ball'){
			playerState.height = lerp(playerAtPrevFrame[3], playerAtNextFrame[3], t);
		}
		// for the angle, find the shortest direction between the 2 angles (+ve or -ve), instead of just lerping between them.
		let angle1 = playerAtPrevFrame[2];
		let angle2 = playerAtNextFrame[2];
		if (Math.abs(angle1 - angle2) > 180){
			if (angle1 > angle2) angle1 -= 360;
			else angle1 += 360;
		}
		playerState.r = lerp(angle1, angle2, t);
		playerState.hasBall = playerAtPrevFrame.hasBall;

		return playerState;
	};
	
	const drawPlayers = () => {
		if(!players) return;
		
		setGlobalAlphaForHidingPlayers();
		
		// Don't bother if players are completely hidden
		if(ctx.globalAlpha > 0){
		
			let field = fieldObj;
			for( let player of players ){
				drawPlayer(player,ctx,field);
			}
			
		}
		
		resetGlobalAlpha(ctx);
	};
	
	const drawPlayer = (player) => {
		if(player.team === 'ball'){
			drawRugbyBall(player);
		} else {
			drawRugbyPlayer(player);
		}
		
	};
	
	const drawRugbyPlayer = (player,isGhost=false) => {
		
		let playerRadiusInMetres = 0.83;
		//console.log('fieldw: ' + fieldObj.w);
		let radius = Math.round(fieldObj.w * (playerRadiusInMetres/100));
		let fontSize = Math.round(radius*1.2);
		let fontOffset = fontSize*0.375;
		let {x,y,jersey,r} = player;
		({x,y} = normaliseFieldPos(x,y,fieldObj));
		
		// Draw a circle
		ctx.beginPath();
		ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
		ctx.fillStyle = ['ateam','h'].includes(player.team) ? '#314994' : '#a22136';
		if(isGhost) ctx.fillStyle += Math.round(ghostOpacity*255).toString(16);
		ctx.fill();
		
		// Draw triangle to show rotation
		drawTriangleArrow(ctx,x,y,r,player.team,radius*0.59,radius*0.77,isGhost);
		
		// Draw jersey
		ctx.font = `${fontSize}px TradeGothicLTPro-Bold`;
		ctx.fillStyle = '#ffffff';
		if(isGhost) ctx.fillStyle += Math.round(ghostOpacity * 255).toString(16);
		ctx.textAlign="center"; 
		ctx.fillText(jersey,x,y+fontOffset);
		
	};
	
	//-------------------------------------------------------------------------- Drawing: Ball
	
	const drawRugbyBall = (ball) => {
		
		if(ball.x===undefined){
			console.warn('Tried to draw invalid ball',ball);
			return;
		}
		
		let minHeight=0, maxHeight=30;
		let groundSize=0.08, highSize=0.15;
		//console.log('ball height: ' + ball.height);
		let t = (ball.height-minHeight) / (maxHeight-minHeight);
		let size = lerp(groundSize, highSize, t);
		let w = 200*size, h = 282*size;
		let rotation = 30;
		let {x,y} = ball;
		({x,y} = normaliseFieldPos(x,y,fieldObj));
		let imgObj = ballImageObj;
		
		// Has image already been loaded?
		if(imgObj){
			drawRotatedImage(imgObj,x,y,w,h,rotation);
		} else {
			imgObj = new Image();
			imgObj.onload = ()=>{
				drawRotatedImage(imgObj,x,y,w,h,rotation);
				ballImageObj = imgObj;
			};
			imgObj.src = '/ball.png';
		}
	};
	
	//-------------------------------------------------------------------------- Drawing: Overlay
	
	const drawOverlay = () => {
		
		setGlobalAlphaForHidingPlayers(true);
		let ftbProgress = getFadeToBlackProgress();
		
		if(ftbProgress>0){
			ctx.fillStyle = `rgb(49,54,65,${ftbProgress})`;
			ctx.fillRect(fieldObj.x,fieldObj.y - fieldObj.h*0.025,fieldObj.w,fieldObj.h*1.05);
		}
		
		if(isComingSoon()){
			
			//ctx.fillStyle = 'rgb(30,33,39,0.55)';
			ctx.fillStyle = 'rgb(118,27,44,0.55)'; // Red
			ctx.fillRect(fieldObj.x,fieldObj.y,fieldObj.w,fieldObj.h*1.007);
			drawMainFieldMessage(ctx,'Animation not available');
			drawCountdownUntilNextAnim(ctx);
			
		} else if(isBreak()){
			
			let message = 'Half time';
			
			let event = getEventAtTime(currentMs);
			
			if(event && event.eventName==='extraTimeBreak1'){
				message = 'Extra-time starting soon!';
			} else if(event && event.eventName==='extraTimeBreak2'){
				message = 'Extra-time half-time';
			} else if(event && event.eventName==='extraTimeBreak3'){
				message = 'Kicking competition starting soon!';
			}
			
			ctx.fillStyle = '#314994' + (128).toString(16);
			ctx.fillRect(fieldObj.x,fieldObj.y,fieldObj.w,fieldObj.h*1.007);
			drawMainFieldMessage(ctx,message);
			drawCountdownUntilNextAnim(ctx);
			
		}
		
		resetGlobalAlpha(ctx);
	};
	
	const setGlobalAlphaForHidingPlayers = (reverse=false) => {
		
		// Get event and last event
		let event = getEventAtTime(currentMs);
		let {eventMs,prevEvent} = event;
		let thisEventHidesPlayers = eventHidesPlayers(event);
		let fadedPlayersAlpha = 0.3;
		
		// Would there be a fade happening?
		let msProgress = currentMs - eventMs;
		if(msProgress < playerFadeMs){
			let progress = msProgress/playerFadeMs;
			
			// Do we need to fade?
			let previousEventHidesPlayers = eventHidesPlayers(prevEvent);
			
			if(previousEventHidesPlayers && !thisEventHidesPlayers){
				
				// Fading in
				if(reverse) progress = 1-progress;
				ctx.globalAlpha = fadedPlayersAlpha + progress*(1-fadedPlayersAlpha);
				
			} else if(!previousEventHidesPlayers && thisEventHidesPlayers){
				
				// Fading out
				
				if(!reverse) progress = 1-progress;
				ctx.globalAlpha = fadedPlayersAlpha + progress*(1-fadedPlayersAlpha);
				//ctx.globalAlpha = reverse ? 1 : fadedPlayersAlpha;
				
			} else if(thisEventHidesPlayers){
				ctx.globalAlpha = reverse ? 1 : fadedPlayersAlpha;
			}
			
		} else if(thisEventHidesPlayers){
			ctx.globalAlpha = reverse ? 1 : fadedPlayersAlpha;
		}
		
	};
	
	const getFadeToBlackProgress = () => {
		
		let {eventName,isFtb,eventMs} = getEventAtTime(currentMs);
		if(!eventName.length) return 0;
		//console.log('eventName',eventName,'prevEvent',prevEvent,'nextEvent',nextEvent,'prev',prev,'now',now,'next',next);
		
		let fadeOutMs = 1000;
		let fullBlackMs = 200; // Proportion of time in both the fade out and fade in, to force the fade to sit and full black
		let fadeInMs = 400; // Proportion of the full fadeMs to use when fading in (it feels nicer to have this quick than the fade out)
		let fadeProgress = 0;
		
		if(isFtb){
			
			let msIntoEvent = currentMs - eventMs;
			let peakTime = Number(eventMs) + fadeOutMs;
			let totalFadeMs = fadeOutMs + fadeInMs;
			
			if(msIntoEvent <= fadeOutMs){
				
				// Fade out
				
				let msUntilFadeOutComplete = peakTime - currentMs;
				fadeProgress = Math.min(1,1 - (msUntilFadeOutComplete - fullBlackMs)/(fadeOutMs - fullBlackMs));
				
				// Apply ease
				fadeProgress = EasingFunctions.easeOutQuart(fadeProgress);
				
			} else if(msIntoEvent <= totalFadeMs){
				
				// Fade in
				let msIntoFadeIn = currentMs - peakTime;
				
				fadeProgress = 1 - msIntoFadeIn/fadeInMs; 
				
			}
			
		}
		
		//if(fadeProgress!==0) console.log('fadeProgress',fadeProgress);
		return fadeProgress;
	};
	
	//-------------------------------------------------------------------------- Drawing: Misc
	
	const drawOrigin = () => {
		
		let radius = Math.round(fieldObj.w * (0.7/100));
		let x=0,y=0;
		({x,y} = normaliseFieldPos(x,y,fieldObj));
		
		// Draw a circle
		ctx.beginPath();
		ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
		ctx.fillStyle = 'yellow';
		ctx.fill();
	};
	
	const drawNearby = () => {
		if(!anim) return;
		
		if(anim.latestNearby){
			let nearby = anim.latestNearby;
			let field = fieldObj;
			let {x,y} = normaliseFieldPos(nearby[0],nearby[1],field);
			let radius = normaliseFieldDist(nearby[2],field);
			// Draw a circle
			ctx.beginPath();
			ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
			ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
			ctx.fill();
		}
		
	};
	
	const drawMeta = () => {
		if(anim && anim.meta){
			let meta = anim.meta;
			
			// Lines
			if(meta.lines){
				for( let line of meta.lines ){
					drawLine(ctx,...line);
				}
			}
			
			// Dots
			if(meta.dots){
				for( let dot of meta.dots ){
					drawDot(ctx,...dot);
				}
			}
			
			// Ghost players
			if(meta.ghostPlayers){
				for( let ghost of meta.ghostPlayers ){
					drawRugbyPlayer(unpackPlayer(ghost),true);
				}
			}
			
		}
	};
	
	const drawLine = (ctx,x1,y1,x2,y2) => {
		({x:x1,y:y1} = normaliseFieldPos(x1,y1));
		({x:x2,y:y2} = normaliseFieldPos(x2,y2));
		ctx.beginPath();
		ctx.moveTo(x1,y1);
		ctx.lineTo(x2,y2);
		ctx.lineWidth = 3;
		ctx.strokeStyle = '#ffff00';
		ctx.stroke();
	};
	
	const drawDot = (ctx,x,y) => {
		({x,y} = normaliseFieldPos(x,y));
		let radius = 7;
		ctx.beginPath();
		ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
		ctx.fillStyle = '#ffff00';
		ctx.fill();
	};
	
	const drawTriangleArrow = (ctx,x,y,r,team,size,distance,isGhost) => {
		
		ctx.save();
		ctx.translate(x,y); // Move context to position of player
		ctx.rotate(r * Math.PI / 180); // Rotate context
		ctx.translate(distance,0); // Now move away from center of player to where triangle will be (on a radius)
		ctx.beginPath(); // Draw the thing
		ctx.moveTo(size,0);
		ctx.lineTo(0,size);
		ctx.lineTo(0,-size);
		ctx.fillStyle = ['ateam','h'].includes(team) ? '#73a7ff' : '#ff788e';
		if(isGhost) ctx.fillStyle += Math.round(ghostOpacity*255).toString(16);
		ctx.fill();
		ctx.restore(); // Restore
		
	};
	
	const drawRotatedImage = (imgObj,x,y,w,h,r) => {
		ctx.save(); // Save context
		ctx.translate(x,y); // Move context to center of rotation point (where center of image will be)
		ctx.rotate(r*Math.PI/180); // Rotate context while here
		ctx.translate(-w*0.5,-h*0.5); // Move context to image draw starting point (top left of image)
		ctx.drawImage(imgObj,0,0,w,h); // Draw the image
		ctx.restore(); // Restore context
	};
	
	const drawCountdownUntilNextAnim = (ctx) => {
		let {nextAnimMs} = getEventAtTime(currentMs,true);
		if(nextAnimMs){
			let countdownMs = nextAnimMs - currentMs;
			let seconds = Math.floor(countdownMs/1000);
			let ms = countdownMs % 1000;
			ms = Math.floor(ms/100);
			drawSecondaryFieldMessage(ctx,`${seconds}.${ms}s until next animation`);
		}
	};
	
	const drawMainFieldMessage = (ctx,text) => {
		
		// Draw text
		let fontSize = 50;
		let fontOffset = fontSize*0.25;
		let x = fieldObj.x + fieldObj.w*0.5;
		let y = fieldObj.y + fieldObj.h*0.5;
		ctx.font = `${fontSize}px TradeGothicLTPro-Bold`;
		ctx.fillStyle = '#ffffff';
		//if(isGhost) ctx.fillStyle += Math.round(ghostOpacity * 255).toString(16);
		ctx.textAlign="center"; 
		ctx.fillText(text,x,y+fontOffset);
		
	};
	
	const drawSecondaryFieldMessage = (ctx,text) => {
		
		// Draw text
		let fontSize = 30;
		let fontOffset = fontSize*0.25;
		let x = fieldObj.x + fieldObj.w*0.5;
		let y = fieldObj.y + fieldObj.h*0.75;
		ctx.font = `${fontSize}px TradeGothicLTPro-Bold`;
		ctx.fillStyle = '#ffffff';
		//if(isGhost) ctx.fillStyle += Math.round(ghostOpacity * 255).toString(16);
		ctx.textAlign="center"; 
		ctx.fillText(text,x,y+fontOffset);
		
	};
	
	const resetGlobalAlpha = () => {
		ctx.globalAlpha = 1.0;
	};
	
	//-------------------------------------------------------------------------- Players
	
	const initPlayers = () => {

		//console.log('initPlayers');
		
		for( let team of ['h','g','ball'] ){
			let jerseys = team === 'ball' ? ['1'] : ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15'];
			//let jerseys = team === 'ball' ? [1] : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
			for( let jersey of jerseys ){
				let firstPositionMs = getInvolvedFrames(team, jersey, true);
				if(firstPositionMs.length === 0){
					throw new Error(`Could not find player position in any frames`);
				}
				players.push(unpackPlayer(keyFrames[firstPositionMs]["ps"][team][jersey],team,jersey));
			}
		}
		
		// Implement if we need to lookup players easily
		//updatePlayerIndex();
	};
	
	const unpackPlayer = (position,team,jersey) => {
		let player ={
			x:position[0],
			y:position[1],
			r:position[2],
			jersey,
			team,
			involvedFrames: getInvolvedFrames(team, jersey)
		};
		if (team === 'ball'){
			player.height = position[3];
		}
		return player;
	};
	
	//-------------------------------------------------------------------------- Anim Processing
	
	/**
	 * returns an array of ints representing each frame the given player is involved in.
	 * @param {*} team the player's team
	 * @param {*} jersey the player's jersey
	 */
	const getInvolvedFrames = (team, jersey, firstOnly=false) => {
		let arr = [];
		
		// makes the assumption that the keys in frames are ordered in ascending order. 
		// If this doesn't hold true then just sort arr and everything else will still work.
		
		for (let key in keyFrames){		// key is the ms value of the frame
			if (keyFrames[key]["ps"][team] && keyFrames[key]["ps"][team][jersey]){
				arr.push(parseInt(key));
				if(firstOnly) break;
			}
		}
		
		return arr;
	};
	
	//-------------------------------------------------------------------------- Events
	
	const getEventAtTime = (time,continueUntilNextAnimEvent=false) => {
		
		let cacheKey = `t${time}`;
		let canCache = !continueUntilNextAnimEvent;
		
		if(eventsCache && cacheKey in eventsCache && canCache){
			return eventsCache[cacheKey].data;
		}
		
		if(!anim||time<0||(!time&&time!==0))
			return {eventName:'',attackMove:''};
		
		// time should be an int representing milliseconds
		// assuming the keys in events will be sorted in ascending order
		let events = anim.events;
		
		let event, eventMs, prevMs, nextAnimMs, nextEvent, nextEventMs;
		for (var ms in events){	
			if (time<parseInt(ms)){
				if(continueUntilNextAnimEvent){
					if(!eventHidesPlayers(events[ms])){
						nextAnimMs = ms;
						break;
					}
				} else {
					nextEvent = events[ms];
					nextEventMs = ms;
					break;
				}
			}
			prevMs = eventMs;
			eventMs = ms;
		}
		
		let prevEvent = events[prevMs];
		event = events[eventMs];
		if(!event) event = prevEvent;
		
		let eventName = event.name;
		if(!eventName) console.log('event',event);
		let attackMove = event.attackMove || 'n/a';
		let h = event.h || 'n/a';
		let isFtb = event.isFtb;
		let isComingSoon = event.isComingSoon;
		
		let data = {eventName,h,attackMove,eventMs,prevEvent,nextEvent,nextEventMs,nextAnimMs,isFtb,isComingSoon};
		
		// Caching
		if(canCache){
			eventsCache[cacheKey] = JSON.parse(JSON.stringify({
				time,
				data,
			}));
		}
		
		return data;
	};
	
	const eventHidesPlayers = (event) => {
		event = event || getEventAtTime(currentMs);
		return (event && event.isComingSoon) || isBreak(event);
	};
	
	const isBreak = event => {
		let eventName = (event && event.name) || getEventAtTime(currentMs).eventName;
		return eventName === 'halfTime' || eventName.indexOf('extraTimeBreak')===0;
	};
	
	const isComingSoon = () => {
		return getEventAtTime(currentMs).isComingSoon;
	};
	
	//-------------------------------------------------------------------------- Misc Helpers
	
	/**
	 * Linearly interpolate between a and b by t.
	 * @param {*} a number to lerp from
	 * @param {*} b number to lerp to
	 * @param {*} t a normalised float with a value between 0 and 1
	 */
	const lerp = (a, b, t) => {
		return (a * (1.0 - t)) + (b * t);
	};
	
	const getHomePointsAtTime = time => {
		return getTeamPointsAtTime(time, 'h');
	};
	
	const getGuestPointsAtTime = time => {
		return getTeamPointsAtTime(time, 'g');
	};
	
	const getTeamPointsAtTime = (time, team) => {
		// time should be an int representing milliseconds
		// assuming the keys in scores will be sorted in ascending order
		let scores = anim.scores;
		let teamScore, prevMs;
		for (let ms in scores){
			if (time<parseInt(ms)){
				break;
			}
			prevMs = ms;
		}
		teamScore = scores[prevMs][team];

		return teamScore;
	};
	
	const normaliseFieldPos = (posX,posY) => {
		// Field image size = 120m x 70m (10m ingoals)
		let x = fieldObj.x + posY/120*fieldObj.w + 10/120*fieldObj.w;
		let y = fieldObj.y + posX/70*fieldObj.h + paintWidth;
		return {x,y};
	};
	
	const normaliseFieldDist = (dist,field) => {
		return dist/120*field.w;
	};
	
	//-------------------------------------------------------------------------- HTML
	
	return (
		<div id="match-viewer" ref={matchViewerRef}>
			<canvas id="gameCanvas" ref={canvasRef} style={canvasStyle}></canvas>
			<div id="rugby-2d-header" className="noselect fb-c fb-c-hcenter fb-c-stretch">
				<div style={{flexBasis: '50%'}} className="fb-i-grow"></div>
				<div>
					<div className="fb-c scoreboard-container">
						<div><div id="home-name" className="scoreboard">{props.homeClubName || 'Home'}</div></div>
						<div><div id="home-points" className="scoreboard" ref={homePointsRef}>0</div></div>
						<div><div id="guest-points" className="scoreboard" ref={guestPointsRef}>0</div></div>
						<div><div id="guest-name" className="scoreboard">{props.guestClubName || 'Guest'}</div></div>
					</div>
					<div className="fb-c fb-c-hcenter">
						<div><span id="special-time" style={{display: 'none'}} ref={specialTimeRef}></span> <span id="clock" ref={clockRef}>00:00</span></div>
					</div>
				</div>
				<div style={{flexBasis: '50%'}} className="fb-i-grow">
					<div className="fb-c fb-c-vcenter fb-c-hright full-height">
						<div className="sub-text fb-i text-div">Actual time: <span id="actualClock" ref={actualTimeRef}>00:00</span></div>
					</div>
				</div>
			</div>
			<div id="rugby-2d-footer" className="noselect">
				<div id="slider-wrapper">
					<div id="slider-map" className="fb-c" ref={sliderMapRef}></div>
					<div id="slider" ref={sliderRef}>
						<div id="slider-fill" ref={sliderFillRef}></div>
						<div id="slider-handle" ref={sliderHandleRef}></div>
					</div>
				</div>
				<div id="play-controls" className="fb-c">
					<div className="outside-blocks blue-text left-text">
						<div className="text-div">
							Current Event: <span id="current-event" className="white-text" ref={currentEventRef}></span>
							<br/>
							Attack Move: <span id="current-am" ref={currentAmRef} className="white-text"></span>
						</div>
					</div>
					<div className="middle-block">
						<button id="play-button" className="rugby-2d rugby-2d-button" onClick={togglePlayPause}><span className={isPlaying?'icon-pause':'icon-play'}></span></button>
						<button id="reset-button" className="rugby-2d rugby-2d-button" onClick={handleResetButton}><span className="icon-to-start"></span></button>
					</div>
					<div className="outside-blocks">
						<div className="text-div sub-text">
							<div className="fb-c fb-c-vcenter">
								<div style={{flex: '1 1 auto'}}>
									<div className="fb-c fb-c-hright" style={{flexWrap: 'wrap'}}>
										<div style={{padding:'0 15px 0 0', marginBottom: '10px', borderRight: '1px solid #6B7493'}}>
											<div className="fb-c">
												<div style={{padding: '0 10px 0 0px'}}>
													<input type="checkbox" id="skipMissing" style={{display:'none'}} checked={cookies.skipMissing} onChange={toggleSkipMissing}/>
													<label htmlFor="skipMissing" className="toggle"><span></span></label> 
												</div>
												<div>
													<span className="white-text">Skip missing animations</span>
												</div>
											</div>
										</div>
										<div style={{padding:'0 0 0 15px'}}>
											<span className="team-legend origin"></span> Origin <span className="text-spacer"></span> <span className="team-legend home"></span> Home <span className="text-spacer"></span> <span className="team-legend guest"></span> Guest <span className="text-spacer"></span>
										</div>
									</div>
								</div>
								<div style={{flex: '0 0', marginLeft: '15px'}}>
									<img id="compass" src="/compass.png" alt="compass" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Rugby2d;

/*
 * Easing Functions - inspired from http://gizma.com/easing/
 * only considering the t value for the range [0, 1] => [0, 1]
 */
const EasingFunctions = {
  // no easing, no acceleration
  linear: function (t) { return t; },
  // accelerating from zero velocity
  easeInQuad: function (t) { return t*t; },
  // decelerating to zero velocity
  easeOutQuad: function (t) { return t*(2-t); },
  // acceleration until halfway, then deceleration
  easeInOutQuad: function (t) { return t<.5 ? 2*t*t : -1+(4-2*t)*t; },
  // accelerating from zero velocity 
  easeInCubic: function (t) { return t*t*t; },
  // decelerating to zero velocity 
  easeOutCubic: function (t) { return (--t)*t*t+1; },
  // acceleration until halfway, then deceleration 
  easeInOutCubic: function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1; },
  // accelerating from zero velocity 
  easeInQuart: function (t) { return t*t*t*t; },
  // decelerating to zero velocity 
  easeOutQuart: function (t) { return 1-(--t)*t*t*t; },
  // acceleration until halfway, then deceleration
  easeInOutQuart: function (t) { return t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t; },
  // accelerating from zero velocity
  easeInQuint: function (t) { return t*t*t*t*t; },
  // decelerating to zero velocity
  easeOutQuint: function (t) { return 1+(--t)*t*t*t*t; },
  // acceleration until halfway, then deceleration 
  easeInOutQuint: function (t) { return t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t; }
};

import React from 'react';
import UsersLib from '../../lib/UsersLib';
import I18nLib from '../../lib/I18nLib';
import Globals from '../../contexts/Globals';

function Logout() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	return (
		<div id="logout">
			<button className="nav-link link-button" onClick={()=>UsersLib.logout(globals)}>
				<div className="icon-logout"></div>
				{i18n('menu.logout','Logout')}
			</button>
		</div>
	);
}

export default Logout;
import React,{ useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cookies from 'js-cookie';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import UsersLib from '../lib/UsersLib';
import { logRender,/* logError*/ } from '../lib/Debug';

import LoadingBlinker from './LoadingBlinker';
import Login from './authentication/Login';
import Account from './account/Account';
import Inviter from './Inviter';

function AppSwitch() {
	
	//--------------------- State
	
	let location = useLocation();
	let history = useHistory();
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let isAppPath = globals.isAccountPath(location.pathname);
	let pathIsLoginOrSignup = ['/login','/signup'].includes(location.pathname);
	let isAccountArea = isAppPath && !pathIsLoginOrSignup;
	let [initialPath,setInitialPath] = useState(isAccountArea ? location.pathname : null);
	
	// These are set to true if we don't need to load a user or friend, or after we have loaded them
	// These ensure we don't begin to render Login before we've run the effects below
	let [hasProcessedUser,setHasProcessedUser] = useState(false);
	
	// These are used when actually loading a user friend
	let [isLoading,setIsLoading] = useState(false);
	
	//--------------------- Routing
	
	// Capture initial path as soon as it's available
	if(!initialPath && isAccountArea) setInitialPath(location.pathname);
	
	//--------------------- Effects
	
	// Load user based on token in a cookie
	useEffect(()=>{
		
		let token = pathIsLoginOrSignup ? null : cookies.getJSON('token');
		
		if(token && !isLoading && !hasProcessedUser){
			setIsLoading(true);
			
			// Checks if token needs refresh, and if so, refreshes it
			UsersLib.getUserFromToken(token).then(({promise,currentUser:userState})=>{
				
				let handleUser = userState => {
					
					globals.mergeUserState(userState);
					
					// Token was refreshed and we already have user and clubs
					setHasProcessedUser(true);
					setIsLoading(false);
					
					if(pathIsLoginOrSignup){
						history.push('/profile');
					}
					
					// Clear any inviter
					localStorage.removeItem('inviter');
					globals.setInviter(null);
					
				};
				
				if(userState){
					handleUser(userState);
					promise.then(userState=>{
						globals.mergeUserState(userState);
					});
				} else {
					promise.then(userState=>{
						handleUser(userState);
					});
				}
				
			}).catch(err=>{
				console.error(err);
				setHasProcessedUser(true);
				setIsLoading(false);
				UsersLib.logout(globals);
			});
		} else if(!isLoading) {
			setHasProcessedUser(true);
		}
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
  
	logRender('AppSwitch -- render');
	
	let content;
	if(isLoading || !hasProcessedUser){
		content = (
			<LoadingBlinker text={i18n('account.loading','Loading account')} />
		);
	} else if(globals.userState.loggedIn && !pathIsLoginOrSignup ){
		content = (
			<Account />
		);
	} else {
		content = (
			<Login initialPath={initialPath} />
		);
	}
	
	return (
		<>
			{content}
			<Inviter />
		</>
	);
}

export default AppSwitch;


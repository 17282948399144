import React,{ useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { logRender } from '../lib/Debug';
import Globals from '../contexts/Globals';

import MainMenu from './MainMenu';
import Footer from './Footer';
import FootballHomeHeader from './football/FootballHomeHeader';
import RugbyHomeHeader from './rugby/RugbyHomeHeader';

let watcher = { isTransitioning: false };

function HomeHeader() {
	
	let globals = Globals.useContainer();
	
	//--------------------- State
	
	let [isHome,setIsHome] = useState(globals.isHome);
	let [homeHeaderHeight,setHomeHeaderHeight] = useState(globals.isHome);
	let [isWatchingSize,setIsWatchingSize] = useState(false);
	let [animState,setAnimState] = useState({
		fadeContentIn: false,
		fadeContentOut: false,
		prepContentIn: false,
		prepContentOut: false,
		fadeFooterIn: false,
		fadeFooterOut: false,
		prepFooterIn: false,
		prepFooterOut: false,
	});
	
	const mergeAnimState = useCallback(
		partial => {
			watcher.animState = { ...(watcher.animState || animState), ...partial };
			setAnimState(watcher.animState);
		},
		[animState],
	);
	
	//--------------------- Effects
	
	// Detect preparation to fade in
	// This allows us to render a frame with opacity: 0 before fading in
	// Otherwise the object will just jump straight to opacity: 1
	useEffect(()=>{
		if(animState.prepFooterIn){
			//document.getElementById('home-header-footer').getClientRects(); // Force layout reflow
			mergeAnimState({
				prepFooterIn: false,
				fadeFooterIn: true,
			});
		} else if(animState.prepFooterOut){
			//document.getElementById('home-header-footer').getClientRects(); // Force layout reflow
			mergeAnimState({
				prepFooterOut: false,
				fadeFooterOut: true,
			});
		} else if(animState.prepContentIn){
			//document.getElementById('home-header-content').getClientRects(); // Force layout reflow
			mergeAnimState({
				prepContentIn: false,
				fadeContentIn: true,
			});
		} else if(animState.prepContentOut){
			//document.getElementById('home-header-footer').getClientRects(); // Force layout reflow
			mergeAnimState({
				prepContentOut: false,
				fadeContentOut: true,
			});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[animState]);
	
	useLayoutEffect(()=>{
		
		if(!isWatchingSize){
			setIsWatchingSize(true);
			
			const ro = new ResizeObserver(entries => {
				if(isHome && entries[0].contentRect.height>0){
					//console.log(`Home header height changed: ${entries[0].contentRect.height}px`);
					setHomeHeaderHeight(entries[0].contentRect.height);
				}
			});

			ro.observe(document.getElementById('home-header-content')); // Watch dimension changes on body
		}
		
	},[isWatchingSize,isHome]);
	
	//--------------------- Styles
	
	let headerContentClassName = ['clearfix'];
	let headerFooterClassName = ['clearfix'];
	let headerClassName = ['clearfix'];
	
	if(globals.isHome){
		if(animState.prepFooterOut||animState.fadeFooterOut){
			headerFooterClassName.push('fadeout','fast');
			headerContentClassName.push('fadeout','hidden');
		} else if(animState.prepContentIn){
			headerFooterClassName.push('hidden');
			headerContentClassName.push('fadeout');
		} else if(animState.fadeContentIn){
			headerFooterClassName.push('hidden');
		} else {
			headerFooterClassName.push('fadeout','hidden');
		}
	} else {
		if(animState.prepContentOut||animState.fadeContentOut){
			headerContentClassName.push('fadeout','fast');
			headerFooterClassName.push('fadeout','hidden');
		} else if(animState.prepFooterIn){
			headerContentClassName.push('hidden');
			headerFooterClassName.push('fadeout');
		} else if(animState.fadeFooterIn){
			headerContentClassName.push('hidden');
		} else {
			headerContentClassName.push('fadeout','hidden');
		}
		headerClassName.push('dark');
	}
	headerContentClassName = headerContentClassName.join(' ');
	headerFooterClassName = headerFooterClassName.join(' ');
	headerClassName = headerClassName.join(' ');
	
	// Lock height of home page header
	let homeHeaderStyle = {};
	if(homeHeaderHeight !== null) homeHeaderStyle.height = `${homeHeaderHeight}px`;
	
	// Detect transitions
	
	let switchToApp = isHome && !globals.isHome;
	let switchToHome = !isHome && globals.isHome;
	
	if(switchToHome || switchToApp){
		
		let content = document.getElementById('home-header-content');
		let footer = document.getElementById('home-header-footer');
		
		// Resets
		delete content.ontransitionend;
		delete footer.ontransitionend;
		
		if(switchToApp){
			
			setIsHome(false);
			
			if(animState.fadeFooterOut){
				
				//----------------- Was heading home, fading out app footer, so we reverse state from here
				
				mergeAnimState({
					fadeFooterOut: false,
					fadeFooterIn: true,
				});
				footer.ontransitionend = e => {
					if(e.target !== footer) return;
					mergeAnimState({ fadeFooterIn: false });
				};
				
			} else if(animState.fadeContentIn){
				
				//----------------- Was heading home, fading in content, so we reverse state from here
				
				mergeAnimState({
					fadeContentIn: false,
					prepContentOut: true,
				});
				content.ontransitionend = e => {
					if(e.target !== content) return;
					mergeAnimState({
						fadeContentOut: false,
						prepFooterIn: true,
					});
					footer.ontransitionend = e => {
						if(e.target !== footer) return;
						mergeAnimState({ fadeFooterIn: false });
					};
				};
				
			} else {
				
				//----------------- No current animations, run normal flow
				
				mergeAnimState({ prepContentOut: true });
				content.ontransitionend = e => {
					if(e.target !== content) return;
					mergeAnimState({
						fadeContentOut: false,
						prepFooterIn: true,
					});
					footer.ontransitionend = e => {
						if(e.target !== footer) return;
						mergeAnimState({ fadeFooterIn: false });
					};
				};
				
			}
			
		} else if(switchToHome){
			
			setIsHome(true);
			
			if(animState.fadeContentOut){
				
				//----------------- Was heading to app, fading out content, so we reverse state from here
				
				mergeAnimState({
					fadeContentOut: false,
					fadeContentIn: true,
				});
				content.ontransitionend = e => {
					if(e.target !== content) return;
					mergeAnimState({ fadeContentIn: false });
				};
				
			} else if(animState.fadeContentIn){
				
				//----------------- Was heading to app, fading in app footer, so we reverse state from here
				
				mergeAnimState({
					fadeFooterIn: false,
					prepFooterOut: true,
				});
				footer.ontransitionend = e => {
					if(e.target !== footer) return;
					mergeAnimState({
						fadeFooterOut: false,
						prepContentIn: true,
					});
					content.ontransitionend = e => {
						if(e.target !== content) return;
						mergeAnimState({ fadeContentIn: false });
					};
				};
				
			} else {
				
				//----------------- No current animations, run normal flow
				
				mergeAnimState({ prepFooterOut: true });
				footer.ontransitionend = e => {
					if(e.target !== footer) return;
					mergeAnimState({
						fadeFooterOut: false,
						prepContentIn: true,
					});
					content.ontransitionend = e => {
						if(e.target !== content) return;
						mergeAnimState({ fadeContentIn: false });
					};
				};
				
			}
		}
	}
	
	logRender(`HomeHeader -- render`);
	
	return (
		<div id={`${"home-header-"+globals.sport}`} className={headerClassName} style={homeHeaderStyle}>
			<div id="home-header-inner" className={globals.sport}>
				<div id="home-header-content" className={headerContentClassName}>
					<div className="flex-row">
						<div className="home-top-bar-left left-text">
							<a href="https://blackout.games" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/blackout-games-square-min.png`} id="blackout-games-mini" alt="Blackout Games"/></a>
						</div>
						<div className="flex-grow"><MainMenu /></div>
						<div className="home-top-bar-right"></div>
					</div>
					{/* Rugby header */}
					<div className={globals.transition ? 'fade-out' : 'fade-in'}> { globals.sport === 'rugby' && !globals.transition && <RugbyHomeHeader /> } </div>
					{/* Football header */}
					<div className={globals.transition ? 'fade-in' : 'fade-out'}> { globals.sport === 'football' && globals.transition && <FootballHomeHeader /> } </div>
				</div>
				<div id="home-header-footer" className={headerFooterClassName}>
					<Footer/>
				</div>
			</div>
		</div>
	);
	
}

export default HomeHeader;


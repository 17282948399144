import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { log, logError, logRender } from '../../lib/Debug';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';

// This is from apps google account, google cloud, recaptcha v3 classic
// https://www.google.com/recaptcha/admin
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import Signup from './Signup';
import LoaderButton from '../LoaderButton';
import PasswordRevealButton from '../PasswordRevealButton';
import Panel from '../Panel';

import UsersLib from '../../lib/UsersLib';
import BlackoutAPI from '../../lib/BlackoutAPI';
import FacebookAPI from '../../lib/FacebookAPI';
import util from '../../lib/util';

function Login(props) {
	
	// Router
	let history = useHistory();
	let location = useLocation();
	let isLoginOrSignup = ['/login','/signup'].includes(location.pathname);
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let googleLoginElement = useRef(null);
	
	let [email,setEmail] = useState('');
	let [password,setPassword] = useState('');

	let [isLoggingIn,setIsLoggingIn] = useState(false);
	let [validationMessages,setInvalidInputs] = useState({});
	let [showPassword,setShowPassword] = useState(false);
	let [thirdPartyLoginError,setThirdPartyLoginError] = useState(null);
	let [loginPath,setLoginPath] = useState(isLoginOrSignup?location.pathname:'/login');
	
	if(isLoginOrSignup && loginPath !== location.pathname) setLoginPath(location.pathname);
	
	const validateInput = () => {
		let validInput = true;
		let validation = {};
		
		if(!email || !util.isEmail(email)){
			validation.email = i18n('login.email-required','Valid email address required');
			validInput = false;
		}

		if(!password){
			validation.password = i18n('login.password-required','Password must be provided');
			validInput = false;
		}

		setInvalidInputs(validation);
		return validInput;
	};

	// ----------------------- Initialise 3rd party login providers
	
	useEffect(()=>{

		// ----------------------- Login with Facebook

		FacebookAPI.initFB(globals.sport);

		if(globals.appleEnabled)
			window.AppleID.auth.init({
				clientId : globals.sport + '.blackout.web',
				scope : 'email',
				redirectURI : `https://${window.location.hostname}`,
				state : '',
				nonce : util.makeId(11),
				usePopup : true
			});
		
	},[globals.appleEnabled,globals.sport]);

	// ----------------------- Blackout Login Stuff

	const handleLogin = ({facebook, email, steam, apple, google}) => {
		
		setThirdPartyLoginError(null);
		
		// Set button as loading
		if(facebook && !globals.fbEnabled){
			alert(FacebookAPI.connectionErrorMessage);
		} else {
			setIsLoggingIn(true);
		}

		if(facebook && globals.fbEnabled){
			if(FacebookAPI.token && (FacebookAPI.userInfo && FacebookAPI.userInfo.email)){
				loginToBR({withFacebook: true});
			} else {
				FacebookAPI.fbLogin().then(res=>{
					console.log('Login res',res);
					if(res.authResponse) {
						loginToBR({withFacebook: true});
					} else {
						setThirdPartyLoginError(i18n('login.password-required-(facebook)',`We didn't receive your email address from facebook`));
						setIsLoggingIn(false);
					}
				}).catch(()=>{
					setThirdPartyLoginError(i18n('login.facebook-login-failed',`Failed to authenticate with facebook`));
					setIsLoggingIn(false);
				});
			}
			
		} else if (apple){
			
			window.AppleID.auth.signIn().then(data=>{
				console.log('login with apple data',data);
				loginToBR({withApple: true,token:data.authorization.id_token});
			}).catch(e=>{
				console.log('Sign in with Apple error',e);
				setIsLoggingIn(false);
			});
			
		} else if (google){		
			
			window.auth2.attachClickHandler(googleLoginElement.current, {},
				function(googleUser) {
					loginToBR({withGoogle: true,token:googleUser.getAuthResponse().id_token});
				}, function(error) {
					if(error && error.error === 'popup_closed_by_user'){
						// Ignore
					} else {
						console.log('googleUser ERR',JSON.stringify(error, undefined, 2));
					}
					setIsLoggingIn(false);
				});
			googleLoginElement.current.click();
			
			// Clone the node, to remove the click handler, otherwise the click
			// handler remains and any validation checks become meaningless
			googleLoginElement.current.cloneNode(true);

		} else if (email){
			
			if(validateInput())
				loginToBR({withEmail: true});
			else 
				setIsLoggingIn(false);

		} else if(steam){
			log('Half life 3 confirmed'); // """""under development"""""
		}

	};
	
	const loginToBR = ({withFacebook=false, withEmail=false, withApple=false, withGoogle=false, token}) => {

		let include = 'clubs,social';
		
		// 'token' also returns the manager...
		if(withFacebook){
			BlackoutAPI.post({
				endpoint: 'token',
				include,
				data: {
					grant_type: 'facebook',
					access_token: FacebookAPI.token.accessToken
				}
			}).then(res=>{
				processLoginSuccess(res);
			}).catch(err=>{
				processLoginFailure(err,{facebook:true});
			});
		} else if(withApple){
			BlackoutAPI.post({
				endpoint: 'token',
				include,
				data: {
					grant_type: 'apple',
					access_token: token
				}
			}).then(res=>{
				processLoginSuccess(res);
			}).catch(err=>{
				processLoginFailure(err,{apple:true});
			});
		} else if(withGoogle){
			BlackoutAPI.post({
				endpoint: 'token',
				include,
				data: {
					grant_type: 'google',
					access_token: token
				}
			}).then(res=>{
				processLoginSuccess(res);
			}).catch(err=>{
				processLoginFailure(err,{google:true});
			});
		} else if (withEmail){
			BlackoutAPI.post({
				endpoint: 'token',
				include,
				data: {
					grant_type: 'password',
					email,
					password,
				}
			}).then(res=>{
				processLoginSuccess(res);
			}).catch(err=>{
				processLoginFailure(err,{});
			});
		}
	};

	const processLoginSuccess = res => {
		
		// Unset button as loading
		setIsLoggingIn(false);

		let token = {
			accessToken: res.access_token,
			expiresIn: res.expires_in,
			refreshToken: res.refresh_token,
			type: res.token_type
		};
		
		let userState = UsersLib.processUser(res.manager,token);
		UsersLib.storeToken(token,res.manager.data);
		globals.mergeUserState(userState);
		history.push(props.initialPath || '/profile');
		
		// Clear any inviter
		localStorage.removeItem('inviter');
		globals.setInviter(null);
		
	};

	const processLoginFailure = (err, opts) => {
		
		// Unset button as loading
		setIsLoggingIn(false);
		
		const errorCode = err.response && err.response.data && err.response.data.errors && err.response.data.errors.code;
		
		// User-facing errors
		if(errorCode === 'userNotFound'){
			setThirdPartyLoginError(i18n('login.userNotFound',`Your email address was not found in our system`));
		} else if(errorCode === 'emailPermissionNotGiven'){
			setThirdPartyLoginError(i18n('login.emailPermissionNotGiven',`Email address permission not given`));
		} else if(errorCode === 'noClubsForSport') {
			setInvalidInputs({ wrongDetails: 'No ' + globals.sport + ' club found for this email address'});
		}
		// Generic errors based on provider
		else if(opts.facebook){
			logError(err);
			setThirdPartyLoginError(i18n('login.facebook-login-failed',`Failed to authenticate with facebook`));
		} else if(opts.apple){
			logError(err);
			setThirdPartyLoginError(i18n('login.apple-login-failed',`Failed to authenticate with Apple`));
		} else if(opts.google){
			logError(err);
			setThirdPartyLoginError(i18n('login.google-login-failed',`Failed to authenticate with Google`));
		} else {
			setInvalidInputs({ wrongDetails: i18n('login.failure-message','Invalid email or password')});
		}
		
	};

	const toggleLoginOrSignup = () => {
		setThirdPartyLoginError(null);
		if(location.pathname === '/signup'){
			setLoginPath('/login');
			history.push('/login');
		} else {
			setLoginPath('/signup');
			history.push('/signup');
		}
	};

	const togglePassword = () => {
		let classList = document.getElementById('eyeCon').classList;
		if(showPassword){
			setShowPassword(false);
			classList.remove('icon-eye');
			classList.add('icon-eye-off');
		} else {
			setShowPassword(true);
			classList.remove('icon-eye-off');
			classList.add('icon-eye');
		}
	};

	// updates input states
	const handleChange = (e) => {
		if(e.target.id === 'email'){
			email = e.target.value;
			setEmail(email);
		}
		else if(e.target.id === 'password'){
			setPassword(e.target.value);
		}
	};

	const handleFocus = (e) => {
		if(validationMessages && validationMessages[e.target.id]){
			delete validationMessages[e.target.id];
			setInvalidInputs(validationMessages);
		}
	};
	
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleLogin({email: true});
		}
	};
	
	// ----------------------- Render
	
	logRender(`Login -- render`);

	let emailLoginForm = (
		<div className="gap-after">
			<div className="sub-panel no-gap-after">
				<form onSubmit={e=>e.preventDefault()}>
					<div className="input-container">
						<input 
							id='email'
							type='text'
							onChange={handleChange}
							onFocus={handleFocus}
							defaultValue={email}
							placeholder={i18n('login.email','Email')}
							autoComplete='username'
						/>
						{validationMessages
							&& validationMessages.email
							? <span className="validation-text"><span className="icon-attention" />{validationMessages.email}</span>
							: ''
						}
					</div>

					<div className="input-container">
						<div className="password-input">
							<input 
								id='password'
								type={ showPassword ? 'text' :'password'}
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								onFocus={handleFocus}
								defaultValue={password}
								placeholder={i18n('login.password','Password')}
								autoComplete='current-password'
							/>
							<PasswordRevealButton onClick={()=>togglePassword()}/>
						</div>

						{validationMessages
							&& validationMessages.password
							? <span className="validation-text"><span className="icon-attention" />{validationMessages.password}</span>
							: ''
						}
					</div>
					<LoaderButton
						onClick={()=>handleLogin({email: true})}
						className="loader-button"
						isLoading={isLoggingIn}
					>{i18n('login.login','Log in')}</LoaderButton>
					{validationMessages
						&& validationMessages.wrongDetails
						? <span className="validation-text">
							<span className="icon-attention" />
								{validationMessages.wrongDetails}
							</span>
						: ''
					}
				</form>
				<div className="gap-before sm">
					<Link to={`/forgotpassword`} className="upper-link">{i18n('login.forgot-password','Forgot Password?')}</Link>
				</div>
			</div>
		</div>

	);

	let fbLoginButton = (
		<div className="gap-after">
			<LoaderButton
				onClick={()=>{handleLogin({facebook: true});}}
				className="fb-button match-sub-panel-side-padding"
				isLoading={isLoggingIn}
			>
				<span className="icon-facebook fb-button-content"></span>
				<span className="fb-button-content">{i18n('login.login-with-facebook','Log in with Facebook')}</span>
			</LoaderButton>
		</div>
	);
	
	let appleLoginButton = (
		<div className="gap-after">
			<LoaderButton
				onClick={()=>{handleLogin({apple: true});}}
				className="apple-button match-sub-panel-side-padding"
				isLoading={isLoggingIn}
			>
				<span className="apple-button-content">&nbsp;&nbsp;{i18n('login.login-with-apple','Sign in with Apple')}</span>
			</LoaderButton>
		</div>
	);

	let googleLoginButton = (
		<div className="gap-after">
			<div ref={googleLoginElement}>
				<LoaderButton
					onClick={()=>{handleLogin({google: true});}}
					className="google-button match-sub-panel-side-padding"
					isLoading={isLoggingIn}
				>
					<img src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" className="icon-google" alt="Google" />&nbsp;
					<span className="google-button-content">{i18n('login.signin-with-google','Sign in with Google')}</span>
				</LoaderButton>
			</div>
		</div>
	);
					
	
	let dontHaveAnAccount = (
		<div className="login">
			{i18n('login.no-account',`Don't have an account?`)}&nbsp;&nbsp;
			<button onClick={()=>{toggleLoginOrSignup();}} className="link-button upper-link">{i18n('login.signup','Signup')}</button>
		</div>
	);
	
	let alreadyHaveAnAccount = (
		<div className="login">
			{i18n('login.already-have-account',`Already have an account?`)}&nbsp;&nbsp;
			<button onClick={()=>{toggleLoginOrSignup();}} className="link-button upper-link">{i18n('login.login','Login')}</button>
		</div>
	);
	
	if(loginPath === '/signup'){
		// User is logged in with FB, but needs to create account/authorize with blackout
		return(
			<Panel>
				<GoogleReCaptchaProvider reCaptchaKey="6LdMSfcjAAAAALeDu1vm1JI-8gRZBx5jY-H6h3Y8">
					<Signup setThirdPartyLoginError={setThirdPartyLoginError} thirdPartyLoginError={thirdPartyLoginError} fbEnabled={globals.fbEnabled} initialPath={props.initialPath} />
				</GoogleReCaptchaProvider>
				{alreadyHaveAnAccount}
			</Panel>
		);
	} else {
		return(
			<Panel>
				<div className="center-text">
					<h2>Welcome <span className="color">{FacebookAPI.userInfo ? FacebookAPI.userInfo.first_name : ''}</span></h2>
					{emailLoginForm}
					{globals.fbEnabled?fbLoginButton:null}
					{globals.appleEnabled?appleLoginButton:null}
					{globals.googleEnabled?googleLoginButton:null}
					{thirdPartyLoginError
						? <div className="validation-text center-text"><span className="icon-attention" />{thirdPartyLoginError}</div>
						: ''}
					{dontHaveAnAccount}
				</div>
			</Panel>
		);
	}
	
	
	
}

export default Login;

import React, { useEffect, useState } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logError } from '../../lib/Debug';

import BlackoutAPI from '../../lib/BlackoutAPI';
import LoaderButton from '../LoaderButton';
import LoadingBlinker from '../LoadingBlinker';

// Components
function InviteAFriend() {
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [social,setSocial] = useState(globals.userState.social);

	let headers = {
		Token: globals.userState.token.accessToken,
		'Accept': 'application/vnd.api+json',
	};
	
	useEffect(()=>{
		if(!social){
			if(globals.userState.manager.socialId){
				
				BlackoutAPI.get({
					endpoint: 'social',
					id: globals.userState.manager.socialId,
					headers,
					include: 'Friends',
				}).then(res=>{
					log(`res GET SOCIAl BY ID...`,res);
					setSocial(res.data);
				}).catch(logError);
				
			} else {
				
				log(`invite a friend globals`,globals);
				
				BlackoutAPI.get({
					endpoint: 'managers',
					id: globals.userState.manager.id,
					include: 'social',
					headers,
				}).then(res=>{
					log(`res Social Id not found, getting manager with social included.....`,res);
					globals.mergeUserState({
						manager: res.data,
						social: res.included[0],
					});
				}).catch(logError);
				
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[social,globals.userState.manager]); 
	
	let inviteLinkTextArea;

	const copyToClipboard = (e) => {
		inviteLinkTextArea.select();
		document.execCommand('copy');
		e.target.focus();
	};

	let socialInfo;
	if(!social){
		socialInfo = ( 
			<LoadingBlinker text={i18n('account.loading-invite-link','Loading invite link')} />
		);
	} else {
		let referralCode = social.id;
		var inviteLink = `${globals.sportConfig.baseUrl}/invite/${referralCode}`;
		socialInfo = (
			<div className="relative">
				<h3 className="center-text">{i18n('account.invite-friend','Invite A Friend')}</h3>
				
				<div className="flex-row invite-link-container">
					<div className="invite-input-container">			
						<input 
							ref={el => inviteLinkTextArea = el}
							readOnly={true}
							type="text" 
							className="invite-link"
							value={inviteLink} 
							onClick={copyToClipboard}>
						</input>
					</div>
					
					<div className="flex-fixed copy-button-container">
						<LoaderButton
							onClick={copyToClipboard}
							className="md-inline"
						>{i18n('buttons.copy','Copy')}</LoaderButton>
					</div>
				</div>
				
			</div>
		);
	}
	
	socialInfo = (
		<div className="sub-panel">
			{socialInfo}
		</div>
	);

	return (
		<>
			{socialInfo}
		</>
	);
	
}

export default InviteAFriend;
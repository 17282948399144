import React, { useState } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import UsersLib from '../../lib/UsersLib';
import { log, logRender, logError } from '../../lib/Debug';

import BlackoutAPI from '../../lib/BlackoutAPI';
//import util from '../../lib/util';
import LoaderButton from '../LoaderButton';

function CloseAccount() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let [isConfirming,setIsConfirming] = useState(false);
	let [isClosingAccount,setIsClosingAccount] = useState(null);
	let [validationMessage,setValidationMessage] = useState(null);
	
	const closeAccount = () => {
			
		setIsClosingAccount(true);
		
		// Send blackout api post request ......
		BlackoutAPI.delete({
			endpoint: 'managers',
			id: globals.userState.manager.id,
			headers: {
				Token: globals.userState.token.accessToken,
			},
		}).then(res=>{
			setIsClosingAccount(false);

			log(`CloseAccount`,res);
			UsersLib.logout(globals);

		}).catch(err=>{
			setIsClosingAccount(false);
			
			if(err.response?.data?.errors?.message){
				logError(`closeAccount err`,err);
				setIsClosingAccount(err.response.data.errors.message);
			} else {
				// 500 errors don't have .response
				setValidationMessage(i18n('account.error-see-console','Error. See console for details.'));
				logError(err);
			}
		});
		
	};
	
	function confirmCloseAccount(){
		if(!isConfirming){
			setIsConfirming(true);
		}
	}
	
	function cancelCloseAccount(){
		if(isConfirming){
			setIsConfirming(false);
		}
	}

	logRender(`CloseAccount -- render`);

	return (
		<div className="sub-panel">
			<h3 className="center-text error-text"><span role="img" aria-label="warning">🚨</span> {i18n('account.close-account-title','Careful')} <span role="img" aria-label="warning">🚨</span></h3>
			<div className="center-text gap-after error-text">{i18n('account.close-account-text','Close my account and delete data for all Blackout Sports games')}</div>
			<LoaderButton
				className="sm gap-after"
				isLoading={isConfirming}
				onClick={confirmCloseAccount}
			>{i18n('buttons.close-account','Close Account')}</LoaderButton>
			{validationMessage
				? <div className="validation-text"><span className="icon-attention" />{validationMessage}</div>
				: null
			}
			{isConfirming?(
				<>
					<div className="gap-after center-text sm">{i18n('buttons.are-you-sure','Are you sure?')}</div>
					<div className="form-minor-message error-text gap-after center-text"><span className="icon-info-circled" />{i18n('account.close-account-helper','Consider changing your username first as it may appear in game history.')}</div>
					<LoaderButton
						className="red-button gap-after sm"
						isLoading={isClosingAccount}
						onClick={closeAccount}
					>{i18n('buttons.yes-delete-my-account','Yes, delete my account')}</LoaderButton>
					<LoaderButton
						className="sm"
						onClick={cancelCloseAccount}
					>{i18n('buttons.cancel','Cancel')}</LoaderButton>
				</>
			):null}
		</div>
	);
	
}

export default CloseAccount;
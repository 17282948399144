import React, { useState, useEffect, useRef } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logError } from '../../lib/Debug';
import util from '../../lib/util';

import BlackoutAPI from '../../lib/BlackoutAPI';
import FacebookAPI from '../../lib/FacebookAPI';
import LoaderButton from '../LoaderButton';
import Panel from '../Panel';

function ThirdPartyAccountManagement() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let googleLoginElement = useRef(null);

	let [validationMessages,setValidationMessages] = useState({});
	let [isUpdatingAccount,setIsUpdatingAccount] = useState(false);

	// ----------------------- Initialise 3rd party login providers
	
	useEffect(()=>{

		// ----------------------- Login with Facebook
		
		FacebookAPI.initFB(globals.sport);

		// ----------------------- Login with Apple
		
		if(globals.appleEnabled)
			window.AppleID.auth.init({
			clientId : globals.sport + '.blackout.web',
				scope : 'email',
				redirectURI : `https://${window.location.hostname}`,
				state : '',
				nonce : util.makeId(11),
				usePopup : true
			});
		
	},[globals.appleEnabled, globals.sport]);

	const unlinkAccount = ({steam, facebook, apple, google}) => {
		setIsUpdatingAccount(google?'google':(apple?'apple':(facebook?'facebook':'steam')));

		let patchAttributes = {};
		if(steam) patchAttributes.UnlinkSteam = true;
		if(facebook) patchAttributes.UnlinkFacebook = true;
		if(apple) patchAttributes.UnlinkApple = true;
		if(google) patchAttributes.UnlinkGoogle = true;

		BlackoutAPI.patch({
			endpoint: 'managers',
			id: globals.userState.manager.id,
			headers: {
				Token: globals.userState.token.accessToken,
				'Content-Type': 'application/vnd.api+json',
			},
			data: {
				type: 'managers',
				id: globals.userState.manager.id,
				attributes: patchAttributes
			}
		}).then(res=>{			
			log(`updateAccount`,res);

			// Log window out of facebook to clear auth token
			if(facebook) FacebookAPI.logout();

			globals.mergeUserState({ manager: res.data });
			setIsUpdatingAccount(false);

		}).catch(err=>{
			setIsUpdatingAccount(false);
			validationMessages = {};

			if(err.response){
				logError(`unlinkAccount`,err.response);
				if(err.response.data.errors.message.includes('Password must be set')){
					validationMessages.unlinkThirdParty = i18n('account.fb-unlink-password','Password must be set before you can unlink facebook');
				}
			} else {
				// 500 errors don't have .response
				logError(`unlinkAccount`,err);
				validationMessages.unlinkThirdParty = i18n('account.error-see-console','Error. See console for details.');
			}
			setValidationMessages(validationMessages);
		});
	};

	const linkFacebookAccount = () => {
		setIsUpdatingAccount('facebook');
		validationMessages = {};

		FacebookAPI.fbLogin().then(res=>{
			if(res.authResponse) {
				linkAccountToBR({facebook: true});
			} else {
				validationMessages.linkFacebook = (i18n('account.facebook-failed-auth',`We didn't receive your email address from facebook`));
				setValidationMessages(validationMessages);
				setIsUpdatingAccount(false);
			}
		}).catch(()=>{
			validationMessages.linkFacebook = (i18n('login.facebook-login-failed',`Failed to authenticate with facebook`));
			setValidationMessages(validationMessages);
			setIsUpdatingAccount(false);
		});
	};

	const linkAppleAccount = () => {
		setIsUpdatingAccount('apple');
		validationMessages = {};
		
		window.AppleID.auth.signIn().then(data=>{
			console.log('login with apple data',data);
			linkAccountToBR({apple: true,token:data.authorization.id_token});
		}).catch(e=>{
			console.log('Sign in with Apple error',e);
			validationMessages.linkApple = (i18n('login.apple-login-failed',`Failed to authenticate with Apple`));
			setValidationMessages(validationMessages);
			setIsUpdatingAccount(false);
		});
	};

	const linkGoogleAccount = () => {
		setIsUpdatingAccount('google');
		validationMessages = {};
		
		window.auth2.attachClickHandler(googleLoginElement.current, {},
			function(googleUser) {
				linkAccountToBR({google: true,token:googleUser.getAuthResponse().id_token});
			}, function(error) {
				if(error && error.error === 'popup_closed_by_user'){
					// Ignore
				} else {
					console.log('googleUser ERR',JSON.stringify(error, undefined, 2));
					validationMessages.linkApple = (i18n('login.google-login-failed',`Failed to authenticate with Google`));
					setValidationMessages(validationMessages);
				}
				setIsUpdatingAccount(false);
			});
		googleLoginElement.current.click();
		
		// Clone the node, to remove the click handler, otherwise the click
		// handler remains and any validation checks become meaningless
		googleLoginElement.current.cloneNode(true);
	};
	
	const linkAccountToBR = ({steamId, facebook, apple, google, token}) => {

		let patchAttributes = {};
		if(steamId){
			patchAttributes.SteamId = steamId;
		} else if(facebook){
			patchAttributes.FacebookToken = FacebookAPI.token.accessToken;
		} else if(apple){
			patchAttributes.AppleToken = token;
		} else if(google){
			patchAttributes.GoogleToken = token;
		}
		validationMessages = {};

		BlackoutAPI.patch({
			endpoint: 'managers',
			id: globals.userState.manager.id,
			headers: {
				Token: globals.userState.token.accessToken,
				'Content-Type': 'application/vnd.api+json',
			},
			data: {
				type: 'managers',
				id: globals.userState.manager.id,
				attributes:patchAttributes
			}
		}).then(res=>{
			setIsUpdatingAccount(false);
			
			log(`updateAccount`,res);
			globals.mergeUserState({ manager: res.data });

		}).catch(err=>{
			setIsUpdatingAccount(false);

			if(err.response){
				logError(`linkAccountToBR`,err.response);
				if(err.response.data.errors.code==='alreadyLinked'){
					validationMessages.linkFacebook = i18n('account.already-linked-to-another-account','The provided account is already linked to another manager');
				}else if(err.response.data.errors.code==='invalidToken'){
					validationMessages.linkFacebook = i18n('account.invalidToken','Failed to authenticate user. Are you sure the token has not expired?');
				} else {
					validationMessages.linkFacebook = i18n('account.error-see-console','Error. See console for details.');
				}
			} else {
				// 500 errors don't have .response
				logError(`linkAccountToBR`,err);
				validationMessages.password = i18n('account.error-see-console','Error. See console for details.');
			}
			setValidationMessages(validationMessages);
		});
		
	};
	
	const facebookLinkerContent = () => {
		let content = (
			<div>
				<LoaderButton
						onClick={()=>linkFacebookAccount()}
						className="loader-button sm gap-after"
						isLoading={isUpdatingAccount==='facebook'}
					>Link Account
				</LoaderButton>
				{validationMessages
					&& validationMessages.linkFacebook
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.linkFacebook}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.fb-link-helper','Linking Blackout Rugby to Facebook will allow you to log using Facebook')}</div>
				}
			</div>
		);
		return content;
	};
	
	const facebookUnlinkerContent = () => {		
		let isPasswordSet = globals.userState.manager.passwordSet ? true : false;
		let content = (
			<div>
				<LoaderButton
						onClick={()=>unlinkAccount({facebook: true})}
						className="loader-button sm gap-after red-button"
						isLoading={isUpdatingAccount==='facebook'}
						disabled={isPasswordSet?false:true}
					>{i18n('account.unlink-account','Unlink Account')}
				</LoaderButton>
				{validationMessages
					&& validationMessages.unlinkThirdParty
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.unlinkThirdParty}</div>
					: !isPasswordSet ? <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.fb-unlink-requires-password','Password must be set in order to unlink Facebook from Blackout Rugby')}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.fb-unlink-helper','This will not remove your Blackout Rugby account, it will only unlink it from facebook')}</div>
				}
			</div>
		);			
		
		
		return content;
	};
	
	const appleLinkerContent = () => {
		let content = (
			<div>
				<LoaderButton
						onClick={()=>linkAppleAccount()}
						className="loader-button sm gap-after"
						isLoading={isUpdatingAccount==='apple'}
					>Link Account
				</LoaderButton>
				{validationMessages
					&& validationMessages.linkApple
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.linkApple}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.apple-link-helper','Linking Blackout Rugby to Apple will allow you to log using Apple')}</div>
				}
			</div>
		);
		return content;
	};

	const appleUnlinkerContent = () => {		
		let isPasswordSet = globals.userState.manager.passwordSet ? true : false;
		let content = (
			<div>
				<LoaderButton
						onClick={()=>unlinkAccount({apple: true})}
						className="loader-button sm gap-after red-button"
						isLoading={isUpdatingAccount==='apple'}
						disabled={isPasswordSet?false:true}
					>{i18n('account.unlink-account','Unlink Account')}
				</LoaderButton>
				{validationMessages
					&& validationMessages.unlinkThirdParty
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.unlinkThirdParty}</div>
					: !isPasswordSet ? <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.unlink-requires-password','Password must be set in order to unlink from Blackout Rugby')}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.apple-unlink-helper','This will not remove your Blackout Rugby account, it will only unlink it from Apple')}</div>
				}
			</div>
		);			
		
		
		return content;
	};
	
	const googleLinkerContent = () => {
		let content = (
			<div>
				<span ref={googleLoginElement}>
					<LoaderButton
							onClick={()=>linkGoogleAccount()}
							className="loader-button sm gap-after"
							isLoading={isUpdatingAccount==='google'}
						>Link Account
					</LoaderButton>
				</span>
				{validationMessages
					&& validationMessages.linkGoogle
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.linkGoogle}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.google-link-helper','Linking Blackout Rugby to Google will allow you to log using Google')}</div>
				}
			</div>
		);
		return content;
	};

	const googleUnlinkerContent = () => {		
		let isPasswordSet = globals.userState.manager.passwordSet ? true : false;
		let content = (
			<div>
				<LoaderButton
						onClick={()=>unlinkAccount({google: true})}
						className="loader-button sm gap-after red-button"
						isLoading={isUpdatingAccount==='google'}
						disabled={isPasswordSet?false:true}
					>{i18n('account.unlink-account','Unlink Account')}
				</LoaderButton>
				{validationMessages
					&& validationMessages.unlinkThirdParty
					? <div className="validation-text"><span className="icon-attention" />{validationMessages.unlinkThirdParty}</div>
					: !isPasswordSet ? <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.unlink-requires-password','Password must be set in order to unlink from Blackout Rugby')}</div>
					: <div className="form-minor-message"><span className="icon-info-circled" />{i18n('account.google-unlink-helper','This will not remove your Blackout Rugby account, it will only unlink it from Google')}</div>
				}
			</div>
		);			
		
		
		return content;
	};
	
	let fbContent;
	if(globals.userState.manager.facebookId){
		fbContent = (
			<div className="sub-panel">
				<h3 className="center-text">{i18n('account.facebook','Facebook')}</h3>
				<div className="data-container account">
					<div className="data-row">
						<div className="key-col">{i18n('account.account-id','Account Id')}</div>
						<div className="val-col sub-text small-text">{globals.userState.manager.facebookId}</div>
					</div>
				</div>
				{ facebookUnlinkerContent() }
			</div>
		);
	} else {
		fbContent = (
			<div className="sub-panel">
				<h3 className="center-text">{i18n('account.facebook','Facebook')}</h3>
				{ facebookLinkerContent() }
			</div>
		);
	}
	
	let appleContent;
	if(globals.appleEnabled){
		if(globals.userState.manager.appleId){
			appleContent = (
				<div className="sub-panel">
					<h3 className="center-text">{i18n('account.apple','Apple')}</h3>
					<div className="data-container account">
						<div className="data-row">
							<div className="key-col">{i18n('account.account-id','Account Id')}</div>
							<div className="val-col sub-text tiny-text">{globals.userState.manager.appleId}</div>
						</div>
					</div>
					{ appleUnlinkerContent() }
				</div>
			);
		} else {
			appleContent = (
				<div className="sub-panel">
					<h3 className="center-text">{i18n('account.apple','Apple')}</h3>
					{ appleLinkerContent() }
				</div>
			);
		}
	}
	
	let googleContent;
	if(globals.googleEnabled){
		if(globals.userState.manager.googleId){
			googleContent = (
				<div className="sub-panel">
					<h3 className="center-text">{i18n('account.google','Google')}</h3>
					<div className="data-container account">
						<div className="data-row">
							<div className="key-col">{i18n('account.account-id','Account Id')}</div>
							<div className="val-col sub-text small-text">{globals.userState.manager.googleId}</div>
						</div>
					</div>
					{ googleUnlinkerContent() }
				</div>
			);
		} else {
			googleContent = (
				<div className="sub-panel">
					<h3 className="center-text">{i18n('account.google','Google')}</h3>
					{ googleLinkerContent() }
				</div>
			);
		}
	}

	return fbContent || appleContent || googleContent ? (
		<Panel>
			{globals.fbEnabled?fbContent:null}
			{globals.appleEnabled?appleContent:null}
			{globals.googleEnabled?googleContent:null}
		</Panel>
	) : null;
}

export default ThirdPartyAccountManagement;

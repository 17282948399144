import React, { useState, useEffect } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import BlackoutAPI from '../../lib/BlackoutAPI';
import { logError } from '../../lib/Debug';

import LoadingBlinker from '../LoadingBlinker';
import Club from '../Club';

function Clubs() {
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [clubs,setClubs] = useState(globals.userState.clubs);

	useEffect(()=>{
		if(!clubs || !clubs.length){
			BlackoutAPI.get({
				endpoint: 'clubs',
				filter: { key: 'Manager', value: globals.userState.manager.id}
			}).then(res=>{
				console.log(res.data);
				setClubs(res.data);
			}).catch(logError);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[clubs]);

	//console.log(globals.userState.manager.clubs);

	if(!clubs || !clubs.length){
		return(
			<div>
			<LoadingBlinker text={i18n('account.loading-clubs','Loading clubs')} />	
			</div>
		);
	} else {
		return (
			<div className="clubs-container">
				{
					clubs.map(club => {
						return (
							<Club club={club} key={club.id} />
						);
					})
				}
			</div>
		);
	}
	
}

export default Clubs;

import React from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import moment from 'moment';
import { Link } from 'react-router-dom';

// Components
import Panel from '../Panel';
import Clubs from './Clubs';
import InviteAFriend from './InviteAFriend';
import InviteInfo from './InviteInfo';

function Profile() {
	
	// State
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	// User is logged into BR
	let clubText = i18n('account.your-club-awaits','Your club awaits');
	if(globals.userState.manager.clubs && globals.userState.manager.clubs.length > 1){
		clubText = i18n('account.your-clubs-await','Your clubs await');
	}
	let classicLinked = globals.userState.manager.classic ? globals.userState.manager.classic : false;
	
	//console.log('globals.userState.manager',globals.userState.manager);
	
	let linkedTo;	
	if(classicLinked){
		linkedTo = (
			<div className="data-row">
				<div className="key-col">{i18n('account.classic-(Blackout-Rugby-Classic)','Classic')}</div>
				<div className="val-col">{classicLinked.username} (Id {classicLinked.id})</div>
			</div>
		);
	}
	
	let accountInfo = (
		<div className="center-text gap-after">
			<div className="data-container account">
				<div className="data-row">
					<div className="key-col">{i18n('account.username','Username')}</div>
					<div className="val-col">{globals.userState.manager.username} <Link to={`/settings`}><span className="icon-pencil-squared" aria-label={i18n('buttons.edit','Edit')}></span></Link></div>
				</div>
				<div className="data-row">
					<div className="key-col">{i18n('account.joined','Joined')}</div>
					<div className="val-col">{moment(globals.userState.manager.signedUp).format('lll')}</div>
				</div>
				<div className="data-row">
					<div className="key-col sm">{i18n('account.id','Id')}</div>
					<div className="val-col sm sub-text">{globals.userState.manager.id}</div>
				</div>
				{linkedTo}
			</div>
		</div>
	);
	
	return (
		<>
			<Panel>
				{accountInfo}
				<h2 className="center-text">{clubText}!</h2>
				<Clubs />
			</Panel>
			<Panel>
				<InviteAFriend />
				<InviteInfo />
			</Panel>
		</>
	);
	
}

export default Profile;
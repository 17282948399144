import debugCreator from 'debug';
window.debugName = 'rugbyweb';

//-------------- Log streams

const log = debugCreator(`${window.debugName}:log`);
const logError = debugCreator(`${window.debugName}:error`);
const logAPI = debugCreator(`${window.debugName}:api`);
const logRender = debugCreator(`${window.debugName}:renders`);

//-------------- Tell browser which streams to log

if(process.env.NODE_ENV === 'development')
	localStorage.debug = `${window.debugName}:log,${window.debugName}:api,${window.debugName}:error`;

export {
	log,
	logError,
	logAPI,
	logRender,
};
import React from 'react';
import Globals from '../../contexts/Globals';
import { logRender } from '../../lib/Debug';

// Components
import Profile from './Profile';
import Settings from './Settings';
import ManageSubscriptions from './ManageSubscriptions';
import Feedback from './Feedback';

function Account() {
	
	// State
	let globals = Globals.useContainer();
	
	logRender(`Account -- render`);
	
	if(globals.appPath.split('/')[1] === 'feedback'){
		
		return (
			<>
				<Feedback />
			</>
		);
						
	} else if(globals.appPath.split('/')[1] === 'subscriptions'){
		
		return (
			<>
				<ManageSubscriptions />
			</>
		);
						
	} else if(globals.appPath.split('/')[1] === 'settings'){
		
		return (
			<>
				<Settings />
			</>
			
		);
	} else { // Default to profile if URL is not recognised
		
		return (
			<>
				<Profile/>
			</>
		);
		
	}
	
}

export default Account;
import React from "react";

function ChatAlert(props){
	let alerts = [];

	const processAlerts = () => {

		for(let i = 0; i < props.alertsToDisplay.length; i++){
			alerts.push(props.alertsToDisplay[i]);
		}
	};

	return (
		<>
			<div>
				{processAlerts()}
				{alerts.map(a => <div key={a} className="chat-alert-item">
					<p className="chat-alert-p"><h4>Date: <br />
						Creator Id: <br />
						Manager Name: <br />
						Manager Id: <br />
						Title: <br />
						Description: <br />
						Expiry Date:
					</h4></p>
					<p className="chat-alert-p">{a.Date} <br />
						{a.Creator} <br />
						{props.managerName} <br />
						{a.Manager}  <br />
						{a.Title} <br />
						{a.Description} <br />
						{a.ExpiryDate}
						</p>
				</div>)}
			</div>
		</>
	);

}

export default ChatAlert;
import React from 'react';
import Globals from '../contexts/Globals';

function Footer() {
	let globals = Globals.useContainer();
	
	return (
		<div className="center-text">
			<div className="footer-socials">
				<a href={globals.sportConfig.discordUrl} target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/social-icon-discord.png`} className="social-icon discord" alt="Discord" /></a>
				<a href="https://www.facebook.com/BlackoutRugby/" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/social-icon-fb.png`} className="social-icon fb" alt="Facebook" /></a>
				<a href="https://twitter.com/blackoutrugby" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/signup-website/social-icon-twitter.png`} className="social-icon twitter" alt="Twitter" /></a>
			</div>
			<div className="footer-logo">
				<a href="https://blackout.games" target="_blank" rel="noopener noreferrer"><img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/bgl-logo.png`} alt="Blackout Games Logo" className="company-logo" /></a>
			</div>
			<div className="footer-copyright">
				<span className="no-wrap">© {new Date().getFullYear()} Blackout Sports Limited | <a href="https://blackout.games/privacy" target="_blank" rel="noopener noreferrer">Privacy</a></span>
			</div>
		</div>
	);
	
}

export default Footer;
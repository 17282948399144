import React from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

function PasswordRevealButton(props) {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	return (
		<button onClick={props.onClick} className="toggle-password" tabIndex="-1" type="button">
			<span className="icon-eye-off" id={props.id || 'eyeCon'} aria-label={i18n('login.password-visibility','Show/Hide Password')}></span>
		</button>
	);
	
}

export default PasswordRevealButton;
import React from 'react';
import { logRender } from '../../lib/Debug';

// Components
import Panel from '../Panel';
import UpdateAccountInfo from './UpdateAccountInfo';
import CloseAccount from './CloseAccount';
import ThirdPartyAccountManagement from './ThirdPartyAccountManagement';
import EmailPreferences from './EmailPreferences';
import UpdatePassword from './UpdatePassword';

function Settings() {
	
	logRender(`Settings -- render`);
		
	return (
		<>
			<Panel>
				<UpdateAccountInfo />
				<EmailPreferences />
			</Panel>
			<Panel>
				<UpdatePassword />
				<CloseAccount />
			</Panel>
			<ThirdPartyAccountManagement />
		</>
	);
	
}

export default Settings;
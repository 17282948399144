import React from 'react';

function LoadingBlinker(props) {
	
	return (
		<>
			<div className={(props.align==='left'?"left-text":"center-text") + ' flex-column-center'}>
				<h4 style={{ marginBottom: 0 }}>{props.text}</h4>
				<div className={(props.align==='left'?"":"center-div") + ' la-ball-pulse la-sm'}>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		</>
	);
	
}

export default LoadingBlinker;
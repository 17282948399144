import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Globals from './contexts/Globals';
import { logRender } from './lib/Debug';
import { useMediaQuery } from 'react-responsive';

import Home from './Home';
import App from './App';
import TopBar from './components/TopBar';
import LowerBar from './components/LowerBar';

// ScrollToTop function
let startMs, interval, startY, pow = Math.pow, floor = Math.floor, lastY, hasStarted=false;
function resetScrollToTop(){
	startMs=null;
	lastY=null;
	if(interval) clearInterval(interval);
	hasStarted = false;
}
function scrollToTop(ms) {
	if(!startMs){
		startMs = new Date().getTime();
		startY = window.scrollY;
		lastY = startY;
		interval = setInterval(()=>scrollToTop(ms),16.67); // 60 FPS
	}
	
	let t = new Date().getTime() - startMs;
	let x = t/ms;
	let currentY = window.scrollY;
	
	// Ease out expo
	x = x >= 1 ? 1 : 1 - pow(2, -10 * x);
	let nextY = floor((1-x)*startY);
	//console.log('lastY',lastY,'nextY',nextY);
	
	if(x>=1||currentY<=0){
		resetScrollToTop();
		window.scrollTo(0,0);
	} else if(hasStarted && currentY > lastY){
		resetScrollToTop();
	} else {
		if(!hasStarted && currentY < lastY) hasStarted = true;
		lastY = nextY;
		window.scrollTo(0,nextY);
	}
}

function Wrapper() {
	
	let globals = Globals.useContainer();
	let wrapperRef = React.createRef();
	let isInviteLink = useRouteMatch('/invite/:refCode');
	let isHome = !!useRouteMatch({ path:'/', exact: true }) || isInviteLink;
	let anchorAtBottom = false;
	const isWide = useMediaQuery({ query: globals.wideMediaQuery });
	
	//--------------------- State
	
	let [homeHeight,setHomeHeight] = useState(null);
	let [isFirstSwitch,setIsFirstSwitch] = useState(!isHome);
	let [isTransitioning,setIsTransitioning] = useState(false);
	let [prepTransitionToHome,setPrepTransitionToHome] = useState(false);
	let [prepTransitionToApp,setPrepTransitionToApp] = useState(false);
	let [isWatchingSize,setIsWatchingSize] = useState(false);
	
	//--------------------- Effects
	
	// Allow the .notransition class to be removed, and rendered before we actually begin the transition
	useEffect(()=>{
		if(prepTransitionToHome){
			globals.setIsHome(true);
			scrollToTop(1000);
			//window.scrollTo(0,0);
			setPrepTransitionToHome(false);
		} else if(prepTransitionToApp){
			globals.setIsHome(false);
			scrollToTop(1000);
			//window.scrollTo(0,0);
			setPrepTransitionToApp(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[prepTransitionToApp,prepTransitionToHome]);
	
	useEffect(()=>{
		if(!isWatchingSize){
			setIsWatchingSize(true);
			
			const ro = new ResizeObserver(entries => {
				//console.log(`Home height changed: ${entries[0].contentRect.height}px`);
				setHomeHeight(entries[0].contentRect.height);
			});
			
			ro.observe(document.getElementById('home')); // Watch dimension changes on body
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	// This runs after first render (e.g. componentDidMount)
	useEffect(()=>{
			
		let handleTransitionEnd = () => {
			const wrapper = document.getElementById('wrapper');
			
			let handler = e => {
				if(e.target !== wrapper) return; // Events bubble by default, so make sure we only act when called for the right element
				setIsTransitioning(false);
				wrapper.ontransitionend = null;
			};
			
			wrapper.ontransitionend = handler;
		};
		
		if(isHome && !globals.isHome){
			
			handleTransitionEnd();
			setIsTransitioning(true);
			setPrepTransitionToHome(true); // Re-render component
			
		} else if(!isHome && globals.isHome){
			
			if(!isFirstSwitch){
				handleTransitionEnd();
				setIsTransitioning(true);
				setPrepTransitionToApp(true); // Re-render component
			} else {
				// Switch without transition/animation
				globals.setIsHome(false);
				wrapperRef.current.scrollIntoView();
				setIsFirstSwitch(false);
			}
			
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[globals.isHome,isHome]);
	
	// Don't show anything until first render and then we can calculate heights above
	let viewportStyle = { height: 0 };
	let wrapperStyle = { height: 0 };
	let viewportClassName = [];
	if(!isTransitioning) viewportClassName.push('notransition');
	if(!anchorAtBottom)  viewportClassName.push('anchor-at-top');
	
	// Set heights and positions based on whether we are viewing home or app
	let appHeight = globals.appHeight || 0;
	if(homeHeight !== null){
		if(globals.isHome){
			viewportStyle = { height: `${homeHeight}px` };
			wrapperStyle = { height: `${homeHeight + appHeight}px` };
			if(anchorAtBottom){
				wrapperStyle.bottom = `0px`;
			} else {
				wrapperStyle.top = `-${appHeight}px`;
			}
		} else {
			
			let footerHeight = isWide ? window.innerWidth/1920*globals.appFooterHeight : globals.appFooterHeight;
			
			viewportStyle = { height: `${globals.appHeight + footerHeight}px` };
			wrapperStyle = { height: `${homeHeight + appHeight}px` };
			if(anchorAtBottom){
				wrapperStyle.bottom = `${footerHeight - homeHeight}px`;
			} else {
				wrapperStyle.top = `0px`;
			}
		}
	}
	
	logRender(`Wrapper -- render`);
	// console.log('viewportStyle',viewportStyle);
	// console.log('wrapperStyle',wrapperStyle);
	
	return (
		<div id="viewport" style={viewportStyle} className={viewportClassName.join(' ')}>
			<div id="wrapper" style={wrapperStyle} ref={wrapperRef}>
				<App homeHeight={homeHeight} />
				<Home />
				<TopBar />
				<LowerBar homeHeight={homeHeight} isTransitioning={isTransitioning} />
			</div>
		</div>
	);
	
}

export default Wrapper;


import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Unstated Next (State management)
import Globals from './contexts/Globals';

// Components
import Wrapper from './Wrapper';
import I18n from './components/I18n';

// css
import './index.scss';
import './icons.css';

try {
	ReactDOM.render(
		<Router>
			<Globals.Provider>
				<I18n />
				<Wrapper />
			</Globals.Provider>
		</Router>,
		document.getElementById('root')
	);
} catch(err) {
	console.error(err);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
import React from 'react';

function Panel(props) {
	
	return (
		<div className={'main-panel ' + props.className}>
			<div className="main-panel-content">
				{props.children}
			</div>
		</div>
	);
	
}

export default Panel;
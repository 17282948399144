import React from 'react';
//import { Link } from 'react-router-dom';
import Globals from '../contexts/Globals';

function InfoBox(props) {
	
	return (
		<div className={`info-box ${Globals.current.sport}`}>
			<div className="info-box-inner">
				<div className="info-box-content">
					{props.children}
				</div>
			</div>
		</div>
	);
	
}

export default InfoBox;
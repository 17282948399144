import React, { useState } from 'react';
import BlackoutAPI from '../lib/BlackoutAPI';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import { Link, useParams } from 'react-router-dom';
import { logError } from '../lib/Debug';

import LoaderButton from './LoaderButton';
import Panel from './Panel';

function ResetPassword() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let { emailParam } = useParams();
	if(!emailParam) emailParam = '';
	
	let [resetSuccessful,setResetSuccessful] = useState(false);
	let [email,setEmail] = useState(emailParam);
	let [invalidInput,setInvalidInput] = useState(false);
	let [isResetting,setIsResetting] = useState(false);
	
	// updates input states
	const handleChange = (e) => {
		setEmail(e.target.value);
		return;
	};

	const handleFocus = () => {
		setInvalidInput(false);
	};

	const handleSubmit = () => {
		setIsResetting(true);
		BlackoutAPI.post({
			endpoint: 'reset-password',
			headers: {
				'Content-Type': 'application/vnd.api+json',
			},
			data:{
				type:'reset-password',
				attributes: {
					Email: email.trim()
				}
			}
		}).then(()=>{
			setInvalidInput(false);
			setResetSuccessful(true);
			setIsResetting(false);
		}).catch(err=>{
			logError(err);
			setInvalidInput(true);
			setIsResetting(false);
		});
	};
	
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSubmit();
		}
	};

	return (
		<Panel>
			<div className="sub-panel center-text">
				{resetSuccessful ? 
					<div>
						<h3>Password Reset</h3>
						<p className="blurb">{i18n('misc.check-email','Check your email for temporary password.')}</p>
						<div className="blurb">{i18n('misc.check-spam','If you do not see it in your main inbox, please check your spam folder.')}</div>
					</div>
					: 
					<form onSubmit={e=>e.preventDefault()}>
						<h2>{i18n('misc.forgot-password','Forgot Password?')}</h2>
						<div className="input-container gap-after">
							<input 
								id='email'
								type='text'
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								defaultValue={email}
								onFocus={handleFocus}
								placeholder='*Email'
							/>
							{invalidInput ? <span className="validation-text"><span className="icon-attention" />{i18n('misc.invalid-email','Invalid email format')}</span>
								: ''}
						</div>
						<LoaderButton
							onClick={()=>{handleSubmit();}}
							className="loader-button gap-after"
							isLoading={isResetting}
						>
							<span className="no-wrap">{i18n('misc.reset-password','Reset Password')}</span>
						</LoaderButton>
					</form>
				}
				<div className="gap-before sm no-gap-after">
					<Link to={`/login`} className="upper-link">{i18n('misc.return-to-login','Return to login')}</Link>
				</div>
			</div>
		</Panel>
	);
	
}

export default ResetPassword;
import React,{ useState, useEffect } from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { /* log, */ logRender, logError } from '../lib/Debug';

import BlackoutAPI from '../lib/BlackoutAPI';

function Inviter() {
	
	// Load refCode from react router (provided if we are here via /invite/[code])
	const { refCode } = useParams();
	const history = useHistory();
	let isInviteLink = useRouteMatch('/invite/:refCode');
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	//--------------------- State
	
	let [hasProcessedFriend,setHasProcessedFriend] = useState(false);
	let [isLoadingFriend,setIsLoadingFriend] = useState(false);
	let [hasSetHome,setHasSetHome] = useState(false);
	
	//--------------------- Effects
	
	// Load inviter user | https://local.blackoutrugby.com:4200/invite/z84hu05d
	useEffect(()=>{
		
		if(refCode && !hasProcessedFriend && !isLoadingFriend){
			setIsLoadingFriend(true);
			
			BlackoutAPI.get({
				endpoint: 'social',
				id: refCode,
				include: 'manager',
			}).then(res=>{
				//log('friend',res);
				if(res && res.included && res.included.length){
					let inviter = res.included[0];
					inviter.refCode = refCode;
					localStorage.setItem('inviter',JSON.stringify(inviter));
					globals.setInviter(inviter);
				} else {
					localStorage.removeItem('inviter');
					globals.setInviter(null);
				}
				setHasProcessedFriend(true);
				setIsLoadingFriend(false);
			}).catch(err=>{
				logError(err);
				localStorage.removeItem('inviter');
				globals.setInviter(null);
				setHasProcessedFriend(true);
				setIsLoadingFriend(false);
			});
			
		} else if(!isLoadingFriend) {
			setHasProcessedFriend(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refCode]);
	
	useEffect(()=>{
		
		// Remove /invite stuff from URL once user is loaded
		if(!hasSetHome && isInviteLink && globals.inviter && hasProcessedFriend){
			history.push('/');
			setHasSetHome(true);
		
		// If user goes back in history to reload the inviter
		} else if(hasSetHome && isInviteLink){
			setHasSetHome(false);
			localStorage.removeItem('inviter');
			globals.setInviter(null);
			setHasProcessedFriend(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[globals.inviter,hasProcessedFriend]);
	
	logRender('Inviter -- render');
	
	if(globals.inviter && !isLoadingFriend){
		return(
			<div id="inviter">
				<div id="invited-by">{i18n('account.invited-by','Invited by')}</div>
				<div id="invite-code">{globals.inviter.refCode}</div>
			</div>
		);
	} else if(isLoadingFriend || !hasProcessedFriend){
		return(
			<div id="inviter">
				<div id="invited-by">{i18n('home.loading-inviter','Loading inviter')}</div>
			</div>
		);
	} else {
		return(
			<></>
		);
	}
	
}

export default Inviter;


import React,{ useState, useEffect } from 'react';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import { logRender } from '../lib/Debug';
import { Link } from 'react-router-dom';

import MainMenu from './MainMenu';
import SubMenu from './SubMenu';

function TopBar() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	//--------------------- State
	
	let [isShowingTopBar,setIsShowingTopBar] = useState(false);
	
	//--------------------- Effects
	
	// Show on scroll position if on home screen
	useEffect(() => {
		
		const handleScroll = () => {
			let scrollTop = window.scrollY;
			if(scrollTop > 100 && !isShowingTopBar){
				setIsShowingTopBar(true);
			} else if(scrollTop <= 100 && isShowingTopBar){
				setIsShowingTopBar(false);
			}
		};
		
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
		
	},[isShowingTopBar]);
	
	//--------------------- Styles
	
	let topBarClassName = ['clearfix'];
	if(isShowingTopBar || !globals.isHome) topBarClassName.push('showing');
	topBarClassName = topBarClassName.join(' ');
	
	let subBarClassName = ['clearfix'];
	if(!globals.isHome && globals.userState.loggedIn) subBarClassName.push('showing');
	subBarClassName = subBarClassName.join(' ');
  
	logRender(`TopBar -- render`);
	
	return (
		<div id="top-bar-wrapper" className={topBarClassName}>
			<div id="sub-bar" className={subBarClassName}>
				<div id="sub-bar-content" className="flex-row-center">
					<div id="sub-bar-title" style={{ flex: '0 0 170px' }}>
						{i18n('account.title','Account')}
					</div>
					<div id="sub-bar-menu" style={{ flex: '0 0 auto' }}>
						<SubMenu />
					</div>
				</div>
			</div>
			<div id="top-bar">
				<div id="top-bar-content" className="flex-row">
					<div className="top-bar-left">
						{globals.sport === 'football' && globals.transition &&
							<div className={globals.transition ? 'fade-in' : 'fade-out'}>
							<img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2023/football-logo-short.png`} id="top-bar-logo-football" alt="Blackout Football"></img>
							</div>
						}
						{globals.sport === 'rugby' && !globals.transition &&
							<div className={globals.transition ? 'fade-out' : 'fade-in'}>
								<Link to="/"><img src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/rugby-logo-2021-small.png`} id="top-bar-logo" alt="Blackout Rugby" />
								</Link>
							</div>
						}
					</div>

					<div className="flex-grow">
						<MainMenu />
					</div>
					<div className="top-bar-right"></div>
				</div>
			</div>
		</div>
	);
	
}

export default TopBar;


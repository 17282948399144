import { logError } from '../lib/Debug';

export default Object.assign({

	requiredPermissions: ['email'],
	
	token: null,
	userInfo: null,
	knownFBLoginStatus: null,
	// For testing
	// null | 'not_authorized' | 'unknown'
	forceFbStatus: null,

	// This will detach our app from the users facebook account. Meaning, they will be repromoted by facebook to authorize 
	// their permissions again next time they log in. Normally we leave this attached whenever possible.
	// But require this for linking/unlinking accounts.
	logout(){
		this.reset();
		this.knownFBLoginStatus = null;
		window.FB.logout();
	},
	
	reset(){
		this.token = null;
		this.userInfo = null;
		this.forceFbStatus = null;
		
		// Don't reset this, otherwise if we log straight back in we get a warning from facebook that we are replacing a token
		//this.knownFBLoginStatus = null;
	},
	
	clearFacebookLoginStatus(){
		console.log(`facebookAPI: clearFacebookLoginStatus 1`);

		this.knownFBLoginStatus = null;
		console.log(`facebookAPI: clearFacebookLoginStatus 2:`,this.knownFBLoginStatus);

		window.fbAsyncInit();
	},
	
	needFacebookAuth(){
		return !this.knownFBLoginStatus || !this.knownFBLoginStatus.authResponse;
	},

	connectionErrorMessage: `We couldn't communicate with Facebook.\nPlease check firewall and browser settings.\nOr try email/password instead.`,
	
	async initFB(sport){

		if(!window.fbAsyncInit){
			if (sport === 'rugby') {
				console.log('fb-rugby');
				window.fbAsyncInit = () => {

					window.FB.init({
						appId: '230716417077656',
						cookie: true,  // enable cookies to allow the server to access
						// the session
						xfbml: true,  // parse social plugins on this page
						version: 'v4.0'
					});

					window.FB.getLoginStatus(res => {
						this.knownFBLoginStatus = res;
						this.statusChangeCallback(res);
					});

				};
			} 
			if (sport === 'football') {
				window.fbAsyncInit = () => {

				window.FB.init({
					appId      : '230716417077656',
					cookie     : true,  // enable cookies to allow the server to access
									// the session
					xfbml      : true,  // parse social plugins on this page
					version    : 'v18.0' 
				});
				
				window.FB.getLoginStatus(res => {
					this.knownFBLoginStatus = res;
					this.statusChangeCallback(res);
				});

				};
			}
		
			// Load the SDK asynchronously
			(function(d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) return;
				js = d.createElement(s); js.id = id;
				js.src = "//connect.facebook.net/en_US/sdk.js";
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));

		} else if(window.FB) {
			
			window.FB.getLoginStatus(res => {

				this.knownFBLoginStatus = res;
				this.statusChangeCallback(res);

			});		

		}
	},

	// This is called with the results from from FB.getLoginStatus().
	async statusChangeCallback(res) {
		if(this.forceFbStatus)
			res.status = this.forceFbStatus;
		
		// Set this regardless of status, so we can capture token even if testing
		this.token = res.authResponse;
		
		return {status:res.status};
	},

	// gets users FB after they've been authorized
	async loadFBUser() {
		return new Promise(resolve=>{
			window.FB.api('/me',{fields: ['first_name', 'last_name', 'name', 'email']}, res=>{
				this.userInfo = res;
				resolve(res);
			});
		});
	},

	// This is used for the manual login button.
	handleFBLogin(e){
		this.userInfo = {
			first_name: e.first_name,
			last_name: e.last_name,
			name: e.name,
			email: e.email,
			id: e.id
		};

		this.token = {
			accessToken: e.accessToken,
			expiresIn: e.expiresIn,
			signedRequest: e.signedRequest,
			userID: e.userID,
			data_access_expiration_time: e.data_access_expiration_time
		};
	},

	async fbLogin(){
		return new Promise((resolve,reject)=>{
			// Skip this, otherwise when you logout (unlink facebook) in one tab, while another tab is open, the open tab will have cached facebook login info and will try to use the same token and fail to login, with nothing the user can do except to clear site data and start from scratch
			//if(this.needFacebookAuth()){
			window.FB.login( res => {
				var authResponse = res.authResponse;
				if (res.authResponse) {
					window.FB.api('/me',{fields: ['first_name', 'last_name', 'name', 'email']}, profile=>{
						if(!profile.email){
							reject(`no-email`);
						} else {
							res.profile = profile;
							this.token = authResponse;
							this.userInfo = res;
							resolve(res);
						}
					});
					this.knownFBLoginStatus = res;
				} else {
					let err = new Error('User cancelled login or did not fully authorize.');
					err.code = 'cancelled';
					logError(err);
					reject(err);
				}
			},{scope: this.requiredPermissions, return_scopes: true, auth_type: 'rerequest' });
			//} else {
			//	this.token = this.knownFBLoginStatus.authResponse;
			//	this.userInfo = this.knownFBLoginStatus;
			//	resolve(this.knownFBLoginStatus);
			//}
		});
	},

});
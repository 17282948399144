import React, { useState } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logRender, logError } from '../../lib/Debug';

import BlackoutAPI from '../../lib/BlackoutAPI';
import LoaderButton from '../LoaderButton';
import PasswordRevealButton from '../PasswordRevealButton';

function UpdatePassword() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let [isUpdatingAccount,setIsUpdatingAccount] = useState(false);
	let [validationMessages,setValidationMessages] = useState({});
	let [showPassword,setShowPassword] = useState({ old: false, new: false });
	let [oldPassword,setOldPassword] = useState('');
	let [password,setPassword] = useState('');
	let [success,setSuccess] = useState(false);

	// updates input states
	const handleChange = (e) => {
		if(e.target.id === 'password')
			setOldPassword(e.target.value);
		else if(e.target.id === 'newPassword')
			setPassword(e.target.value);
	};

	const handleFocus = (e) => {
		if(success){
			setSuccess(false);
		}
		if(validationMessages && validationMessages[e.target.id]){
			delete validationMessages[e.target.id];
			setValidationMessages(validationMessages);
		}
	};
	
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			updateAccount();
		}
	};

	const togglePassword = (key) => {
		let classList = document.getElementById(key+'EyeCon').classList;
		if(showPassword[key]){
			showPassword[key] = false;
			classList.remove('icon-eye');
			classList.add('icon-eye-off');
		} else {
			showPassword[key] = true;
			classList.remove('icon-eye-off');
			classList.add('icon-eye');
		}
		setShowPassword({ ...showPassword });
	};

	const updateAccount = () => {
		let attributes = {};
		let validInputs = true;

		// Old password must be provided
		if(globals.userState.manager.passwordSet){
			if(oldPassword){
				attributes.CurrentPassword = oldPassword;
			} else {
				validationMessages.oldPassword = i18n('account.current-password-required',`Your current password must be provided`);
				validInputs = false;
			}
		} else {
			attributes.CurrentPassword = '';
		}

		// New password
		if(password && (password.length < 7)){
			validationMessages.password = i18n('account.password-length',`Password must be at least 7 characters`);
			validInputs = false;
		} else if (password && (password.length >= 7)){
			attributes.Password = password;
		}

		if(password === oldPassword){
			validationMessages.password = i18n('account.password-conflict',`New password must be different from the old one`);
			validInputs = false;
		}

		if(!validInputs){
			setValidationMessages(validationMessages);
			return;
		}
		setIsUpdatingAccount(true);
		
		// Send blackout api post request ......
		BlackoutAPI.patch({
			endpoint: 'managers',
			id: globals.userState.manager.id,
			headers: {
				Token: globals.userState.token.accessToken,
				'Content-Type': 'application/vnd.api+json',
			},
			data: {
				type: 'managers',
				id: globals.userState.manager.id,
				attributes
			}
		}).then(res=>{
			setIsUpdatingAccount(false);
			
			log(`updateAccount`,res);
			globals.mergeUserState({ manager: res.data });
			setSuccess(true);
			setPassword('');
			setOldPassword('');

		}).catch(err=>{
			setIsUpdatingAccount(false);
			validationMessages = {};

			if(err.response){
				logError(`updateAccount`,err);
				if(err.response.data.errors.message.includes('Your password is incorrect')){
					validationMessages.oldPassword = i18n('account.wrong-password','Incorrect password');
				} else if(err.response.data.errors.message.includes(`Cannot set password as '-------'`)){
					validationMessages.password = `${i18n('account.protected-password','Password cannot be')} -------`;
				}
			} else {
				// 500 errors don't have .response
				logError(err);
				validationMessages.password = i18n('account.error-see-console','Error. See console for details.');
			}
			setValidationMessages(validationMessages);
		});
		
	};

	const setPasswordForm = () => {		
		return(
			<form onSubmit={e=>e.preventDefault()}>
				<h3 className="center-text">{i18n('account.set-password','Set a Password')}</h3>
				
				{/* This is for password manager extensions to identify the user account */}
				<input 
					id='hidden-email' 
					type='text'
					defaultValue={globals.userState.manager.email}
					autoComplete="username"
					style={{display:'none'}}
				/>

				<div className="input-container">
					<div className="password-input">
						<input 
							id='newPassword' 
							type={ showPassword.new ? 'text' : 'password'}						
							onChange={handleChange}
							onFocus={handleFocus}
							placeholder={i18n('account.new-password','New Password')}
							value={password}
							autoComplete="new-password"
						/>
						<PasswordRevealButton onClick={()=>togglePassword('new')} id="newEyeCon"/>
					</div>
					{validationMessages
						&& validationMessages.password
						? <span className="validation-text"><span className="icon-attention" />{validationMessages.password}</span>
						: ''
					}
				</div>
				<LoaderButton
					onClick={()=>updateAccount()}
					className="sm"
					isLoading={isUpdatingAccount}
				>{ success ? i18n('account.password-changed','Password Changed') : i18n('buttons.submit','Submit')}</LoaderButton>
			</form>
		);		
	};

	const updatePasswordForm = () => {		
		return(
			<form onSubmit={e=>e.preventDefault()}>
				<h3 className="center-text">{i18n('account.change-password','Change Password')}</h3>
				
				{/* This is for password manager extensions to identify the user account */}
				<input 
					id='hidden-email' 
					type='text'
					defaultValue={globals.userState.manager.email}
					autoComplete="username"
					style={{display:'none'}}
				/>
				
				<div className="input-container">
					<div className="password-input">
						<input 
							id='password' 
							type={ showPassword.old ? 'text' :'password'}						
							onChange={handleChange}
							onKeyDown={handleKeyDown}
							onFocus={handleFocus}
							placeholder={`*${i18n('account.old-password','Old Password')}`}
							value={oldPassword}
							autoComplete="current-password"
						/>
						<PasswordRevealButton onClick={()=>togglePassword('old')} id="oldEyeCon"/>
					</div>
					{validationMessages
						&& validationMessages.oldPassword
						? <span className="validation-text"><span className="icon-attention" />{validationMessages.oldPassword}</span>
						: ''
					}
				</div>
				<div className="input-container">
					<div className="password-input">
						<input 
							id='newPassword' 
							type={ showPassword.new ? 'text' :'password'}						
							onChange={handleChange}
							onFocus={handleFocus}
							onKeyDown={handleKeyDown}
							placeholder={i18n('account.new-password','New Password')}
							value={password}
							autoComplete="new-password"
						/>
						<PasswordRevealButton onClick={()=>togglePassword('new')} id="newEyeCon"/>
					</div>
					{validationMessages
						&& validationMessages.password
						? <span className="validation-text"><span className="icon-attention" />{validationMessages.password}</span>
						: ''
					}
				</div>
				<LoaderButton
					onClick={()=>updateAccount()}
					className="sm"
					isLoading={isUpdatingAccount}
				>{ success ? i18n('account.password-changed','Password Changed') : i18n('buttons.submit','Submit')}</LoaderButton>
			</form>
		);
		
	};
	
	logRender(`Update Password -- render`);
	
	let content = globals.userState.manager.passwordSet ? updatePasswordForm() : setPasswordForm();

	return (
		<div className="sub-panel">
			{content}
		</div>
	);
	
}

export default UpdatePassword;
// npm packages
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import { log, logRender, logError } from '../../lib/Debug';

// This is from apps google account, google cloud, recaptcha v3 classic
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

// Custom lib files
import UsersLib from '../../lib/UsersLib';
import BlackoutAPI from '../../lib/BlackoutAPI';
import util from '../../lib/util';
import FacebookAPI from '../../lib/FacebookAPI';

// Components
import LoaderButton from '../LoaderButton';
import PasswordRevealButton from '../PasswordRevealButton';

function Signup(props) {
	const { executeRecaptcha } = useGoogleReCaptcha();
	
	let history = useHistory();
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let [refCode,setRefCode] = useState('');
	let [club,setClub] = useState('');
	let [email,setEmail] = useState('');
	let [password,setPassword] = useState('');
	let [country,setCountry] = useState('none');
	let [countries,setCountries] = useState([]);
	let [isSigningUp,setIsSigningUp] = useState(false);
	let [showPassword,setShowPassword] = useState(false);
	let [showEmailField,setShowEmailField] = useState(false);
	let [validationMessages,setInvalidInputs] = useState({});
	let googleLoginElement = useRef(null);

	// If invite link, set referral code to link code
	if(globals.inviter){
		refCode = globals.inviter.refCode;
	}

	let headers = {
		'Content-Type': 'application/vnd.api+json',
	};
	
	// Load countries
	useEffect(()=>{
		
		let isCleanedUp = false;
		
		BlackoutAPI.get({
			endpoint: 'countries',
			sort: 'name',
			headers,
		}).then(res=>{
			if(isCleanedUp) return;
			
			let countryData = res.data;
			let countries = [];
			for(let i = 0; i < countryData.length; i++){
				countries.push({
					id: countryData[i].id,
					game: countryData[i].game,
					name: countryData[i].name
				});
			}
			setCountries(countries);
		}).catch(logError);
		
		return () => {
			isCleanedUp = true;
		};
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	// updates input states
	const handleChange = (e) => {
		if(e.target.id === 'refCode')
			setRefCode(e.target.value);
		else if(e.target.id === 'club')
			setClub(e.target.value);
		else if(e.target.id === 'email')
			setEmail(e.target.value);
		else if(e.target.id === 'password')
			setPassword(e.target.value);
		else if(e.target.id === 'country')
			setCountry(e.target.value);
	};
	
	const handleFocus = (e) => {
		if(validationMessages && validationMessages[e.target.id]){
			delete validationMessages[e.target.id];
			setInvalidInputs(validationMessages);
		}
	};
	
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			handleSubmit({usingEmail: true});
		}
	};
	
	const handleSubmit = ({usingFacebook, usingApple, usingEmail, usingGoogle}) => {
		// Check if input fields are valid
		let validInput = validateInput({email});
		// If inputs are invalid then return;
		if(!validInput){
			return;
		}

		if(usingFacebook && !props.fbEnabled){
			log('Facebook login fail');
			alert(FacebookAPI.connectionErrorMessage);
			return;
		}
		
		if(!isSigningUp){
			if(usingEmail){
				handleSignup({usingEmail: true});
			}
			else if(usingFacebook){
				if(FacebookAPI.token && (FacebookAPI.userInfo && FacebookAPI.userInfo.email)){
					// We already have fb access, so let's jump straight to signup
					log(`handleSubmit already FB auth`);
					handleSignup({usingFacebook: true});
				} else {
					FacebookAPI.fbLogin().then(res=>{
						log(`fbLogin res`,res);
						if(res) {
							handleSignup({usingFacebook: true});
						} else {
							// User hasn't granted permissions
							props.setThirdPartyLoginError(i18n('signup.email-required-facebook',`We require your email address to register your club`));
						}
					}).catch((e)=>{
						console.log('Failed to authenticate with facebook',e);
						props.setThirdPartyLoginError(i18n('login.facebook-login-failed',`Failed to authenticate with facebook`));
						setIsSigningUp(false);
					});
				}
			}
			else if(usingApple){
				window.AppleID.auth.signIn().then(data=>{
					log(`apple login res (during Signup handleSubmit)`,data);
					handleSignup({usingApple,token:data.authorization.id_token});
				}).catch(e=>{
					console.log('Sign up with Apple error',e);
					if(!e.error || (e.error !== 'user_trigger_new_signin_flow' || e.error !== 'popup_closed_by_user')){
						props.setThirdPartyLoginError(i18n('login.apple-login-failed',`Failed to authenticate with Apple`));
						setIsSigningUp(false);
					}
				});
			} else if(usingGoogle){
				window.auth2.attachClickHandler(googleLoginElement.current, {},
					function(googleUser) {
						handleSignup({usingGoogle: true, token:googleUser.getAuthResponse().id_token});
					}, function(error) {
						if(error && error.error === 'popup_closed_by_user'){
							// Ignore
						} else {
							console.log('googleUser ERR',JSON.stringify(error, undefined, 2));
						}
						setIsSigningUp(false);
					});
				googleLoginElement.current.click();
				
				// Clone the node, to remove the click handler, otherwise the click
				// handler remains and any validation checks become meaningless
				googleLoginElement.current.cloneNode(true);
			}

		}
	};
	
	const validateInput = ({email}) => {
		let validInput = true;
		let validation = {};

		if((!club || club.length < 4  || club.length > 24) || !util.regexCheck(club)){
			validation.club = i18n('signup.num-characters','Must contain between 4-22 characters');
			validInput = false;
		}
		if(refCode && ((refCode.length < 5 || refCode.length > 24) || !util.regexCheck(refCode))){
			validation.refCode =  i18n('signup.referral-characters','Must contain between 5 and 24 characters') + ' (a-z,A-Z,0-9)';
			validInput = false;
		}
		if(!country || country === 'none'){
			validation.country = i18n('signup.select-country','Must select a country');
			validInput = false;
		}

		if(email){
			if(!password || password.length < 7 ){
				validation.password = i18n('signup.min-password','Password must be at least 7 characters');
				validInput = false;
			}
			if(!email || !util.isEmail(email)){
				validation.email = i18n('login.email-required','Valid email address required');
				validInput = false;
			}
		}
		setInvalidInputs(validation);

		return validInput;
	};
	
	const handleSignup = async ({usingFacebook, usingApple, usingEmail, usingGoogle, token}) => {
		let attributes = {
			ClubName: club,
			ClubCountry: country,
		};

		// add referral code if present
		if(refCode !== ''){
			attributes.ReferralCode = refCode;
		}

		if(usingEmail){
			attributes.Email = email;
			attributes.Password = password;
			if (!executeRecaptcha) {
				console.log('Execute recaptcha not yet available');
				return;
			}
		} else if(usingFacebook){
			attributes.FacebookToken = FacebookAPI.token.accessToken;
		} else if(usingApple){
			attributes.AppleToken = token;
		} else if(usingGoogle){
			attributes.GoogleToken = token;
		}

		setIsSigningUp(true);
		
		const loadCaptcha = async ()=>{
			if(usingEmail){
				attributes.CaptchaToken = await executeRecaptcha('submit');
			}
		};
		
		loadCaptcha().then(async ()=>{
			
			BlackoutAPI.post({
				endpoint: 'managers',
				include: 'clubs,social',
				headers,
				data: {
					type: 'managers',
					attributes
				}
			}).then(res=>{
				setIsSigningUp(false);
				
				// let token = res.data.token;
				let token = {
					accessToken: res.data.token.access_token,
					expiresIn: res.data.token.expires_in,
					refreshToken: res.data.token.refresh_token,
					type: res.data.token.token_type
				};
				log(`Signup token`,token);
				
				let userState = UsersLib.processUser(res,token);
				UsersLib.storeToken(token,res.data);
				globals.mergeUserState(userState);
				history.push(props.initialPath || '/profile');
				
				// Clear any inviter
				localStorage.removeItem('inviter');
				globals.setInviter(null);
				
			}).catch(err=>{
				setIsSigningUp(false);
				
				if(err.response){
					err = err.response;
					logError(err);
					if(err.data.errors.message.includes('Name contained profanity')){
						let validation = { club: i18n('signup.no-profanity','Name contained profanity')};
						setInvalidInputs(validation);
					} else if(err.data.errors.message.includes('Invalid Invite Code')){
						let validation = { refCode: i18n('signup.invalid-invite-code','Invalid invite code')};
						setInvalidInputs(validation);
					} else if(err.data.errors.message.includes('This manager conflicts with a pre-existing manager.')){
						let validation = { email: i18n('signup.email-already-exists','This email address is already registered')};
						setInvalidInputs(validation);
					} else {
						alert(err.data.errors.message);
					}
				} else {
					// 500 errors don't have .response
					logError(err);
				}
				
			});
			
		});

	};

	const toggleEmailFields = () => {
		if(showEmailField){
			setShowEmailField(false);
		} else {
			setShowEmailField(true);
		}
	};

	const togglePassword = () => {
		let classList = document.getElementById('eyeCon').classList;
		if(showPassword){
			setShowPassword(false);
			classList.remove('icon-eye');
			classList.add('icon-eye-off');
		} else {
			setShowPassword(true);
			classList.remove('icon-eye-off');
			classList.add('icon-eye');
		}
	};
	
	logRender(`Signup -- render`);
	
	let greeting;
	if(FacebookAPI.userInfo && FacebookAPI.userInfo.first_name){
		greeting = (
			<div>
				<span className="no-wrap">Welcome {FacebookAPI.userInfo.first_name},&nbsp;</span>
				<span className="no-wrap">Reserve your club now!</span>
			</div>
			);
	} else if(globals.inviter) {
		greeting = `${globals.inviter.username} ${i18n('signup.invited-you','invited you to Blackout Rugby!')}`;
	} else {
		greeting = i18n('signup.title',`Reserve your club now!`);
	}

	let emailFields = (
		<div className="sub-panel no-gap-after">
			<div className="input-container">
				<input 
					id='email'
					type='text'						
					onChange={handleChange}
					onFocus={handleFocus}
					defaultValue={email}
					placeholder={i18n('login.email','Email')}
				/>
				{validationMessages
					&& validationMessages.email
					? <span className="validation-text"><span className="icon-attention" />{validationMessages.email}</span>
					: ''
				}
			</div>
			<div className="input-container">
				<div className="password-input">
					<input 
						id='password'
						type={ showPassword ? 'text' :'password'}						
						onChange={handleChange}
						onFocus={handleFocus}
						onKeyDown={handleKeyDown}
						defaultValue={password}
						placeholder={i18n('login.password','Password')}
					/>
					<PasswordRevealButton onClick={()=>togglePassword()}/>
				</div>
				{validationMessages
					&& validationMessages.password
					? <span className="validation-text"><span className="icon-attention" />{validationMessages.password}</span>
					: ''
				}
			</div>
			<LoaderButton
				onClick={()=>handleSubmit({usingEmail: true})}
				className="loader-button"
				isLoading={isSigningUp}
			>{i18n('buttons.submit','Submit')}</LoaderButton>
		</div>
	);
	
	return (
		<form onSubmit={e=>e.preventDefault()}>
			<div className="signup-form">
				<h3>{greeting}</h3>
				
				<div className="sub-panel">
					
					<div className="input-container">
						<input 
							id='club'
							type='text'
							onChange={handleChange}
							defaultValue={club}
							onFocus={handleFocus}
							placeholder={`*${i18n('signup.club-name','Club Name')}`}
						/>
						{validationMessages
							&& validationMessages.club
							? <span className="validation-text"><span className="icon-attention" />{validationMessages.club}</span>
							: ''
						}
					</div>
					
					<div className="input-container select-container">
						<select
							id='country'
							value={country}
							onChange={handleChange}
							onFocus={handleFocus}
						>
							<option key="-1" value="none" disabled>
								{countries.length
									? `*${i18n('signup.country','Country')}`
									: i18n('signup.loading-countries','Loading countries...')}
							</option>
							{countries.map((country,index)=>{
								return (
									<option key={index} value={country.id}>{country.name}</option>
								);
							})}
						</select>
						<div className="select-arrow icon-down-dir"></div>
						{validationMessages
							&& validationMessages.country
							? <span className="validation-text"><span className="icon-attention" />{validationMessages.country}</span>
							: ''
						}
						
					</div>
					
					<div className="input-container">
						<input 
							id='refCode'
							type='text'						
							onChange={handleChange}
							onFocus={handleFocus}
							defaultValue={refCode}
							placeholder={i18n('signup.referral-code','Referral Code')}
						/>
						{validationMessages
							&& validationMessages.refCode
							? <span className="validation-text"><span className="icon-attention" />{validationMessages.refCode}</span>
							: ''
						}
					</div>
					

					<div className="login gap-after sm">
						<span className="color heading">{i18n('signup.sign-up-using','Sign-up using')}</span>
						{globals.fbEnabled?(<LoaderButton
							onClick={()=>handleSubmit({usingFacebook: true})}
							className="sm-inline icon-facebook fb-button vertical-middle square"
							isLoading={isSigningUp}
						></LoaderButton>):null}
						{globals.appleEnabled?(<LoaderButton
							onClick={()=>{handleSubmit({usingApple: true});}}
							className="sm-inline apple-button vertical-middle square"
							isLoading={isSigningUp}
						>
							<span className="apple-button-content"></span>
						</LoaderButton>):null}
						{globals.googleEnabled?(<span ref={googleLoginElement}>
							<LoaderButton
								onClick={()=>{handleSubmit({usingGoogle: true});}}
								className="sm-inline google-button vertical-middle square"
								isLoading={isSigningUp}
							>
								<img src="https://cdn.cdnlogo.com/logos/g/35/google-icon.svg" className="icon-google" alt="Google" />
							</LoaderButton>
						</span>):null}
						<LoaderButton
							onClick={() =>toggleEmailFields()}
							className="sm-inline vertical-middle"
							isLoading={isSigningUp}
						>{i18n('login.email','Email')}</LoaderButton>
					</div>
					
					{props.thirdPartyLoginError
						? <div className="validation-text center-text"><span className="icon-attention" />{props.thirdPartyLoginError}</div>
						: ''}
					
				</div>

				{showEmailField ? emailFields : ''}
				
			</div>
		</form>
	);
	
}

export default Signup;
import React, { useState } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import BlackoutAPI from '../../lib/BlackoutAPI';
import { logError } from '../../lib/Debug';
import { useParams } from 'react-router-dom';

import LoaderButton from '../LoaderButton';
import Panel from '../Panel';

function Feedback() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	//--------------------- State
	
	let [isSending,setIsSending] = useState(false);
	let [feedbackType,setFeedbackType] = useState(null);
	let [feedbackText,setFeedbackText] = useState('');
	let [hasSent,setHasSent] = useState(false);
	let headers = {
		Token: globals.userState.token.accessToken,
		'Content-Type': 'application/vnd.api+json',
	};
	
	//--------------------- URL params
	
	const { base64 } = useParams();
	let urlParams = {};
	if(base64){
		try {
			urlParams = JSON.parse(atob(base64));
		} catch(err){
			logError(err);
		}
	}
	let userEmail = urlParams.email || globals.userState.manager.email;
	let feedbackContext = urlParams.context || 'blackout.rugby Website';
	
	//--------------------- Handlers
	
	const handleSubmit = () => {
		if(feedbackType&&feedbackText){
			setIsSending(true);
			
			let slackPayload = {
				"attachments": [
					{
						"color": feedbackType === 'negative' ? '#e54e5c' : '#2eb886',
						"title": `${feedbackType === 'negative' ? ':unimpressed: Negative' : ':yus: Positive'} Feedback from manager: ${globals.userState.manager.username}`,
						"text": `${feedbackText}\nmanager id: ${globals.userState.manager.id}\ncontext: ${feedbackContext}\n<mailto:${userEmail}|Email>`
					}
				]
			};
			
			BlackoutAPI.post({
				endpoint: 'feedback',
				headers,
				data:{
					type:'feedback',
					attributes: {
						SlackPayload: JSON.stringify(slackPayload),
					}
				}
			}).then(()=>{
				setIsSending(false);
				setHasSent(true);
			}).catch(err=>{
				logError(err);
				setIsSending(false);
			});
			
		}
	};
	
	const handleTextChange = (e) => {
		setFeedbackText(e.target.value);
	};
	
	return (
		<Panel>
			<h2 className="center-text">{i18n('misc.feedback','Feedback')}</h2>
			<div className="sub-panel">
				{hasSent
				?
					<>
						<h3 className="center-text">{i18n('misc.thank-you','Thank you!')}</h3>
						<div className="center-text">{i18n('misc.feedback-acknowledgement','We review all feedback, and will do our best to respond if appropriate.')}</div>
					</>
				:
					<>
						<form>
							<div className="center-text">
								<div className={`feedback-icon-wrapper ${feedbackType==="positive"?'selected':''}`}>
									<img
										src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/positive.png`} 
										alt="Positive Feedback"
										onMouseDown={()=>setFeedbackType("positive")}
										className={`feedback-icon ${feedbackType==="positive"?'selected':''}`}
									/>
								</div>
								<div className={`feedback-icon-wrapper negative ${feedbackType==="negative"?'selected':''}`}>
									<img
										src={`${globals.config.baseImageUrl}/website/blackout.rugby/2020/negative.png`}
										alt="Negative Feedback"
										onMouseDown={()=>setFeedbackType("negative")}
										className={`feedback-icon ${feedbackType==="negative"?'selected':''}`}
									/>
								</div>
							</div>
							<textarea
								value={feedbackText}
								onChange={handleTextChange}
								className="feedback-text"
								placeholder={i18n('misc.feedback-placeholder','What\'s on your mind?')}
							/>
						</form>
						<LoaderButton
							onClick={()=>{handleSubmit();}}
							className="loader-button"
							isLoading={isSending}
							disabled={!feedbackType||!feedbackText}
						>
							<span className="no-wrap">{i18n('misc.send-feedback','Send Feedback')}</span>
						</LoaderButton>
					</>
				}
			</div>
		</Panel>
	);
	
}

export default Feedback;
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { log, logError, logRender } from '../lib/Debug';
import BlackoutAPI from '../lib/BlackoutAPI';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

import Panel from './Panel';
import LoadingBlinker from './LoadingBlinker';

function Unsubscribe() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	const { data } = useParams();
	
	const unpackURL = () => {
		try {
			return JSON.parse(window.atob(data));
		} catch (error) {
			setValid(false);
		}
	};
	
	let [valid,setValid] = useState(true);
	let [unsubbedEmail,setUnsubbedEmail] = useState(null);
	
	useEffect(()=>{
		
		var data = unpackURL();
		
		// For testing:
		//data = { email: 'a@a.a' };

		if(data){
			
			BlackoutAPI.post({
				endpoint: 'email-preferences',
				headers: {
					'Content-Type': 'application/vnd.api+json'
				},
				data: {
					type: 'email-preferences',
					attributes:{
						Email: data.email,
						OptOut: true
					}
				}
			}).then(res=>{
				setUnsubbedEmail(data.email);
				log(` **** unsubscribeAll success ***** `);
				log(`res`,res);	
			}).catch(err=>{
				logError(` **** unsubscribeAll Error ***** `);
				logError(`err.message`,err.message);
			});
			
		}
		
		// For testing:
		// 
		// setTimeout(()=>{
		// 	setValid(true);
		// 	setUnsubbedEmail('jeremytm@gmail.com');
		// },1000);
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	let homePageLink = (
		<Link to={`/login`} className="upper-link">{i18n('misc.return-to-login-signup','Return to login/signup')}</Link>
	);
	
	let content;
	
	if(valid){
		if(unsubbedEmail){
			content = (
				<>
					<h2 className="center-text">{unsubbedEmail}</h2>
					<div className="sub-panel center-text">
						<h3>{i18n('misc.unsubscribed','Successfully Unsubscribed')}</h3>
						<p><span className="icon-info-circled" />{i18n('misc.revert-unsubscribe','You can change this again in your account.')}</p>
						{homePageLink}
					</div>
					
				</>
			);
		} else {
			content = (
				<div className="sub-panel center-text">
					<LoadingBlinker text={i18n('misc.unsubscribing','Unsubscribing')} />
				</div>
			);
		}
	} else {
		content = (
			<div className="sub-panel center-text">
				<h2>{i18n('misc.invalid-unsubscribe-link','Invalid unsubscribe link')}</h2>
				{homePageLink}
			</div>
		);
	}
	
	logRender('Unsubscribe -- render');
	
	return (
		<Panel className="flex-column-center">
			{content}
		</Panel>
	);
	
}

export default Unsubscribe;

import React, { useState } from 'react';
import cookies from 'js-cookie';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';

function CookieFooter() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let cookiesAccepted = cookies.getJSON('cookiesAccepted');
	let [showCookieMessage,setShowCookieMessage] = useState(!cookiesAccepted);

	const clearAllCookies = () => {
		var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	};

	const acceptCookies = () => {
		cookies.set('cookiesAccepted',true);
		setShowCookieMessage(false);
	};

	const clearCookie = () => {
		cookies.remove('cookiesAccepted');
		setShowCookieMessage(true);
	};
	
	let devMode = process.env.NODE_ENV === 'development';
	
	if(showCookieMessage){
		clearAllCookies();
		return(
			<div id="cookie-footer">
				{i18n('misc.cookie-disclaimer','We use a cookie to track if you\'re logged-in; and we use the facebook login plug-in which also uses cookies.')}
				<button onClick={() => acceptCookies()} className="cookie-button">{i18n('misc.accept-cookies','Accept Cookies')}</button>
			</div>
		);
	} else if(devMode) {
		return(
			<div id="cookie-footer" className="debug">
				<button onClick={() => clearCookie()} className="cookie-button">Clear &apos;acceptCookies&apos; cookie</button>
			</div>
		);
	} else {
		return '';
	}
	
}

export default CookieFooter;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logRender } from '../lib/Debug';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import SwitchSport from './SwitchSport';

function MainMenu() {
	
	//--------------------- State
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	let location = useLocation();
	
	let homeClassName = ['menu-link'];
	let accountClassName = ['menu-link'];
	
	if(globals.isHome){
		homeClassName.push('active');
	} else if(globals.isAccountPath(location.pathname)){
		accountClassName.push('active');
	}
	
	logRender(`MainMenu -- render`);
	
	return (
		<>
			<Link to='/' className={homeClassName.join(' ')}>{i18n('menu.home','Home')}</Link>
			<Link to={globals.appPath} className={accountClassName.join(' ')}>{i18n('menu.account','Account')}</Link>
			<a href="mailto:hello@blackout.games" className="menu-link">{i18n('menu.contact','Contact')}</a>
			{globals.isDevMode?<SwitchSport />:null}
		</>
	);
	
}

export default MainMenu;
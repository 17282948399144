import React, { useState, useEffect } from 'react';
import Globals from '../../contexts/Globals';
import I18nLib from '../../lib/I18nLib';
import BlackoutAPI from '../../lib/BlackoutAPI';
import moment from 'moment';
import util from '../../lib/util';
import { logRender, logError } from '../../lib/Debug';
import { Link } from 'react-router-dom';

import LoaderButton from '../LoaderButton';
import Panel from '../Panel';
import LoadingBlinker from '../LoadingBlinker';
import InfoBox from '../InfoBox';

function ManageSubscriptions() {
	
	let globals = Globals.useContainer();
	let i18n = I18nLib.init(globals);
	
	let [hasLoadedSubs,setHasLoadedSubs] = useState(!!globals.userSubs);
	let [isLoadingSubs,setIsLoadingSubs] = useState(false);
	let [isUpdatingSub,setIsUpdatingSub] = useState(false);
	let [validationMessage,setValidationMessage] = useState(null);

	let headers = {
		Token: globals.userState.token.accessToken,
		Accept: 'application/vnd.api+json',
	};
	
	useEffect(()=>{
		let ignore = false;
		
		if(!globals.userSubs && !isLoadingSubs){
			setIsLoadingSubs(true);
			
			BlackoutAPI.get({
				endpoint: 'subscriptions',
				headers,
				// no `id` needed, so we get all subs
			}).then(res=>{
				globals.setUserSubs(res.data);
				if(ignore) return;
				setHasLoadedSubs(true);
				setIsLoadingSubs(false);
			}).catch(logError);
			
		} else if(!isLoadingSubs) {
			setHasLoadedSubs(true);
			setIsLoadingSubs(false);
		}
		
		return ()=>{
			ignore = true;
		};
	
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[globals.userSubs]);

	const formatDate = (date) => {
		return moment(date).format("MMM Do YYYY");
	};

	const submitSubscriptionUpdate = (id, sub) => {
		
		setIsUpdatingSub(true);
		setValidationMessage(null);
		let ignore = false;
		
		// Send blackout api post request ......
		BlackoutAPI.post({
			endpoint: 'subscriptions',
			headers,
			data: {
				type: 'subscriptions',
				attributes:{
					[sub.isAutoRenewable ? "Cancel" : "Reactivate"]: sub.game,
				}
			}
		}).then(res=>{
			
			let newSubs = globals.userSubs ? util.cloneData(globals.userSubs) : [];

			for(let i in newSubs){
				if(newSubs[i].id === res.data.id){
					newSubs[i] = res.data;
				}
			}
			globals.setUserSubs(newSubs);
			
			if(ignore) return;
			setIsUpdatingSub(false);
			
		}).catch(err=>{
			setValidationMessage(i18n('account.error-see-console','Error. See console for details.'));
			logError(`submitSubscriptionUpdate Error`,err.message);
		});
		
		return ()=>{
			ignore = true;
		};
		
	};

	const subData = (id, sub) => {
		//console.log('sub',sub);
		/**
		 * Relevant sub data:
		 * 	Game
		 * 	Type
		 * 
		 * 	StartDate
		 * 	EndDate
		 * 	BillingDate
		 * 	MonthlyRewardsDate
		 * 	ProcessDate
		 * 	LastProcessDate
		 * 
		 * 	IsAutoRenewable
		 * 	RecurringAmount	// only if auto renew
		 * 	Frequency
		 * 	DowngradeAtNextRenewal
		 * 
		 * 	IsCurrentlyActive
		 * 	HasExpired
		 */

		// let status = sub.hasExpired ? 'Expired' : 'Active';
		// let i18nStatus = `account.status.${status.toLowerCase()}`;

		return (
			<div>
				<div className="data-container invites">
					<h3 className="gap-after sm center-text">{sub.type} {sub.type === 'Premium' ? `(${i18n(`sports.${sub.game.toLowerCase()}`,sub.game)})` : ''} </h3>
					
					<div className="gap-after sm">
						<div className="data-row">
							<div className="key-col">{i18n('account.status','Status')}:</div>
							<div className="val-col">{
								sub.hasExpired 
								? i18n('account.status.expired','Expired') : sub.isAutoRenewable 
								? `${i18n('account.status.autorenew','Auto-Renew')} (via ${util.toTitleCase(sub.platform)})` : i18n('account.status.active','Active')
							}</div>
						</div>
						{/* 
							Commented out, as this may confuse users, thinking they're only subscribed
							to X platform version of the game. Leaving comment as reminder to future people.
						<div className="data-row">
							<div className="key-col">Platform:</div>
							<div className="val-col">{util.toTitleCase(sub.platform)}</div>
						</div> 
						*/}

						{
							// Will only show this for auto renew subs
							sub.isAutoRenewable ?
							<div className="data-row">
								<div className="key-col">{i18n('account.amount','Renewal Amount')} (USD):</div>
								<div className="val-col">${sub.recurringAmount}</div>
							</div> : null
						}
						<div className="data-row">
							<div className="key-col">{i18n('account.start-date','Start Date')}:</div>
							<div className="val-col">{formatDate(sub.startDate)}</div>
						</div>
						<div className="data-row">
							<div className="key-col">{i18n('account.end-date','End Date')}:</div>
							<div className="val-col">{formatDate(sub.endDate)}</div>
						</div>

						{
							sub.isAutoRenewable ?
							<div className="data-row">
								<div className="key-col">{i18n('account.last-payment','Last Payment')}:</div>
								<div className="val-col">{formatDate(sub.lastPaymentDate)}</div>
							</div> : null
						}
						{
							sub.lastRewardsDate ?
							<div className="data-row">
								<div className="key-col">{i18n('account.previous-processing-date','Previous Rewards Date')}:</div>
								<div className="val-col">{formatDate(sub.lastRewardsDate)}</div>
							</div> : null
						}
						{
							!sub.endDate || formatDate(sub.processDate) !== formatDate(sub.endDate) ?
							<div className="data-row">
								<div className="key-col">{i18n('account.processing-date','Next Rewards Date')}:</div>
								<div className="val-col">{formatDate(sub.monthlyRewardsDate)}</div>
							</div> : null
						}
						
					</div>
					
					{ sub.isAutoRenewable && !sub.hasExpired ?
					<LoaderButton
						onClick={()=>submitSubscriptionUpdate(id, sub)}
						className="gap-after sm"
						isLoading={isUpdatingSub}
					>
						{ sub.isAutoRenewable ? i18n('buttons.cancel-subscription','Cancel Subscription') : i18n('buttons.reactivate','Reactivate')}
					</LoaderButton>
					:null}


				</div>
			
				{/* if downgrade, show info warning  */}

				{
					sub.downgrade === "Downgrade" ? <div className="form-minor-message"><span className="icon-info-circled" />
						{i18n('account.subscription-change','Subscription will downgrade to Premium on next renewal')} ({i18n(`sports.${sub.game.toLowerCase()}`,sub.game)})
					</div>
					: ''

				}

				{
					validationMessage
					? <div className="validation-text"><span className="icon-attention" />{validationMessage}</div>
					: ''
				}
			</div>

		);
	};
	
	logRender(`ManageSubscriptions -- render`);
	let content;
	
	if((!globals.userSubs || !globals.userSubs.length)){
		// subs were already loaded, and none were found ....
		if(hasLoadedSubs){
			
			content = (
				<div className="center-text">
					<h4 style={{ marginBottom: 0 }}>{i18n('account.no-subscriptions','No Subscriptions')}</h4>
				</div>
			);
			
		} else {

			content = (
				<LoadingBlinker text={i18n('account.loading-subscriptions','Loading subscriptions')} />
			);
			
		}
	} else {
		
		content = globals.userSubs.map(sub => {
			return (
				<div key={sub.id} className="center-text">
					{subData(sub.id, sub)}
				</div>
			);
		});
		
	}
	
	return (
		<Panel>
			<div className="sub-panel">
				{content}
			</div>
			<InfoBox>
				Find out about premium <a href="https://blackout-rugby.fandom.com/wiki/Premium_Subscription" target="_blank" rel="noopener noreferrer">here</a> and grab your subscription <Link to='/'>in-game</Link>.
			</InfoBox>
		</Panel>
	);
	
}

export default ManageSubscriptions;
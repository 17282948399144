import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logRender } from '../lib/Debug';
import Globals from '../contexts/Globals';
import I18nLib from '../lib/I18nLib';
import Logout from './authentication/Logout';

function SubMenu() {
	
	//--------------------- State
	
	let globals = Globals.useContainer();
	let location = useLocation();
	let i18n = I18nLib.init(globals);
	
	let profileClassName = ['menu-link','sm'];
	let settingsClassName = ['menu-link','sm'];
	let subsClassName = ['menu-link','sm'];
	
	if(location.pathname === '/profile') profileClassName.push('active');
	if(location.pathname === '/settings') settingsClassName.push('active');
	if(location.pathname === '/subscriptions') subsClassName.push('active');
	
	logRender(`SubMenu -- render`);
	
	return (
		<>
			<Link to='/profile' className={profileClassName.join(' ')}>{i18n('menu.profile','Profile')}</Link>
			<Link to='/settings' className={settingsClassName.join(' ')}>{i18n('menu.settings','Settings')}</Link>
			<Link to='/subscriptions' className={subsClassName.join(' ')}>{i18n('menu.subscriptions','Subscriptions')}</Link>
			<Logout />
		</>
	);
	
}

export default SubMenu;